import React from "react";

const PandCchangesTo = () => {
  return (
    <>
      Keeping your account information accurate and up to date is very
      important. If your account information is incomplete, inaccurate, or not
      current, please use the Contact Us option on our Site, or call or write to
      us at the telephone numbers or appropriate address for changes listed on
      your account statements, records, online or other account materials. You
      can also speak to a customer representative from the Company.
    </>
  );
};

export default PandCchangesTo;
