import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import doc from "./doc.png";
import api from "./api.png";

const SubMenuThree = ({ clearHandler }) => {
	const [isBouncing, setIsBouncing] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsBouncing(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div
			onClick={clearHandler}
			className={`mt-1.5 mb-4 ${
				isBouncing ? "bounce" : ""
			} w-full flex flex-col transition-[0.2ms] text-left`}
		>
			{submenuItems.map((item, index) => (
				<Link
					to={item.path}
					data-aos="fade-right"
					data-aos-delay={item.aosDelay}
					key={index}
				>
					<li className="flex gap-4 group mt-4 flex-row items-center justify-start ease-in-out">
						<img
							src={item.imgSrc}
							alt={item.label}
							className="w-[47px] h-[47px] 
							group-hover:scale-125 transition ease-in-out delay-[100] group-hover:border group-hover:border-primary rounded-full"
						/>
						<div className="flex flex-col group-hover:text-primary">
							<h1 className="font-normal">{item.label}</h1>
							<p className="font-light">{item.description}</p>
						</div>
					</li>
				</Link>
			))}
		</div>
	);
};

export default SubMenuThree;

const submenuItems = [
	{
		path: "/documents",
		imgSrc: doc,
		label: "Documentation",
		description: "Overview API Documentation",
		aosDelay: "0", // No delay
	},
	{
		path: "/api",
		imgSrc: api,
		label: "API Reference",
		description: "Overview of API Reference",
		aosDelay: "100",
	},
];
