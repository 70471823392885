import React, { forwardRef } from "react";
import { HiCheckCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";

const plans = [
	{
		title: "Local Channels",
		subtitle: "Perfect for small businesses",
		price: "1.4%",
		cta: "Get Started",
		features: ["Capped at 2,000 NGN", "On all local transactions"],
	},
	{
		title: "International",
		subtitle: "Best for growing businesses",
		price: "5.0%",
		cta: "Get Started",
		features: ["On all international transactions"],
	},
	{
		title: "Terminal",
		subtitle: "Custom solutions for enterprises",
		price: "0.5%",
		cta: "Get Started",
		features: ["Capped at 1,000 NGN", "On POS card transactions"],
	},
];

const PricingCard = ({ title, subtitle, price, cta, features }) => (
	<div
		className="flex group flex-col justify-between 
  bg-gradient-to-r from-green-50 to-green-200 w-full max-w-[450px] 1xl:max-w-[650px]
  hover:bg-gradient-to-r hover:from-accent hover:to-green-hover
  bg-opacity-10 rounded-3xl p-[3vw] md:p-[6vw] h-full text-left hover:scale-105 transition-transform ease-in duration-300"
	>
		<div className="">
			<h2
				className="
			text-[3vw] xl:text-[45px] capitalize
        leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[50px]
        tracking-[0.02em] lg:text-[35px]
			font-bold text-green-600 group-hover:text-primary mb-4"
			>
				{title}
			</h2>
			<p
				className="text-gray-800
			        text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl 
        3xl:text-2xl 4xl:text-2xl 5xl:text-2xl 6xl:text-2xl
        tracking-wider 
        xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
			group-hover:text-gray-900 mb-4"
			>
				{subtitle}
			</p>
			<div
				className="xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl font-bold text-green-600 mb-4 group-hover:text-primary"
			>
				<span
					className="align-super text-m xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl relative -top-1 mr-1"
				>
					{price}
				</span>
			</div>
			<Link  to={title === "Terminal" ? "/contactus" : "/getstarted"}>
				<button
					className="bg-green-600 text-white
			        text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-2xl 4xl:text-2xl 5xl:text-2xl 6xl:text-2xl
        tracking-wider 
        xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
			p-4 rounded-[64px] group-hover:bg-primary mb-6 w-full"
				>
					{cta}
				</button>
			</Link>
		</div>
		<ul className="space-y-3 h-1/2">
			{features.map((feature, index) => (
				<li
					key={index}
					className="flex items-center space-x-2 hover:scale-105 transition-transform duration-300 ease-in-out"
				>
					<HiCheckCircle
						className="text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-2xl 4xl:text-2xl 5xl:text-2xl 6xl:text-2xl text-primary group-hover:text-primary"
					/>
					<span
						className="text-gray-700 group-hover:text-gray-800 text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-2xl 4xl:text-2xl 5xl:text-2xl 6xl:text-2xl"
					>
						{feature}
					</span>
				</li>
			))}
		</ul>
	</div>
);

const PricingCards = forwardRef((props, ref) => {
	return (
		<section>
			<div className="p-[2vw] md:p-[4vw] pt-[10vw] flex flex-col justify-center items-center">
				<h1
					className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
        text-[4vw] xl:text-[45px] capitalize text-black
        leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[50px]
        tracking-[0.02em] lg:text-[35px]  text-center
        "
				>
					Choose your plan
				</h1>
				<p
					className="font-sans font-light
        mb-[40px]
        lg:mb-[20px] 
        max-w-6xl md:max-w-sm 
        text-center text-black 
        text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
        tracking-wider 
        xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
        "
				>
					unlock endless possibilites
				</p>
			</div>
			<div
				ref={ref}
				className="grid 
				6xl:grid-cols-3
				4xl:grid-cols-3
				3xl:grid-cols-3
				2xl:grid-cols-3
				1xl:grid-cols-3
				xl:grid-cols-2
				lg:grid-cols-2
				md:grid-cols-2 
				sm:grid-cols-1 
				xs:grid-cols-1 
				gap-[4vw] flex justify-items-center p-[2vw] md:p-[4vw] pb-[10vw] w-full h-full"
			>
				{plans.map((plan, index) => (
					<PricingCard key={index} {...plan} />
				))}
			</div>
		</section>
	);
});

export default PricingCards;
