import React from "react";

const PandCaboutUs = () => {
  return (
    <>
      Sayswitch is a Financial Technology Company registered which provides an
      online payment gateway that accepts Credit and Debit Cards online from
      Customers for Merchant. The Company is in Nigeria with offices at 15,
      Idowu Olo Lane, Lagos Island, Lagos. We are an independent contractor for
      all purposes, providing this website and our services on an independent
      service provider basis. We do not have control or assume the liability or
      legality for the products or services that are paid for with our service.
      We do not guarantee any user’s identity and cannot ensure that a buyer or
      seller will complete a transaction. This Terms of Use is an agreement
      between you and Sayswitch. It details Sayswitch’s obligations to you. It
      also highlights certain risks on using the services and you must consider
      such risks carefully as you will be bound by the provision of this
      Agreement through your use of this website or any of our services.
      <span id="privacy"></span>
    </>
  );
};

export default PandCaboutUs;
