import React from "react";
import { motion } from "framer-motion";

// Import images correctly
import payment from "./images/payment.png";
import secure from "./images/secure.png";
import globe from "./images/globe.png";
import seamless from "./images/seamless.png";

const features = [
	{
		image: payment,
		hover: "hover:bg-[#2A99E3]",
		title: "Multiple Payment Methods",
		description:
			"SaySwitch checkout offers the payment methods your customers know and trust, including Card Payments, Accounts, Bank Transfer, and more.",
	},
	{
		image: secure,
		hover: "hover:bg-[#E94F4F]",
		title: "Secure and Scalable",
		description:
			"Security standards using encryption for a safe, friction-free experience. We are PCIDSS certified, ensuring that your customers can pay in a secure environment.",
	},
	{
		image: globe,
		hover: "hover:bg-[#00A861]",
		title: "Make Payments Anywhere You Go",
		description:
			"Customers can make payments on desktop through various methods like Card, Bank Account, NQR, and Bank Transfers, no matter where they are in the world.",
	},
];

// Reusable FeatureCard component with framer-motion hover effect
const FeatureCard = ({ image, title, description, hover }) => (
	<motion.div
		className={`flex group flex-col items-start text-left gap-[4vw] lg:gap-[6vw] border border-[#BBBABA] hover:border-none rounded-3xl 
			p-2xl py-4xl ${hover} shadow-lg transition-all duration-300`}
		whileHover={{
			scale: 1.05,
			transition: { duration: 0.3 },
		}}
	>
		<img src={image} alt={title} className="h-24 lg:h-[56px] aspect-square" />
		<div className="flex flex-col gap-[2vw] lg:gap-[3vw] tracking-wide">
			<h2
				className="h4 font-semibold leading-relaxed
			 transition duration-[300ms] ease-in-out group-hover:text-white
			"
			>
				{title}
			</h2>
			<p className="text-gray-600 transition duration-[300ms] ease-in-out group-hover:text-white font-light text-2 leading-loose">
				{description}
			</p>
		</div>
	</motion.div>
);

const PaymentExperience = () => {
	return (
		<section className="px-[6vw] py-[8vw] bg-white text-black">
			<div className="text-center md:text-left mb-[4vw] lg:mb-[6vw]">
				<h1 className="h3 font-bold">
					Seamless Payment Experience <br /> for All Your Customers
				</h1>
			</div>

			<div className="mb-[4vw] lg:mb-[6vw] flex items-center justify-center">
				<img
					src={seamless}
					className="aspect-[1032/664.24] w-auto object-cover"
				/>
			</div>

			<div
				className="grid grid-cols-3 
            3xl:grid-cols-3
            1xl:grid-cols-2
            xl:grid-cols-2
            lg:grid-cols-2
            md:grid-cols-1
             gap-[6vw]"
			>
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						image={feature.image}
						title={feature.title}
						description={feature.description}
						hover={feature.hover}
					/>
				))}
			</div>
		</section>
	);
};

export default PaymentExperience;
