import React from "react";
import grow from "./icons/grow.png";
import payment from "./icons/payment.png";
import b2c from "./icons/b2c.png";
import b2b from "./icons/b2b.png";

const GrowYourBusiness = () => {
	// Array of items
	const growItems = [
		"Card",
		"Bank Transfer",
		"Bank Account",
		"USSD",
		"QR Payment",
		"POS",
		"Mobile Money (Coming Soon)", // "Coming Soon" will be styled in green
	];
	const b2bItems = [
		"Accept payment links, bank transfer, bank account, Sayswitch account and more",
		"Reconcile and match transactions dispute and refunds effortlessly.",
		"Quicker settlements and payouts.",
	];

	return (
		<main className="bg-[#F3F6F9] flex flex-col w-full px-[4vw] py-[5vw] gap-[6vw]">
			<h1
				className="w-full font-bold mb-[1.6vw] 
				6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
      text-black leading-tight tracking-wide text-left"
			>
				What you can do with SaySwitch
			</h1>
			<section className="w-full grid grid-cols-2 md:grid-cols-1 gap-[4vw] md:gap-[8vw] px-[1vw]">
				{/* Left Section */}
				<section
					data-aos="fade-right"
					className="flex flex-col justify-center items-start bg-none rounded-3xl h-full"
				>
					<img
						src={grow}
						data-aos="zoom-in"
						alt="Partner with us"
						className="w-full h-full object-cover rounded-3xl"
					/>
				</section>

				{/* Right Section */}
				<section
					className="h-full flex flex-col items-stretch justify-between"
					data-aos="fade-left"
				>
					<div>
						<h1
							className="text-gray-500 
						  6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
			          font-medium leading-tight tracking-wide"
						>
							01 —
						</h1>
						<h2
							className="text-black 
				          6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
				          font-bold mb-[1vw]"
						>
							Grow your business
						</h2>

						<p
							className="text-gray-700 font-light
				          6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
				          mb-[3vw] xl:mb-[2vw]"
						>
							Build unique branded payment experiences and increase conversions.
						</p>
					</div>

					{/* Mapping the items */}
					<ul className="space-y-[1vw] p-[1vw]">
						{growItems.map((item, index) => (
							<li key={index} className="flex items-center">
								<div className="aspect-square bg-[#008037] rounded-full w-[1vw] 1xl:w-[1.5vw] xl:w-[1.8vw] md:w-[3vw] mr-[2vw]" />
								{/* Conditional styling for the "Coming Soon" item */}
								<p
									className={` font-normal
									    6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
									    ${
												item.includes("Coming Soon")
													? "text-[#008037] font-semibold"
													: "text-black"
											}`}
								>
									{item}
								</p>
							</li>
						))}
					</ul>
				</section>
			</section>
			<section className="w-full grid grid-cols-2 md:grid-cols-1 gap-[6vw] md:gap-[8vw] px-[1vw]">
				{/* Left Section */}
				<section
					data-aos="fade-right"
					className="flex flex-col justify-center items-start bg-none rounded-3xl h-full gap-[2vw]"
				>
					<div>
						<h1
							className="text-gray-500 
						  6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
			          font-medium leading-tight tracking-wide"
						>
							02 —
						</h1>
						<h2
							className="text-black 
				          6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
				          font-bold mb-[1vw]"
						>
							Take payments online <br /> for any business setup
						</h2>

						<p
							className="text-gray-700 font-light
				          6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
				          mb-[3vw] xl:mb-[2vw]"
						>
							We offer a full range of payment options, for any business and
							industry, <br /> so you can give your customers the experiences
							they expect.
						</p>
					</div>
					<img
						src={payment}
						data-aos="zoom-in"
						alt="Partner with us"
						className="w-full h-full object-cover rounded-3xl"
					/>
				</section>

				{/* Right Section */}
				<section
					className="h-full flex flex-col items-stretch justify-between gap-[2vw]"
					data-aos="fade-left"
				>
					<div>
						<h1
							className="text-gray-500 
						  6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
			          font-medium leading-tight tracking-wide"
						>
							03 —
						</h1>
						<h2
							className="text-black 
				          6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
				          font-bold mb-[1vw]"
						>
							Business to customer <br /> (B2C)
						</h2>

						<p
							className="text-gray-700 font-light
				          6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
				          "
						>
							Build unique branded payment experiences <br /> and increase
							conversions.
						</p>
						{/* Mapping the items */}
						<div className="flex items-center my-[1.5vw] xy:my-[1vw]">
							<div className="aspect-square bg-[#008037] rounded-full w-[1vw] 1xl:w-[1.5vw] xl:w-[1.8vw] md:w-[3vw] mr-[2vw]" />
							<p
								className={`
									    6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
									text-black`}
							>
								Customize payment flows.
							</p>
						</div>
					</div>
					<img
						src={b2c}
						data-aos="zoom-in"
						alt="Partner with us"
						className="w-full h-full object-cover rounded-3xl"
					/>
				</section>
			</section>
			<section className="w-full grid grid-cols-2 md:grid-cols-1 gap-[4vw] md:gap-[8vw] px-[1vw]">
				{/* Left Section */}
				<section
					className="h-full flex flex-col items-stretch justify-between"
					data-aos="fade-left"
				>
					<div>
						<h1
							className="text-gray-500 
						  6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
			          font-medium leading-tight tracking-wide"
						>
							04 —
						</h1>
						<h2
							className="text-black 
				          6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
				          font-bold mb-[1vw]"
						>
							Business to Business (B2B)
						</h2>

						<p
							className="text-gray-700 font-light
				          6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
				          mb-[3vw] xl:mb-[2vw]"
						>
							Win more merchants with flexible payment options and reduce
							reconciliation issues.{" "}
						</p>
					</div>

					{/* Mapping the items */}
					<ul className="space-y-[2vw] p-[1vw]">
						{b2bItems.map((item, index) => (
							<li key={index} className="grid grid-cols-10">
								<div className="aspect-square bg-[#008037] rounded-full w-[1vw] 1xl:w-[1.5vw] xl:w-[1.8vw] md:w-[3vw] mr-[2vw] col-span-1" />
								{/* Conditional styling for the "Coming Soon" item */}
								<p
									className={` col-span-9
									    6xl:text-[48px] 5xl:text-[40px] 4xl:text-[32px] 3xl:text-[30px] 2xl:text-[26px] 1xl:text-[24px] xl:text-[20px] lg:text-[20px] md:text-[18px] sm:text-[16px] xs:text-[16px]
								text-black`}
								>
									{item}
								</p>
							</li>
						))}
					</ul>
				</section>

				{/* Right Section */}
				<section
					data-aos="fade-right"
					className="flex flex-col justify-center items-start bg-none rounded-3xl h-full"
				>
					<img
						src={b2b}
						data-aos="zoom-in"
						alt="Partner with us"
						className="w-full h-full object-cover rounded-3xl"
					/>
				</section>
			</section>
		</main>
	);
};

export default GrowYourBusiness;
