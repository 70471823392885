import React from "react";
import { SlArrowRightCircle  } from "react-icons/sl";
import { Link } from "react-router-dom";
import Button from "../Button";

const cardData = [
	{
		title: "Account creation",
		description: "Generate an account number and instantly issue/assign it.",
	},
	{
		title: "Flexible Settlement & Payouts",
		description: "Choose when, how and in which currency you want to settle.",
	},
	{
		title: "One Integration",
		description:
			"Offer your customers all their preferred payment methods with a single integration. Local payment methods. Global support.",
	},
	{
		title: "Accurate data and reporting",
		description:
			"We provide advanced analytics on how your business is doing on our beautiful business dashboard. Your transaction data is always yours. Real-time reporting.",
	},
];

const PricingSubHero = () => {
	return (
		<section className="p-28 sm:p-6 md:p-8 lg:p-10 xl:p-12 2xl:p-16 3xl:p-20 4xl:p-24 5xl:p-28 bg-gray-50 text-left">
			<h1 className="xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 1xl:text-6xl 2xl:text-6xl 3xl:text-6xl 4xl:text-6xl 5xl:text-6xl font-bold text-black mb-8">
				Simple, transparent pricing. No hidden fees.
			</h1>
			<div className="grid grid-cols-1 gap-6">
				{cardData.map((card, index) => (
					<div
						key={index}
						className="p-6 rounded-2xl bg-green-50/10 shadow-sm hover:shadow-lg transition-shadow duration-300"
					>
						<h2 className="sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl 1xl:text-3xl 2xl:text-3xl 3xl:text-3xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl font-medium text-green-600 mb-4">
							{card.title}
						</h2>
						<p className="sm:text-base md:text-lg lg:text-xl xl:text-2xl 1xl:text-2xl 2xl:text-2xl 3xl:text-2xl 4xl:text-2xl 5xl:text-2xl 6xl:text-2xl text-gray-700">
							{card.description}
						</p>
					</div>
				))}
			</div>

			<div className="mt-[4vw]">
				<Link to="/contactus">
					<Button
					className="bg-accent border-none font-light group
                    text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
                    w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
                    h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px]  1xl:h-[88px] 2xl:h-[96px] 
                    rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
                    hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
					>
						Contanct Sales
						<SlArrowRightCircle  className="text-black" />
					</Button>
				</Link>
			</div>
		</section>
	);
};

export default PricingSubHero;
