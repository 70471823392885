import React from "react";
import flexibility from "./images/flexibility.png";
import financial from "./images/financial.png";
import health from "./images/health.png";
import extras from "./images/extras.png";
import { motion } from "framer-motion";

const benefitsData = [
	{
		header: "Flexibility and Time Off",
		description:
			"The time to recharge and flexibility to get work done; Hybrid work model.",
		points: [
			"Remote work opportunities.",
			"Paid time off, including vacation, sick leave, and holidays.",
		],
		bgColor: "bg-[#377D3F]",
		opacity: "bg-opacity-10",
		image: flexibility,
	},
	{
		header: "Financial Wellbeing",
		description:
			"Providing compensation and programs that give our team financial peace of mind;",
		points: ["Competitive compensation.", "National housing scheme."],
		bgColor: "bg-[#94BEF6]",
		opacity: "bg-opacity-10",
		image: financial,
	},
	{
		header: "Health & Wellness",
		description: "Our team is our priority, our employees have access to;",
		points: [
			"Medical, dental and vision insurance for employees and dependants.",
			"Onsite wellness centres.",
			"Employee assistance programs focused on mental health.",
		],
		bgColor: "bg-[#C892D0]",
		opacity: "bg-opacity-10",
		image: health,
	},
	{
		header: "Sayswitch Extras",
		description:
			"Resources to support your growth, productivity and wellbeing;",
		points: [
			"Top technology including internet access.",
			"Inspiring spaces to work, recharge and collaborate with others.",
		],
		bgColor: "bg-[#EEC5A3]",
		opacity: "bg-opacity-10",
		image: extras,
	},
];

const BenefitCard = ({
	header,
	description,
	points,
	bgColor,
	opacity,
	image,
}) => (
	<motion.div
		className={`rounded-2xl shadow-xl ${bgColor} ${opacity} mb-8`}
		whileHover={{ scale: 1.05 }} // Scale effect on hover
		transition={{ duration: 0.3 }} // Duration of the hover effect
	>
		<div
			className={`${bgColor} rounded-t-2xl mb-4 min-h-[240.07px] lg:min-h-[142.25px] flex items-center justify-center aspect-ratio-[573.61/240.07]`}
		>
			<img
				src={image}
				alt={header}
				className="max-w-[183.26px] lg:max-w-[109px]  object-cover "
			/>
		</div>
		<div className="p-[4vw] md:p-[6vw] tracking-normal">
			<h3 className="font-bold text-1 mb-[1vw]">{header}</h3>
			<p className="text-2 mb-[2vw] font-light">{description}</p>
			<div className="text-3 space-y-[2vw]">
				{points.map((point, index) => (
					<div className="grid grid-cols-10 items-center justify-start w-full">
						<div className="w-2 h-2 md:h-1 md:w-1 col-span-1 rounded-full bg-black" />

						<div key={index} className="font-light col-span-9">
							{point}
						</div>
					</div>
				))}
			</div>
		</div>
	</motion.div>
);

const BenefitsSection = () => (
	<section className="mx-auto p-[6vw]">
		<header className="text-start mb-10">
			<h1 className="text-1 font-regular mb-4 text-gray-500">OUR BENEFITS</h1>
			<p className="h4 font-bold">
				Every workplace and business has their{" "}
				<br className="block md:hidden" />
				style of work. This is ours;
			</p>
		</header>

		<div className="grid grid-cols-2 md:grid-cols-1 gap-[6vw]">
			{benefitsData.map((benefit, index) => (
				<BenefitCard key={index} {...benefit} />
			))}
		</div>
	</section>
);

export default BenefitsSection;
