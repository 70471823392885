import React from "react";

const PandCRightToComplain = () => {
  return (
    <>
      If you have a complaint about our use of your information, we would prefer
      you to contact us directly in the first instance so that we can address
      your complaint. However, you can also contact the National Information
      Technology Development Agency NITDA via their website at
      https://nitda.gov.ng/ or write to them at:
      <br />
      <br />
      No. 28, Port Harcourt Crescent,
      <br />
      Off Gimbiya Street, P.M.B 564,
      <br />
      Area 11, Garki, Abuja, Nigeria.
    </>
  );
};

export default PandCRightToComplain;
