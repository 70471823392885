// useScrollToTarget.js
import { useEffect } from 'react';
import { isInView } from './utils';

const useScrollToTarget = (refs, scrollTarget) => {
  useEffect(() => {
    if (scrollTarget) {
      const targetRef = refs[scrollTarget];
     // console.log('Scroll Target Ref:', targetRef);

      if (targetRef?.current) {
        const currentElement = targetRef.current;

        setTimeout(() => {
          if (!isInView(currentElement)) {
            currentElement.scrollIntoView({ behavior: 'smooth' });
          }
          // Optionally remove the scroll target from localStorage after scrolling
          // localStorage.removeItem('scrollTarget');
        }, 100);
      }
    }
  }, [refs, scrollTarget]);
};

export default useScrollToTarget;
