import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import paymentGatewayImg from "./paymentGatewayImg.png";
import checkoutImg from "./checkoutImg.png";
import pointOfSalesImg from "./pointOfSalesImg.png";
import switchProcessingImg from "./switchProcessingImg.png";
import collectionPlatformImg from "./collectionPlatformImg.png";
import mobileAppImg from "./mobileAppImg.svg";
import agentBankingImg from "./agentBankingImg.svg";

const SubMenuOne = ({ clearHandler }) => {
	const [isBouncing, setIsBouncing] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsBouncing(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div
			onClick={clearHandler}
			className={`mt-1.5 pt-4 mb-4 ${
				isBouncing ? "bounce" : ""
			} w-full flex flex-col transition-[0.2ms] text-left`}
		>
			{submenuSections.map((section, sectionIndex) => (
				<div key={sectionIndex}
				data-aos="fade-right"
				 className="mb-6">
					<h1 className="mb-2 font-normal">{section.sectionTitle}</h1>
					{section.links.map((item, index) => (
						<Link
							to={item.path}
							key={index}
						>
							<li className="flex group gap-4 mt-4 flex-row items-center justify-start ease-in-out">
								<img
									src={item.imgSrc}
									alt={item.label}
									className="w-[47px] xs:w-10 xs:h-10 h-[47px] 
									group-hover:scale-125 transition ease-in-out delay-[100] group-hover:border group-hover:border-primary rounded-full"
								/>
								<div className="flex flex-col group-hover:text-primary">
									<h1 className="font-normal xs:font-light">{item.label}</h1>
									<p className="block xs:hidden font-light">{item.description}</p>
								</div>
							</li>
						</Link>
					))}
				</div>
			))}
		</div>
	);
};

export default SubMenuOne;

const submenuSections = [
	{
		sectionTitle: "Online Solutions",
		links: [
			{
				path: "/products",
				imgSrc: paymentGatewayImg,
				label: "Payment Gateway",
				description: "Experience the finest of web acquiring",
			},
			{
				path: "/checkout",
				imgSrc: checkoutImg,
				label: "Checkout",
				description: "Payment solution for online storefront",
			},
			// {
			// 	path: "/mobileapp",
			// 	imgSrc: mobileAppImg,
			// 	label: "Mobile App",
			// 	description: "Access our services on the go",
			// },
		],
	},
	{
		sectionTitle: "Offline Solutions",
		links: [
			{
				path: "/pos",
				imgSrc: pointOfSalesImg,
				label: "Point of Sales",
				description: "Access payment with terminal and devices",
			},
			{
				path: "/sayprocessing",
				imgSrc: switchProcessingImg,
				label: "Switch and Processing",
				description: "Supporting business growth",
			},
			// {
			// 	path: "/agentbanking",
			// 	imgSrc: agentBankingImg,
			// 	label: "Agent Banking",
			// 	description: "Banking services through local agents",
			// },
		],
	},
	{
		sectionTitle: "Collection & Disbursement",
		links: [
			{
				path: "/collection",
				imgSrc: collectionPlatformImg,
				label: "Collection Platform",
				description: "Offers web-based solution for businesses",
			},
		],
	},
];
