import React from "react";

const PandCPayoutSchedule = () => {
  return (
    <>
      Your Payout Schedule, which is the time it takes us to initiate a transfer
      to your Bank Account settled funds from card transactions processed
      through us is on your Sayswitch Dashboard. We reserve the right to change
      your Payout Schedule, suspend payouts to your Bank Account or initiate a
      Reversal should we deem it necessary due to pending disputes, excessive or
      anticipated excessive Chargebacks or Refunds, or other suspicious activity
      associated with your use of Sayswitch, or if required by law or court
      order.
    </>
  );
};

export default PandCPayoutSchedule;
