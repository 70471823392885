import React from "react";

const PandCsayswitchInt = () => {
  return (
    <>
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Reduce or avoid information security breaches and related loss to not
          more than the acceptable operational loss limit.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Improve security-awareness culture for 75% of employees.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Protect 100% of client confidential information.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Protect 90% critical information assets and critical business
          processes relative to Sayswitch’s core business.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Ensure 100% safety and welfare of staff and visitors who are within
          its premises at the time of an incident.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Ensure 90% compliance with contractual, regulatory, and legal
          requirements.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Provide for an orderly and expedited recovery/continuity of critical
          business processes after a disruptive incident within 4 hours.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Ensure 100% service availability across all Sayswitch platforms.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Improve IT agility by ensuring 0% change-induced incidents within the
          year.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Achieve IT services delivery target in line with business requirements
          not less than 85%.
        </li>
      </ol>
    </>
  );
};

export default PandCsayswitchInt;
