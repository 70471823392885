import React, { useState, useEffect, useMemo, createRef } from "react";
import CustomLayout from "./Navigation/CustomLayout";
import Section from "./utils/Section";
import { sectionConfig } from "./utils/sectionConfig";
import useScrollToTarget from "./utils/useScrollToTarget";


const ApiPage = () => {
  const refs = useMemo(() => {
		return sectionConfig[1].sections.reduce((acc, section) => {
			acc[section.id] = createRef(); // Create refs here
			return acc;
		}, {});
	}, [sectionConfig]);

	// State to manage the scroll target
	const [scrollTarget, setScrollTarget] = useState(
		localStorage.getItem("scrollTarget")
	);

	// Update scroll target from localStorage
	useEffect(() => {
		const handleStorageChange = () => {
			setScrollTarget(localStorage.getItem("scrollTarget"));
		};

		window.addEventListener("storage", handleStorageChange);
		return () => window.removeEventListener("storage", handleStorageChange);
	}, []);

	// Use the custom hook to handle scrolling logic
	useScrollToTarget(refs, scrollTarget);
  return (
    <CustomLayout>
     {sectionConfig[1].sections.map((section, idx) => (
				<Section
					key={section.id}
					id={section.id}
					refProp={refs[section.id]}
					Component={section.component}
				/>
			))}
    </CustomLayout>
  );
};

export default ApiPage;
