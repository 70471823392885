import React, { useState } from "react";
import Logo from "../../../assets/images/sayswitchlogo.svg";
import { SidebarLink } from "./SidebarLinks";
import { Link, useHistory, useLocation } from "react-router-dom";
import { sectionConfig } from "../utils/sectionConfig";

const Sidebar = () => {
	const history = useHistory();
	const location = useLocation();

	return (
		<div className="bg-[#F9F8F8] overflow-scroll max-h-screen w-[20vw] block lg:hidden fixed top-0 left-0 p-[2vw] transition-all duration-300 ease-in-out">
			{/* Logo */}
			<div className="flex flex-row justify-start items-center mb-10">
				<div
					className={`flex items-center align-center`}
					onClick={() => history.push("/")}
				>
					<img src={Logo} alt="" className="aspect-[136/35] max-w-[150px]" />
				</div>
			</div>

			{/* Sidebar Links */}
			<nav className="flex flex-col gap-[1vw] justify-start items-start">
				{sectionConfig.map((section, idx) => (
					<SidebarLink
						key={idx}
						href={section.href}
						icon={section.icon}
						label={section.label}
						location={location}
						subLinks={section.sections}
						toggleMenu={() => {
							return null;
						}}
					/>
				))}
			</nav>
		</div>
	);
};

export default Sidebar;
