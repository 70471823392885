import React, { useRef } from "react";
import Icon from "./icons/grow.png";
import Icon2 from "./icons/online.png";
import Icon3 from "./icons/offline.png";
import Icon4 from "./icons/secure.png";
import abstract from "./icons/speed.png";
import abstracthover from "./icons/speed-hover.png";
import { motion } from "framer-motion";

const Homepage6 = () => {
	const nextSectionRef = useRef(null);

	return (
		<div
			ref={nextSectionRef}
			id="brand"
			className="relative flex justify-center items-center w-full py-[156px] flex flex-col px-[152px] lg:px-[70px] md:px-[30px] bg-[#F3F6F9] overflow-hidden"
		>
			<div
				data-aos="fade-down"
				className="absolute w-full z-0 right-0 top-0 aspect-[1839/638] bg-feature bg-center bg-cover"
			></div>
			<div className="w-full max-w-5xl">
			<div className="relative z-10 w-full mb-[96px]">
				<h1 className="font-bold text-[40px] text-black leading-[44px] tracking-[0.04em]">
					All-in-one solution to accept payments
					<br />
					and manage your finances effectively.
				</h1>
				<p className="text-gray-500 mt-4 font-semibold">
					A single platform to accept payments, protect revenue, and control
					your finances.
				</p>
			</div>
			<div className="relative z-10 w-full grid grid-cols-2 grid-flow-row justify-items-center lg:grid-cols-2 grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center">
				{cardData.map((card, index) => (
					<Card
						key={index}
						icon={card.icon}
						title={card.title}
						description={card.description}
						bgColor={card.bgColor}
					/>
				))}
			</div>
			</div>
	
		</div>
	);
};

export default Homepage6;

const cardData = [
	{
		icon: Icon,
		bgColor: "bg-[#F6F0EF]",
		title: "Grow your business",
		description:
			"Give your customers the gift of simple payments. Integrate Sayswitch once and let your customers pay however they want. We want businesses to thrive in any market around the world.",
	},
	{
		icon: Icon2,
		bgColor: "bg-[#FFEDE7]",
		title: "Online solution",
		description:
			"Enjoy easy, secure, and speedy online payments with Sayswitch. Accept payments on any device or channel, regardless of your business model. Effortlessly add payment methods and boost your conversion rates with our advanced payment solution.",
	},
	{
		icon: Icon3,
		bgColor: "bg-[#EAE3FC]",
		title: "Offline solution",
		description:
			"Experience easy, secure, and speedy payments with Sayswitch. Accept payments offline on any device or channel, no matter your business model. Easily add payment methods and boost your conversion rates with our seamless payment solution.",
	},
	{
		icon: Icon4,
		bgColor: "bg-[#E8F4FF]",
		title: "Secure and Scalable",
		description:
			"We are PCIDSS certified. Security standards using encryption to offer a safe and friction-free experience for your customers. Every device comes embedded with enhanced security keeping your data protected.",
	},
];

const Card = ({ icon, title, description, bgColor }) => {
	return (
		<motion.div
			whileHover={{ scaleX: 1.05, scaleY:1.05 }}
			transition={{ type: "spring", stiffness: 300, damping: 20 }}
			className={`relative ${bgColor} hover:bg-[#1D1B05] max-w-md aspect-square min-h-[362px] border-1 rounded-[10px] overflow-hidden flex flex-col h-full group shadow-lg transition-all duration-100 ease-in-out transform hover:shadow-2xl hover:scale-105`}
		>
			<div className="px-[24px] py-[40px] lg:w-full flex-1 flex flex-col h-full">
				<img
					src={abstract}
					alt=""
					className="absolute w-24 -bottom-8 -left-3 transition-all duration-300 ease-in-out group-hover:opacity-0"
				/>
				<img
					src={abstracthover}
					alt=""
					className="absolute w-24 -bottom-1 -left-2 transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100"
				/>
				<h5 className="mb-10 font-bold text-[32px] leading-[30px] tracking-[0.02em] text-[#1E2C24] group-hover:text-white">
					{title}
				</h5>
				<p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F] group-hover:text-white">
					{description}
				</p>
				<motion.div
					initial={{ opacity: 0, x: -20 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ delay: 0.3, type: "spring", stiffness: 100 }}
					className="items-center absolute bottom-4 right-4 justify-end p-0 w-full items-center flex hidden group-hover:flex"
				>
					<img src={icon} alt="" className="w-20" />
				</motion.div>
			</div>
		</motion.div>
	);
};
