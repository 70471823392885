import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import valuesImage from "./images/values.png";
import responsibilityImage from "./images/responsibility.png";
import list from "./images/list.png";
import hoverlist from "../Products/Gateway/icons/list.png";

const PromiseSection = () => {
	const [activeSection, setActiveSection] = useState("values");

	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	const sectionData = {
		values: {
			header: "Our Values",
			subheader:
				"At SaySwitch, we are passionate about payment experience, and we are building for today and tomorrow needs.",
			items: [
				"We delight our customers.",
				"We honor our commitments to one another.",
				"We communicate with Impact.",
				"We create our own reality.",
				"We build trust and maintain integrity.",
			],
			image: valuesImage,
		},
		responsibility: {
			header: "Social Responsibility",
			subheader:
				"At Sayswitch, we are committed to creating a positive impact through our business practices. We prioritize ethical standards, ensuring our operations are transparent and responsible.",
			items: [
				"Our dedication to social responsibility drives us to support community initiatives, promote sustainability, and uphold the highest levels of integrity in everything we do.",
				"By fostering a culture of ethical excellence, we aim to contribute to a better and more equitable world.",
				"For our position in society, it was a wise decision to make choices that would help us build an ethical business while also driving growth for our merchants.",
			],
			image: responsibilityImage,
		},
	};

	const headerTextSize =
		"6xl:text-[72px] 5xl:text-[64px] 4xl:text-[56px] 3xl:text-[42px] 2xl:text-[42px] 1xl:text-[40px] xl:text-[40px] lg:text-[34px] md:text-[34px] sm:text-[34px] xs:text-[34px] tracking-wide";
	const subheaderTextSize =
		"6xl:text-[28px] 5xl:text-[28px] 4xl:text-[28px] 3xl:text-[24px] 2xl:text-[24px] 1xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[14px] xs:text-[12px]";
	const cardTextSize =
		"6xl:text-[28px] 5xl:text-[26px] 4xl:text-[26px] 3xl:text-[24px] 2xl:text-[22px] 1xl:text-[22px] xl:text-[22px] lg:text-[20px] md:text-[20px] sm:text-[16px] xs:text-[14px]";
	const buttonTextSize =
		"6xl:text-[28px] 5xl:text-[28px] 4xl:text-[28px] 3xl:text-[24px] 2xl:text-[24px] 1xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[14px] xs:text-[12px]";
	const buttonPadding =
		"px-[32px] md:px-[20px] xs:px-[16px] py-[20px] md:py-[12px] focus";
	const onselect = `transition duration-300 hover:bg-primary hover:text-white focus:outline-none focus:ring-1 focus:ring-green-200 focus:ring-opacity-50 focus:scale-105`;

	return (
		<main className="p-[6vw] md:p-[8vw] pt-[18vw] md:pt-[18vw] relative">
			<div
				data-aos="fade-down"
				className="absolute w-full z-0 right-0 top-0 aspect-[1839/638] bg-about md:bg-feature bg-center bg-cover"
			></div>
			<section className="z-10 gap-[6vw]">
				<div className="mb-20 md:mb-12 z-20">
					<h1
						className={`
                        ${headerTextSize}
                        font-bold text-left`}
					>
						Our Promise: <br />
						Values and Responsibility
					</h1>
				</div>

				<div className="flex justify-start space-x-8 md:space-x-4 mb-[2vw] md:mb-[4vw] sm:mb-[6vw] z-20 gap-[1vw]">
					<button
						onClick={() => setActiveSection("values")}
						className={`  ${buttonTextSize}  ${buttonPadding}  ${onselect} rounded-[100px] lg:rounded-3xl transition duration-300 z-20 ${
							activeSection === "values"
								? "bg-primary text-white border-4 sm:border-none border-green-200"
								: "bg-none text-[#7a7a7a] font-light border border-[#A3ADC2]"
						}`}
					>
						CORE VALUES
					</button>
					<button
						onClick={() => setActiveSection("responsibility")}
						className={`  ${buttonTextSize}  ${buttonPadding} ${onselect} rounded-[100px] lg:rounded-3xl transition duration-300 z-20 ${
							activeSection === "responsibility"
								? "bg-primary text-white border-4 sm:border-none border-green-200"
								: "bg-none text-[#7a7a7a] font-light border border-[#A3ADC2]"
						}`}
					>
						SOCIAL RESPONSIBILITY
					</button>
				</div>

				<div className="flex flex-row lg:flex-col items-stretch gap-[6vw]">
					<motion.div
						className="w-full mb-8 md:mb-0"
						initial={{ opacity: 0, x: -50 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
					>
						<img
							src={sectionData[activeSection].image}
							alt={sectionData[activeSection].header}
							className="w-full h-auto"
						/>
					</motion.div>

					<motion.div
						className="w-full pl-8 md:pl-0 flex flex-col flex-grow"
						initial={{ opacity: 0, x: 50 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 0.5 }}
						data-aos="fade-up"
					>
						<div>
							<h1 className={`font-normal mb-4 ${headerTextSize}`}>
								{sectionData[activeSection].header}
							</h1>
							<p className={`font-light mb-4 ${subheaderTextSize}`}>
								{sectionData[activeSection].subheader}
							</p>
						</div>

						<div className="space-y-[2vw] mt-[2vw] flex-grow">
							{sectionData[activeSection].items.map((item, index) => (
								<div
									key={index}
									className="flex items-center space-x-4
                                p-[2vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform ease-in-out duration-300
              rounded-xl bg-green-100/10
                                
                                "
								>
									<div className="relative w-[24px] h-[24px] mr-[20px]">
										<img
											src={list}
											alt="list"
											className="absolute inset-0 w-full h-full group-hover:hidden"
										/>
										<img
											src={hoverlist}
											alt="hover list"
											className="absolute inset-0 w-full h-full hidden group-hover:block"
										/>
									</div>

									<p
										className={` ${cardTextSize} font-light group-hover:text-primary`}
									>
										{item}
									</p>
								</div>
							))}
						</div>
					</motion.div>
				</div>
			</section>
		</main>
	);
};

export default PromiseSection;
