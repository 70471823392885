// src/components/ScrollToTop.jsx
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, [location.pathname]); // Trigger when the pathname changes

  return children;
};

export default withRouter(ScrollToTop);
