import React from "react";

const PandCseverability = () => {
  return (
    <>
      If any portion of these Terms of Use is held by any court or tribunal to
      be invalid or unenforceable, either in whole or in part, then that part
      shall be severed from these Terms of Use and shall not affect the validity
      or enforceability of any other part in this Terms of Use.
      <span id="terms"></span>
    </>
  );
};

export default PandCseverability;
