// getDescription.js

const getDescription = (method, endpoint) => {
    switch (method) {
      case "POST":
        if (endpoint.includes("/validate")) {
          return "Use this endpoint to validate a customer's account information for various services.";
        } else if (endpoint.includes("/payout")) {
          return "Use this endpoint to initiate a payout to a customer’s account.";
        } else if (endpoint.includes("/topup")) {
          if (endpoint.includes("/airtime")) {
            return "Use this endpoint to top up a customer’s airtime balance.";
          } else if (endpoint.includes("/internet")) {
            return "Use this endpoint to top up a customer’s internet data balance.";
          } else if (endpoint.includes("/electricity")) {
            return "Use this endpoint to recharge a customer's electricity account.";
          }
        } else if (endpoint.includes("/login")) {
          return "Use this endpoint for user authentication and login.";
        } else if (endpoint.includes("/refunds")) {
          return "Use this endpoint to initiate a refund request.";
        } else {
          return "Use this endpoint to create a new resource or perform a specific action.";
        }
  
      case "PUT":
        if (endpoint.includes("/customers")) {
          return "Use this endpoint to update an existing customer's information.";
        }
        return "Use this endpoint to update a resource.";
  
      case "GET":
        if (endpoint.includes("/settlements")) {
          return "Use this endpoint to retrieve settlement information for customers.";
        } else if (endpoint.includes("/bills/history")) {
          if (endpoint.includes("/{reference}")) {
            return "Use this endpoint to fetch detailed history for a specific bill reference.";
          }
          return "Use this endpoint to retrieve the history of all bills.";
        } else if (endpoint.includes("/customers")) {
          return "Use this endpoint to fetch customer details.";
        } else if (endpoint.includes("/transactions")) {
          return "Use this endpoint to get transaction history for a wallet.";
        } else {
          return "Use this endpoint to retrieve data or resources.";
        }
  
      default:
        return "Use this endpoint to interact with the API.";
    }
  };
  
  export default getDescription;
  