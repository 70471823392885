import React from "react";

const PandCIndemnity = () => {
  return (
    <>
      You agree to defend, indemnify, and hold Sayswitch, its officers,
      directors, employees, agents, licensors, and suppliers, harmless from and
      against any claims, actions or demands, liabilities and settlements
      including without limitation, reasonable legal and accounting fees,
      resulting from, or alleged to result from, your violation of these
      Agreement.
    </>
  );
};

export default PandCIndemnity;
