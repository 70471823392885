import React, { useState } from "react";
import { Country, State } from "country-state-city";
import ReactCountryFlag from "react-country-flag";
import submitFormAsEmail from "../../util/submitFormAsEmail";
import { AiOutlineLoading3Quarters } from "react-icons/ai";



function ContactForm() {
	const [selectedCountry, setSelectedCountry] = useState("NG"); // Default to Nigeria's ISO code
	const [selectedState, setSelectedState] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [formData, setFormData] = useState({
		fullName: "",
		companyName: "",
		workEmail: "",
		country: "NG",
		state: "",
		phoneNumber: "",
		message: "",
	});

	// Handle input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleCountryChange = (e) => {
		const country = Country.getCountryByCode(e.target.value);
		setSelectedCountry(country);
		setSelectedState(null); // Reset state when country changes
		setSelectedCity(""); // Reset city when country changes
		//console.log(selectedCountry);
		setFormData((prevData) => ({
			...prevData,
			country: e.target.value,
			state: "",
		}));
	};

	const handleStateChange = (e) => {
		const state = State.getStateByCodeAndCountry(
			e.target.value,
			selectedCountry?.isoCode
		);
		setSelectedState(state);
		setSelectedCity(""); // Reset city when state changes
		//console.log(selectedState, selectedCity);
		setFormData((prevData) => ({
			...prevData,
			state: e.target.value,
		}));
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(formData);

		// Example of a basic form validation (you can expand this)
		if (!formData.fullName || !formData.workEmail || !formData.message) {
			alert("Please fill in all the required fields.");
			return;
		}
		setIsSubmitting(true);
		try {
			await submitFormAsEmail(
				formData,
				"hello@sayswitchgroup.com",
				"Your form has been submitted successfully."
			);
			setFormData({
				fullName: "",
				companyName: "",
				workEmail: "",
				country: "NG",
				state: "",
				phoneNumber: "",
				message: "",
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	// Responsive text size
	const textSize =
		"6xl:text-[30px] 5xl:text-[28px] 4xl:text-[25px] 3xl:text-[22px] 2xl:text-[20px] 1xl:text-[18px] xl:text-[16px] lg:text-md md:text-sm sm:text-sm xs:text-sm";

	// Responsive padding
	const paddingSize = "p-[1vw] md:p-[2vw] sm:p-[3vw]";

	return (
		<div
			data-aos="fade-up"
			className="flex flex-col font-normal justify-between flex-grow p-[4vw] md:p-[6vw] bg-[#F3F9F3] border rounded-2xl shadow-lg"
		>
			<form className="space-y-6 flex flex-col flex-grow">
				{/* Full Name */}
				<div className="flex-grow" data-aos="fade-right">
					<label
						htmlFor="fullName"
						className={`block ${textSize} font-normal text-gray-700`}
					>
						Full Name
					</label>
					<input
						type="text"
						id="fullName"
						name="fullName"
						value={formData.fullName}
						onChange={handleChange}
						placeholder="Enter your full name"
						className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
					${textSize} 
					bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
					/>
				</div>

				{/* Company Name and Work Email */}
				<div className="grid grid-cols-2 sm:grid-cols-1 gap-6 flex-grow">
					<div>
						<label
							htmlFor="companyName"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							Company Name
						</label>
						<input
							type="text"
							id="companyName"
							name="companyName"
							value={formData.companyName}
							onChange={handleChange}
							placeholder="Enter your company name"
							className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
						${textSize} 
						bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
						/>
					</div>
					<div>
						<label
							htmlFor="workEmail"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							Work Email
						</label>
						<input
							type="email"
							id="workEmail"
							name="workEmail"
							value={formData.workEmail}
							onChange={handleChange}
							placeholder="partner@gmail.com"
							className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
						${textSize} 
						bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
						/>
					</div>
				</div>

				{/* Country and State */}
				<div className="grid grid-cols-2 sm:grid-cols-1 gap-6 flex-grow">
					{/* Country Selection */}
					<div>
						<label
							htmlFor="country"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							Country
						</label>
						<div className="relative">
							{/* Flag as prefix */}
							<span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
								<ReactCountryFlag
									countryCode={selectedCountry?.isoCode || "NG"}
									svg
									style={{
										width: "1.5em",
										height: "1.5em",
										borderRadius: "50%",
										border: "1px solid gray",
									}}
									title={Country.getCountryByCode(selectedCountry)?.name}
								/>
							</span>

							<select
								id="country"
								name="country"
								value={selectedCountry?.isoCode || "NG"}
								onChange={handleCountryChange}
								className={`mt-[1vw] block w-full pl-12 py-[1vw] md:py-[2vw] sm:py-[3vw] border border-gray-300 
							${textSize} 
							bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
							>
								{Country.getAllCountries().map((country) => (
									<option key={country.isoCode} value={country.isoCode}>
										{country.name}
									</option>
								))}
							</select>
						</div>
					</div>
					<div>
						<label
							htmlFor="state"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							State
						</label>
						{selectedCountry && (
							<select
								id="state"
								name="state"
								value={formData.state}
								onChange={handleStateChange}
								className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
							${textSize} 
							bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
							>
								<option value="">Select your state</option>
								{State.getStatesOfCountry(selectedCountry?.isoCode || "NG").map(
									(state) => (
										<option key={state.isoCode} value={state.isoCode}>
											{state.name}
										</option>
									)
								)}
							</select>
						)}
					</div>
				</div>

				{/* Phone Number */}
				<div className="flex-grow grid grid-cols-4 gap-2">
					<div className="col-span-1">
						<label
							htmlFor="countryCode"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							Code
						</label>
						<input
							type="text"
							id="countryCode"
							name="countryCode"
							value={
								selectedCountry ? `+${selectedCountry.phonecode || "234"}` : ""
							}
							readOnly
							className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
						${textSize} 
						bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
						/>
					</div>
					<div className="col-span-3">
						<label
							htmlFor="phoneNumber"
							className={`block ${textSize} font-normal text-gray-700`}
						>
							Phone Number
						</label>
						<input
							type="tel"
							id="phoneNumber"
							name="phoneNumber"
							value={formData.phoneNumber}
							onChange={handleChange}
							placeholder="Enter your phone number"
							className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
						${textSize} 
						bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
						/>
					</div>
				</div>

				{/* Leave a Message */}
				<div className="flex-grow">
					<label
						htmlFor="message"
						className={`block ${textSize} font-normal text-gray-700`}
					>
						Leave a Message
					</label>
					<textarea
						id="message"
						name="message"
						placeholder="Enter your message"
						value={formData.message}
						onChange={handleChange}
						rows="4"
						className={`mt-[1vw] block w-full ${paddingSize} border border-gray-300 
					${textSize} 
					bg-[#F3F9F3] rounded-2xl shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
					/>
				</div>

				{/* Submit Button */}
				<div className="flex-grow flex items-end">
					<button
						disabled={isSubmitting}
						onClick={handleSubmit}
						type="submit"
						className={`w-full ${paddingSize} ${textSize} hover:text-white bg-accent text-black font-normal rounded-3xl shadow hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
					>
						{isSubmitting ? (
							<>
								<AiOutlineLoading3Quarters className="animate-spin mr-2" />{" "}
								Submitting...
							</>
						) : (
							"Submit"
						)}
					</button>
				</div>
			</form>
		</div>
	);
}

export default ContactForm;
