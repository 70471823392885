import React from "react";

const PandCRestrictedActivitiesAndPolicy = () => {
  return (
    <>
      You are independently responsible for complying with all applicable laws
      related to your use of our Portal and services. However, by accessing or
      using Sayswitch, you agree to comply with the terms and conditions of our
      Acceptable Use Policy and are restricted from the activities specified in
      it which you can read on our Acceptable Use Policy page.
    </>
  );
};

export default PandCRestrictedActivitiesAndPolicy;
