import React from "react";

const PandCexclusions = () => {
  return (
    <>
      Some jurisdictions do not allow the exclusion of certain warranties or the
      limitation or exclusion of liability for certain damages. Accordingly,
      some of the above disclaimers and limitations of liability may not apply
      to you. To the extent that any ITEX Party may not, as a matter of
      applicable law, disclaim any implied warranty or limit its liabilities,
      the scope and duration of such warranty and the extent of the ITEX’s
      Party's liability shall be the minimum permitted under such applicable
      law.
    </>
  );
};

export default PandCexclusions;
