import React from "react";
import AboutOverview from "../components/About/AboutOverview";
import TheSponsors from "../components/Partners/TheSponsors";

import ReadyToStart from "../components/Cta/ReadyToStart";

import { motion } from "framer-motion";
import AboutSection from "../components/About/AboutSection";
import PromiseSection from "../components/About/PromiseSection";
import SecuritySection from "../components/Security/SecuritySection";
import MissionSection from "../components/About/MissionSection";

const AboutView = () => {
  return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
  "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
      <AboutOverview />
      <TheSponsors />
      <AboutSection />
      <PromiseSection />
      < MissionSection/>
      <SecuritySection />
      <ReadyToStart />
      </motion.div>  );
};

export default AboutView;
