import React from "react";

const PandCNotificationOfErrors = () => {
  return (
    <>
      You agree to notify us immediately any error is detected while reconciling
      transactions that have occurred using Sayswitch. We will investigate and
      rectify the errors where verified. In the event that we notice any errors,
      we will also investigate and rectify such errors. Where we owe you money
      as a result of such errors, we will refund the amounts owed to you by a
      bank transfer to your Bank Account. If a transaction is erroneously
      processed through your platform, report to us immediately. We will
      investigate any such reports and attempt to rectify the errors by
      crediting or debiting your Bank Account as appropriate.
      <br />
      <br />
      Failure to notify us within 45 (forty-five) days of the occurrence of an
      error will be deemed a waiver of your rights to amounts that are owed to
      you due to an error.
    </>
  );
};

export default PandCNotificationOfErrors;
