import React from "react";

import Checkout from "../../components/Products/Checkout";

const CheckoutView = () => {
  return (
      <div className="w-full scrollbar-hide relative">
        <Checkout />
      </div>
  );
};

export default CheckoutView;
