import React, { useRef } from "react";
import ContactHero from "./ContactHero";
import { motion } from "framer-motion";
import ContactSection from "./ContactCard";
import Form from "./Form";
import ReadyToStart from "../Cta/ReadyToStart";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";

const ContactUs = () => {
	const formRef = useRef(null);

	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
"
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<ContactHero scrollToForm={() => formRef.current.scrollIntoView({ behavior: "smooth" })}/>
			<ContactSection />
			<Form ref={formRef} />
			<FrequentlyAskedQuestion />
			<ReadyToStart />
		</motion.div>
	);
};

export default ContactUs;
