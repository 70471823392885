import React, { useRef } from "react";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";
import ReadyToStart from "../Cta/ReadyToStart";
import { motion } from "framer-motion";
import Hero from "./Hero";
import BenefitsSection from "./Benefits";
import HiringSection from "./Hiring";


const AboutCareers = () => {
  const formRef = useRef(null);
	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
"
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<Hero
				scrollToForm={() =>
					formRef.current.scrollIntoView({ behavior: "smooth" })
				}
			/>
			<BenefitsSection/>
			<HiringSection ref={formRef}/>
			<FrequentlyAskedQuestion />
			<ReadyToStart />
		</motion.div>
	);
};


export default AboutCareers;
