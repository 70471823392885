import React from "react";
import Icon1 from "./market.png";
import Icon2 from "./network.png";
import Icon3 from "./manager.png";
import vector from "./Vector.png";

const cardData = [
	{
		icon: Icon1,
		title: "Marketing",
		description:
			"We take time to build relationships with our partners and love to collaborate with them to create marketing campaigns.",
		bgColor: "#1A3C03",
	},
	{
		icon: Icon2,
		title: "Partner Network",
		description:
			"With our partners we are able to focus on your needs where each customer has a unique custom-made payment solution.",
		bgColor: "#784021",
	},
	{
		icon: Icon3,
		title: "Dedicated Technical Account Manager",
		description:
			"With partners we grow together and by supporting you with our dedicated technical account manager.",
		bgColor: "#1B1F28",
	},
];

const Card = ({ icon, title, description, bgColor }) => (
	<div
		className="aspect-square bounce group flex flex-col justify-center rounded-lg shadow-xl p-[3vw] md:p-[6vw] gap-[2vw] md:gap-[8vw]
    max-w-[640px] max-h-[640px] overflow-scroll scrollbar-hide"
		style={{
			backgroundColor: bgColor,
			backgroundImage: `url(${vector})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
		}}
	>
		<img
			data-aos="fade-right"
			src={icon}
			alt=""
			className="aspect-square max-w-[5vw] lg:max-w-[10vw] md:max-w-[12w] sm:max-w-[14vw] group-hover:scale-105"
		/>
		<h1
			data-aos="fade-right"
			className="font-semibold text-white text-left 
            text-[2vw] xl:text-[45px] lg:text-[35px] md:text-[25px]
            text-ellipsis overflow-hidden
            "
		>
			{title}
		</h1>
		<p
			data-aos="fade-right"
			className="text-left text-white font-light xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 
            1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl 
            tracking-wider leading-[50px]
            text-ellipsis overflow-hidden
            "
		>
			{description}
		</p>
	</div>
);

const WhyChooseUs = () => {
	return (
		<div className="w-full flex flex-col p-[8vw] bg-white gap-[8vw]">
			<div
				data-aos="fade-down"
				className="w-full flex flex-row md:flex-col justify-center items-start gap-[4vw]"
			>
				<h1
					data-aos="fade-right"
					className="font-bold w-1/2 md:w-full
          text-[4vw] xl:text-[45px] xs:text-[30px]
          text-black leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] 
          md:leading-[50px] lg:leading-[50px] tracking-[0.02em] lg:text-[35px] text-left
          "
				>
					Why choose us as
					<br />a payment partner?
				</h1>
				<p
					data-aos="fade-left"
					className="font-normal w-1/2 md:w-full
          text-left text-black 
          text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
          3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl tracking-wider 
          xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 
          3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]"
				>
					We will support you and your team in achieving your desired goal(s) on
					payment and transaction processing services. With our wide range of
					services tailored just for you, we would ensure a win-win partnership.
				</p>
			</div>
			<div
				className="relative z-10 w-full grid grid-cols-3 3xl:grid-cols-3 2xl:grid-cols-3 1xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 
	grid-flow-row justify-items-center lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center"
			>
				{cardData.map((card, index) => (
					<Card
						key={index}
						icon={card.icon}
						title={card.title}
						description={card.description}
						bgColor={card.bgColor}
					/>
				))}
			</div>
		</div>
	);
};

export default WhyChooseUs;
