import React, { useState } from "react";

const APIDocsSection = () => {
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		const code = `
      GET /api/v1/users
      Response:
      {
        "id": 1,
        "name": "John Doe",
        "email": "john.doe@example.com"
      }
    `;
		navigator.clipboard.writeText(code);
		setCopied(true);

		// Reset the "Copied!" status after a short delay
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	return (
		<div className="py-2xl flex flex-col lg:p-8">
			<h2 className="text-1 font-bold mb-4">API Documentation</h2>
			<p className="font-light text-2 max-w-3xl">
				Access detailed information about each API endpoint, including request
				formats, responses, and error handling.
			</p>

			{/* Preformatted block for API response */}
			<div className="bg-gray-900 text-white p-4 text-3 rounded-lg mt-4 relative">
				<pre className="whitespace-pre-wrap">
    {`
        GET /api/v1/users
        Response:
        {
          "id": 1,
          "name": "John Doe",
          "email": "john.doe@example.com"
        }
  `}
				</pre>

				{/* Copy button */}
				<button
					onClick={handleCopy}
					className="absolute top-4 right-4 bg-blue-500 text-white px-sm py-2 text-4 rounded-lg hover:bg-blue-600 transition-all duration-300"
				>
					{copied ? "Copied!" : "Copy"}
				</button>
			</div>
		</div>
	);
};

export default APIDocsSection;
