import React from "react";
import SVGGridPattern from "./SVGGridPattern";

const HeroSection = ({ children }) => {
	return (
		<div
			className="w-full relative flex flex-col flex-1 justify-center items-center
        p-4 pt-6 xs:px-4 xs:pt-8 sm:px-4 sm:pt-10 md:px-4 md:pt-16 lg:px-6 lg:pt-18 
        xl:px-14 xl:pt-20 1xl:px-14 1xl:pt-20 2xl:px-16 2xl:pt-22 3xl:px-16 3xl:pt-22 4xl:px-16 4xl:pt-22 
        5xl:px-16 5xl:pt-22 6xl:px-16 6xl:pt-22"
			data-aos="fade-up"
		>
			{" "}
			<div className="absolute inset-0 bg-abstract bg-cover bg-center bg-blend-color-dodge"></div>
			<SVGGridPattern />
			{children}
		</div>
	);
};

export default HeroSection;
