// ApiComponents.js
import React from "react";
import apiData from "./apiData";
import ApiSectionContainer from "./ApiSectionContainer";

// Generic component for rendering API sections
const ApiComponent = ({ apiKey }) => {
	const apiProps = apiData[apiKey];
	return <ApiSectionContainer {...apiProps} />;
};

// Dynamically generate components and configuration based on apiData
export const generateApiRefComponents = () =>
	Object.keys(apiData).reduce((acc, key) => {
		acc[key] = () => <ApiComponent apiKey={key} />;
		return acc;
	}, {});

// API components configuration derived from apiData
export const apiRefComponentsConfig = Object.keys(apiData).map((key) => ({
	key,
	label: apiData[key].title, // Use the title from apiData
	method: apiData[key].method, // Use the method from apiData
}));
