import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS CSS
import { motion } from "framer-motion"; // Import Framer Motion

// Sample FAQ data
const faqData = [
	{
		question: "How do I reset my password?",
		answer:
			"To reset your password, go to the settings page and click on 'Reset Password'. Follow the instructions sent to your email.",
	},
	{
		question: "What is your refund policy?",
		answer:
			"Our refund policy allows for refunds within 30 days of purchase. For more details, please visit our refund policy page.",
	},
	{
		question: "How can I contact support?",
		answer:
			"You can contact support by emailing us at hello@sayswitchgroup.com or through our contact form on the website.",
	},
	{
		question: "Where can I find my purchase history?",
		answer:
			"You can find your purchase history in the 'Orders' section of your account dashboard.",
	},
	{
		question: "Do you offer discounts for bulk purchases?",
		answer:
			"Yes, we offer discounts for bulk purchases. Please contact our sales team for more information.",
	},
	{
		question: "Is there a mobile app available?",
		answer:
			"Yes, we have a mobile app available for both iOS and Android. You can download it from the App Store or Google Play.",
	},
];

const FrequentlyAskedQuestion = () => {
	const [activeIndex, setActiveIndex] = useState(null);
	const history = useHistory();

	// Initialize AOS on component mount
	useEffect(() => {
		AOS.init({
			duration: 800, // Animation duration
			once: true, // Animation occurs once
		});
	}, []);

	// Function to toggle active FAQ item
	const toggleFAQ = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};

	return (
		<div className="w-full p-[8vw] md:p-[4vw] flex flex-col bg-[#F3F6F9] justify-start items-start">
			<h3 className="text-left font-extrabold h3 p-xl">
				Frequently Asked Questions
			</h3>

			{/* Speak With Us Button */}
			<div className="text-1 p-xl">
				<p className="font-normal">
					Can’t find the answer here?{" "}
					<span
						className="text-primary cursor-pointer hover:underline"
						onClick={() => history.push("/contactus")}
					>
						Speak with Us
					</span>
				</p>
			</div>

			{/* FAQ List */}
			<motion.div
				className="w-full p-xl"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5 }}
			>
				{faqData.map((item, index) => (
					<motion.div
						key={index}
						data-aos="fade-up" // AOS fade animation on scroll
						className="w-full mb-4"
						whileHover={{ scale: 1.01 }} // Framer Motion hover effect
						whileTap={{ scale: 0.98 }}
					>
						<div
							className={`flex items-center justify-between py-sm gap-2 cursor-pointer border-b border-b-slate-200 ${
								activeIndex === index ? "text-primary border-none" : ""
							}`}
							onClick={() => toggleFAQ(index)}
						>
							<p className="font-normal text-1 leading-[26px]">
								{item.question}
							</p>
							<span>
								{activeIndex === index ? (
									<FaChevronUp className="text-primary w-6" />
								) : (
									<FaChevronDown className="w-6" />
								)}
							</span>
						</div>

						{/* Show answer when the question is active */}
						{activeIndex === index && (
							<motion.div
								className="text-2 max-w-3xl font-light"
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: "auto", opacity: 1 }}
								transition={{ duration: 0.2 }}
							>
								{item.answer}
							</motion.div>
						)}
					</motion.div>
				))}
			</motion.div>
		</div>
	);
};

export default FrequentlyAskedQuestion;
