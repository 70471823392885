import React from "react";
import GlobalAcceptance from "./Gateway/GlobalAcceptance";
import GrowYourBusiness from "./Gateway/GrowYourBusiness";
import OurGoal from "./Gateway/OurGoal";

import ReadyToStart from "../Cta/ReadyToStart";
import TheBestWay from "./Gateway/TheBestWay";

import { motion } from "framer-motion";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";

const PaymentGateway = () => {
	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
  "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<TheBestWay />
			<OurGoal />
			<GlobalAcceptance />
			<GrowYourBusiness />
			<FrequentlyAskedQuestion />
			<ReadyToStart />
		</motion.div>
	);
};

export default PaymentGateway;
