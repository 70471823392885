// sectionConfig.js
import { FaBook, FaCode, FaLifeRing } from "react-icons/fa";
import { RiCodeView } from "react-icons/ri";
import { TbApi } from "react-icons/tb";
import HeroSection from "../Documents/HeroSection";
import FeaturesSection from "../Documents/FeaturesSection";
import APIDocsSection from "../Documents/APIDocsSection";
import {
	apiComponentsConfig,
	generateApiComponents,
} from "../Documents/ApiComponents";
import Introduction from "../Api/Introduction";
import { apiRefComponentsConfig, generateApiRefComponents } from "../Api/ApiComponents";

// Generate API components dynamically
const apiComponents = generateApiComponents();
const apiRefComponents = generateApiRefComponents();

// Configuration for sidebar links and sections
export const sectionConfig = [
	{
		href: "/documents",
		icon: FaBook,
		label: "Documentation",
		sections: [
			{
				id: "#hero",
				label: "Introduction",
				icon: FaCode,
				component: HeroSection,
			},
			{
				id: "#features",
				label: "Key Features",
				icon: RiCodeView,
				component: FeaturesSection,
			},
			{
				id: "#apidocs",
				label: "API Documentation",
				icon: TbApi,
				component: APIDocsSection,
			},

			// Map over the dynamically generated API components
			...apiComponentsConfig.map(({ key, label, method }) => ({
				id: `#${key}`,
				label,
				method,
				component: apiComponents[key], // Assign the dynamically generated component here
			})),
		],
	},
	{
		href: "/api",
		icon: FaCode,
		label: "API Reference",
		sections: [
			{
				id: "#intro",
				label: "Introduction",
				icon: FaCode,
				component: Introduction,
			},
				// Map over the dynamically generated API components
				...apiRefComponentsConfig.map(({ key, label, method }) => ({
					id: `#${key}`,
					label,
					method,
					component: apiRefComponents[key], // Assign the dynamically generated component here
				})),
		],
	},
	{ href: "/contactus", icon: FaLifeRing, label: "Support" },
];
