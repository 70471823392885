import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { FaArrowUp } from "react-icons/fa"; // Import the FontAwesome icon

const CustomLayout = ({ children }) => {
	// State to track if we should show the "scroll to top" button
	const [showScrollTop, setShowScrollTop] = useState(false);

	// Handle scroll event to toggle scroll-to-top visibility
	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		if (scrollPosition > 300) {
			setShowScrollTop(true); // Show button when scrolled down
		} else {
			setShowScrollTop(false); // Hide button when near top
		}
	};

	// Scroll to the top when the button is clicked
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // Smooth scroll effect
		});
	};

	// Add event listener for scrolling
	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll); // Cleanup
	}, []);

	return (
		<div className="flex flex-col min-h-screen bg-[#F9F8F8]">
			<div className="flex flex-row overflow-hidden relative">
				{/* Sidebar */}
				<div className="w-[20vw] flex flex-col min-h-screen flex lg:hidden">
					<Sidebar />
				</div>

				{/* Main Content */}
				<div className="flex flex-col flex-1 min-h-screen">
					{/* Sticky Navbar */}
					<div className="">
						<Navbar />
					</div>

					{/* Scrollable Children Content */}
					<div className="p-8 lg:p-0 bg-white overflow-y-auto rounded-t-3xl lg:rounded-none">
						{children}
						{/* Scroll to Top Button */}
						{showScrollTop && (
							<button
								onClick={scrollToTop}
								className="fixed bottom-5 right-5 bg-white text-gray-500 p-3 rounded-full shadow-lg hover:bg-green-600 hover:text-white transition-all duration-300"
							>
								<FaArrowUp className="h-6 w-6" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomLayout;
