import React from "react";
import DeveloperPage from "../../components/Developer/Documents";

const DocumentsView = () => {
  return (
    <div className="w-full scrollbar-hide relative">
      <DeveloperPage />
    </div>
  );
};

export default DocumentsView;
