import React from "react";

const PandCcustomerPayments = () => {
  return (
    <>
      You may only process payments when authorized to do so by your customer.
      We will only process transactions that have been authorized by the
      applicable Card Network or card issuer. We do not guarantee or assume any
      liability for transactions authorized and completed that are later
      reversed or charged back (see Chargebacks below). You are solely
      responsible for all reversed or charged back transactions, regardless of
      the reason for, or timing of, the reversal or chargeback. Sayswitch may
      add or remove one or more payment types or networks at any time. If we do
      so we will use reasonable efforts to give you prior notice of the removal.
    </>
  );
};

export default PandCcustomerPayments;
