import React from "react";
import { motion } from "framer-motion";

const HeroContent = () => {
	return (
		<motion.div
			className="flex w-full flex-col flex-1 justify-between text-center items-center space-y-10"
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<h1
				className="capitalize text-black font-[800] 
        text-[28px] xs:text-[32px] sm:text-[36px] md:text-[50px] lg:text-[56px] xl:text-[60px] 1xl:text-[64px] 2xl:text-[64px] 
        mt-8 md:mt-12 lg:mt-12 xl:mt-20 1xl:mt-24 2xl:mt-20 leading-[1.5] 1xl:leading-[1.0] 2xl:leading-[1.0] 3xl:leading-[1.0]
		max-w-[1480px] xl:px-6 1xl:px-20 2xl:px-20"
			>
				Designed for seamless managerial support and efficiency.
			</h1>

			<div
				className="text-black font-base leading-[2.0] xl:leading-[1.5] 1xl:leading-[1.5] 2xl:leading-[1.5] 3xl:leading-[1.5] 4xl:leading-[1.5] 5xl:leading-[1.5]
        text-xs xs:text-base sm:text-base md:text-xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-3xl 1xl:max-w-[1000px] 2xl:max-w-[1200px]
         md:px-4 xl:px-20 1xl:px-30"
			>
				Take full control of your business with our solution. Track expenses,
				analyze, and optimize every step of your payment flow for maximum
				efficiency.
			</div>
			<div
				data-aos="fade-up"
				className="w-full max-w-[1301px] aspect-[1301/813] bg-subhero bg-center bg-cover"
			></div>
		</motion.div>
	);
};

export default HeroContent;
