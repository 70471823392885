import React from "react";

const PandCsoftwareLicense = () => {
  return (
    <>
      We hereby grant you a revocable, non-exclusive, non-transferable license
      to use Sayswitch’s APIs, developer’s toolkit, and other software
      applications (the “Software”) in accordance with the documentation
      accompanying the Software. This license grant includes all updates,
      upgrades, new versions and replacement software for your use in connection
      with the Sayswitch’s services. If you do not comply with the documentation
      and any other requirements provided by Sayswitch, then you will be liable
      for all resulting damages suffered by you, Sayswitch and third parties.
      Unless otherwise provided by applicable law, you agree not to alter,
      reproduce, adapt, distribute, display, publish, reverse engineer,
      translate, disassemble, decompile or otherwise attempt to create any
      source code that is derived from the Software. Upon expiration or
      termination of this Agreement, you will immediately cease all use of any
      Software.
      <span id="trademark"></span>
    </>
  );
};

export default PandCsoftwareLicense;
