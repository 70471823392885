import React from "react";

const PandCconfidencialInfo = () => {
  return (
    <>
      The parties acknowledge that in the performance of their duties under this
      Agreement, either party may communicate to the other (or its designees)
      certain confidential and proprietary information, including without
      limitation information concerning each party’s services, know how,
      technology, techniques, or business or marketing plans (collectively, the
      “Confidential Information”) all of which are confidential and proprietary
      to, and trade secrets of, the disclosing party. Confidential Information
      does not include information that: (i) is public knowledge at the time of
      disclosure by the disclosing party; (ii) becomes public knowledge or known
      to the receiving party after disclosure by the disclosing party other than
      by breach of the receiving party’s obligations under this section or by
      breach of a third party’s confidentiality obligations; (iii) was known by
      the receiving party prior to disclosure by the disclosing party other than
      by breach of a third party’s confidentiality obligations; or (iv) is
      independently developed by the receiving party.
      <br />
      <br />
      As a condition to the receipt of the Confidential Information from the
      disclosing party, the receiving party shall: (i) not disclose in any
      manner, directly or indirectly, to any third party any portion of the
      disclosing party’s Confidential Information; (ii) not use the disclosing
      party’s Confidential Information in any fashion except to perform its
      duties under this Agreement or with the disclosing party’s express prior
      written consent; (iii) disclose the disclosing party’s Confidential
      Information, in whole or in part, only to employees and agents who need to
      have access thereto for the receiving party’s internal business purposes;
      (iv) take all necessary steps to ensure that its employees and agents are
      informed of and comply with the confidentiality restrictions contained in
      this Agreement; and (v) take all necessary precautions to protect the
      confidentiality of the Confidential Information received here under and
      exercise at least the same degree of care in safeguarding the Confidential
      Information as it would with its own confidential information, and in no
      event shall apply less than a reasonable standard of care to prevent
      disclosure.
    </>
  );
};

export default PandCconfidencialInfo;
