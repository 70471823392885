// src/util/submitFormAsEmail.js

const submitFormAsEmail = (formData, recipientEmail, successMessage) => {
	// Dynamically construct the email body based on form data
	let body = "";
	for (const [key, value] of Object.entries(formData)) {
		body += `${key}: ${value}\n`;
	}

	// Create email subject
	const subject = `New Submission`;

	// Format mailto URL
	const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(
		subject
	)}&body=${encodeURIComponent(body)}`;

	// Redirect to mailto link (this opens the user's email client)
	window.location.href = mailtoUrl;

	// Show alert to indicate submission success
	alert(successMessage);
};

export default submitFormAsEmail;
