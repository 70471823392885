import React from "react";
import AboutCareers from "../components/Careers/AboutCareers";
import { motion } from "framer-motion";

const AboutCareerView = () => {
	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
  "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<AboutCareers />
		</motion.div>
	);
};

export default AboutCareerView;
