import React from "react";
import { useRef } from "react";
import { FiChevronDown } from "react-icons/fi";

const HeroSection = ({ children }) => {
	const nextSectionRef = useRef(null);

	const handleScroll = () => {
		if (nextSectionRef.current) {
			nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div
			ref={nextSectionRef}
			className="w-full relative flex flex-col flex-1 justify-center items-center
        p-2 py-6 xs:px-2 xs:py-8 sm:px-4 sm:py-10 md:px-10 md:py-16 lg:px-12 lg:py-16 
        xl:px-14 xl:py-16 1xl:px-14 1xl:py-16 2xl:px-16 2xl:py-16 3xl:px-16 3xl:py-16 4xl:px-16 4xl:py-16 
        5xl:px-16 5xl:py-16 6xl:px-16 6xl:py-16"
			data-aos="fade-up"
		>
			<div className="absolute w-full flex justify-center items-center -top-9 right-0">
				<div className="rounded-full w-24 h-24 bg-[#F3F6F9]">
					{/* Additional styles or content if needed */}
					<button
						onClick={handleScroll}
						className="flex items-center justify-start p-2 w-24 h-24 flex-col"
					>
						<FiChevronDown className="text-[#000] cursor-pointer text-[16px]" />
						<p className="text-[#000] text-[10px]">Scroll Down</p>
					</button>
				</div>
			</div>
			{children}
		</div>
	);
};

export default HeroSection;
