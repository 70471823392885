import React from "react";
import { motion } from "framer-motion";

// Import images correctly
import report from "./images/report.png";
import settlement from "./images/settlement.png";
import brand from "./images/brand.png";
import process from "./images/process.png";

const features = [
	{
		image: report,
		title: "Reporting",
		bgColor: "bg-[#E2FAC0]",
		text: "text-black",
		description:
			"Access reporting tools for monitoring your transactions real time through our web-based user interface or utilize our REST API to build your own reporting tools. Refund transactions with a click of a button.",
	},
	{
		image: settlement,
		title: "Settlement",
		bgColor: "bg-[#191C1F]",
		text: "text-white",
		description:
			"A Single Settlement daily. A full overview of all your transaction fees and payout. Reconcile and match transactions, dispute, and refunds effortlessly. Quicker settlements and payouts."
	},
	{
		image: brand,
		title: "White label branding",
		bgColor: "bg-[#44074F]",
		text: "text-white",
		description:
			"A service where any business has the possibility to white label the SaySwitch Services to their merchants including their own setup on the SaySwitch Gateway.",
	},
	{
		image: process,
		title: "Payment processing",
		bgColor: "bg-[#1F266C]",
		text: "text-white",
		description:
			"Save on fees and increase efficiency when you bring everything together with SaySwitch gateway. Become more integrated and cohesive moving forward in an omnichannel world, both online and in-person.",
	},
];

// Reusable FeatureCard component with framer-motion hover effect
const FeatureCard = ({ image, title, description, bgColor , text}) => (
	<motion.div
		className={`flex group relative flex-col items-start text-left gap-[4vw] lg:gap-[6vw] rounded-3xl ${bgColor} ${text}
			p-2xl py-4xl shadow-lg transition-all duration-300 overflow-clip`}
		whileHover={{
			scale: 1.05,
			transition: { duration: 0.3 },
		}}
	>
		<img src={image} alt={title} className="
		max-h-[480px]
		3xl:max-h-[400px]
		2xl:max-h-[360px]
		1xl:max-h-[320px]
		xl:max-h-[280px]
		lg:max-h-[240px]
		md:max-h-[231.37px]
		sm:max-h-[200px]
		 aspect-[599/444] absolute -bottom-10 right-0" />
		<div className="flex flex-col gap-[2vw] lg:gap-[3vw] tracking-wide">
			<h2
				className="h5 font-normal leading-relaxed
			"
			>
				{title}
			</h2>
			<p className="font-light text-2 leading-loose mb-[18vw] md:mb-[40vw]">
				{description}
			</p>
		</div>
	</motion.div>
);

const Services = () => {
	return (
		<section className="px-[6vw] py-[10vw] bg-white text-black bg-slate-100">
			<div className="text-left md:text-left mb-[4vw] lg:mb-[6vw]">
				<h1 className="h2 font-bold">Services and added value</h1>
			</div>

			<div
				className="grid grid-cols-3 
            3xl:grid-cols-3
            1xl:grid-cols-2
            xl:grid-cols-2
            lg:grid-cols-2
            md:grid-cols-1
             gap-[6vw]"
			>
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						image={feature.image}
						title={feature.title}
						description={feature.description}
						bgColor={feature.bgColor}
						text={feature.text}
					/>
				))}
			</div>
		</section>
	);
};

export default Services;
