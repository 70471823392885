// apiData.js

// Shared data for API headers
const headers = {
	authorization: "Authorization: Bearer <token>",
	contentTypeJson: "Content-Type: application/json",
};

// Base URL for the API
const baseUrl = "https://backendapi.staging-sayswitchgroup.com/api/v1";

// Helper function to generate API call configuration
const createApiCall = (
	method,
	title,
	endpoint,
	headers,
	body = "",
	response = "",
	status = "200 OK"
) => ({
	method,
	title,
	endpoint,
	headers,
	body,
	response,
	status,
});

// Helper function to format body and response JSON
const formatJson = (data) => JSON.stringify(data, null, 2);

// API data configuration
export const apiData = {
	createCustomer: createApiCall(
		"POST",
		"Create Customer",
		`${baseUrl}/customers`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			name: "John Doe",
			email: "john.doe@example.com",
			phone: "+1234567890",
		}),
		formatJson({
			id: 1,
			name: "John Doe",
			email: "john.doe@example.com",
			phone: "+1234567890",
		})
	),

	listCustomers: createApiCall(
		"GET",
		"List Customers",
		`${baseUrl}/customers`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				name: "James Mitch",
				email: "john.doe@example.com",
				phone: "+1234567890",
			},
			{
				id: 2,
				name: "Jane Doe",
				email: "jane.doe@example.com",
				phone: "+0987654321",
			},
		])
	),

	fetchCustomer: createApiCall(
		"GET",
		"Fetch Customer",
		`${baseUrl}/customers/{id}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			name: "James Mitch",
			email: "james.mitch@example.com",
			phone: "+1234567890",
		})
	),

	updateCustomer: createApiCall(
		"PUT",
		"Update Customer",
		`${baseUrl}/customers/{id}`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			name: "John Smith",
			email: "john.smith@example.com",
			phone: "+1234567890",
		}),
		formatJson({
			id: 1,
			name: "John Smith",
			email: "john.smith@example.com",
			phone: "+1234567890",
		})
	),

	validateCustomer: createApiCall(
		"POST",
		"Validate Customer",
		`${baseUrl}/customers/validate`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({ id: 1 }),
		formatJson({
			id: 1,
			status: "Validated",
			message: "Customer validation successful.",
		})
	),

	payoutFee: createApiCall(
		"POST",
		"Payout Fee",
		`${baseUrl}/customers/payout`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({ id: 1, amount: 100 }),
		formatJson({
			id: 1,
			amount: 100,
			status: "Payout Successful",
			transactionId: "abc123",
		})
	),

	listSettlements: createApiCall(
		"GET",
		"List Settlements",
		`${baseUrl}/customers/settlements`,
		[headers.authorization],
		"",
		formatJson([
			{ id: 1, amount: 200, date: "2024-09-01", status: "Completed" },
			{ id: 2, amount: 150, date: "2024-09-02", status: "Pending" },
		])
	),

	fetchSettlementDetails: createApiCall(
		"GET",
		"Fetch Settlement Details",
		`${baseUrl}/customers/settlements/{id}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			amount: 200,
			date: "2024-09-01",
			status: "Completed",
			transactionId: "settle123",
		})
	),

	createDedicatedVirtualAccount: createApiCall(
		"POST",
		"Create Dedicated Virtual Account",
		`${baseUrl}/accounts/dedicated`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			accountName: "Business Account",
			currency: "USD",
			accountType: "Dedicated",
		}),
		formatJson({
			id: 1,
			accountName: "Business Account",
			currency: "USD",
			accountType: "Dedicated",
		})
	),

	listDedicatedVirtualAccounts: createApiCall(
		"GET",
		"List Dedicated Virtual Accounts",
		`${baseUrl}/accounts/dedicated`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				accountName: "Business Account",
				currency: "USD",
				accountType: "Dedicated",
			},
			{
				id: 2,
				accountName: "Savings Account",
				currency: "USD",
				accountType: "Dedicated",
			},
		])
	),

	fetchDedicatedVirtualAccount: createApiCall(
		"GET",
		"Fetch Dedicated Virtual Account",
		`${baseUrl}/accounts/dedicated/{id}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			accountName: "Business Account",
			currency: "USD",
			accountType: "Dedicated",
		})
	),

	bankList: createApiCall(
		"GET",
		"Bank List",
		`${baseUrl}/banks`,
		[headers.authorization],
		"",
		formatJson([
			{ id: 1, name: "Bank A" },
			{ id: 2, name: "Bank B" },
		])
	),

	getWalletTransactions: createApiCall(
		"GET",
		"Get Wallet Transactions",
		`${baseUrl}/wallet/transactions`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				type: "Credit",
				amount: 200,
				date: "2024-09-01",
			},
			{
				id: 2,
				type: "Debit",
				amount: 150,
				date: "2024-09-02",
			},
		])
	),

	listPayouts: createApiCall(
		"GET",
		"List Payouts",
		`${baseUrl}/payouts`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				amount: 100,
				date: "2024-09-01",
				status: "Completed",
			},
			{
				id: 2,
				amount: 200,
				date: "2024-09-02",
				status: "Pending",
			},
		])
	),

	fetchPayout: createApiCall(
		"GET",
		"Fetch Payout",
		`${baseUrl}/payouts/{id}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			amount: 100,
			date: "2024-09-01",
			status: "Completed",
		})
	),

	initiateRefund: createApiCall(
		"POST",
		"Initiate Refund",
		`${baseUrl}/refunds`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			transactionId: "abc123",
			amount: 50,
		}),
		formatJson({
			id: 1,
			transactionId: "abc123",
			amount: 50,
			status: "Refund Initiated",
		})
	),

	listRefunds: createApiCall(
		"GET",
		"List Refunds",
		`${baseUrl}/refunds`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				transactionId: "abc123",
				amount: 50,
				status: "Refund Initiated",
			},
			{
				id: 2,
				transactionId: "def456",
				amount: 75,
				status: "Refund Completed",
			},
		])
	),

	fetchRefundStatus: createApiCall(
		"GET",
		"Fetch Refund Status",
		`${baseUrl}/refunds/{id}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			transactionId: "abc123",
			amount: 50,
			status: "Refund Initiated",
		})
	),

	billsAirtimeProviders: createApiCall(
		"GET",
		"Bills Airtime Providers",
		`${baseUrl}/bills/airtime/providers`,
		[headers.authorization],
		"",
		formatJson([
			{ id: 1, name: "Provider A" },
			{ id: 2, name: "Provider B" },
		])
	),

	billsAirtimeTopup: createApiCall(
		"POST",
		"Bills Airtime Topup",
		`${baseUrl}/bills/airtime/topup`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			providerId: 1,
			phoneNumber: "+1234567890",
			amount: 100,
		}),
		formatJson({
			transactionId: "topup123",
			status: "Topup Successful",
		})
	),

	billsInternetProviders: createApiCall(
		"GET",
		"Bills Internet Providers",
		`${baseUrl}/bills/internet/providers`,
		[headers.authorization],
		"",
		formatJson([
			{ id: 1, name: "Provider A" },
			{ id: 2, name: "Provider B" },
		])
	),

	billsInternetTopup: createApiCall(
		"POST",
		"Bills Internet Topup",
		`${baseUrl}/bills/internet/topup`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			providerId: 1,
			accountNumber: "1234567890",
			amount: 200,
		}),
		formatJson({
			transactionId: "internet123",
			status: "Topup Successful",
		})
	),

	billsElectricityProviders: createApiCall(
		"GET",
		"Bills Electricity Providers",
		`${baseUrl}/bills/electricity/providers`,
		[headers.authorization],
		"",
		formatJson([
			{ id: 1, name: "Provider A" },
			{ id: 2, name: "Provider B" },
		])
	),

	billsElectricityTopup: createApiCall(
		"POST",
		"Bills Electricity Topup",
		`${baseUrl}/bills/electricity/topup`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			providerId: 1,
			accountNumber: "1234567890",
			amount: 300,
		}),
		formatJson({
			transactionId: "electricity123",
			status: "Topup Successful",
		})
	),

	billsElectricityValidate: createApiCall(
		"POST",
		"Bills Electricity Validate",
		`${baseUrl}/bills/electricity/validate`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			providerId: 1,
			accountNumber: "1234567890",
		}),
		formatJson({
			accountNumber: "1234567890",
			status: "Validation Successful",
		})
	),

	billsElectricityRecharge: createApiCall(
		"POST",
		"Bills Electricity Recharge",
		`${baseUrl}/bills/electricity/recharge`,
		[headers.authorization, headers.contentTypeJson],
		formatJson({
			providerId: 1,
			accountNumber: "1234567890",
			amount: 300,
		}),
		formatJson({
			transactionId: "electricityRecharge123",
			status: "Recharge Successful",
		})
	),

	billsHistory: createApiCall(
		"GET",
		"Bills History",
		`${baseUrl}/bills/history`,
		[headers.authorization],
		"",
		formatJson([
			{
				id: 1,
				type: "Electricity",
				amount: 300,
				date: "2024-09-01",
				status: "Completed",
			},
			{
				id: 2,
				type: "Internet",
				amount: 200,
				date: "2024-09-02",
				status: "Completed",
			},
		])
	),

	billsHistorySingleReference: createApiCall(
		"GET",
		"Bills History Single Reference",
		`${baseUrl}/bills/history/{reference}`,
		[headers.authorization],
		"",
		formatJson({
			id: 1,
			type: "Electricity",
			amount: 300,
			date: "2024-09-01",
			status: "Completed",
			transactionId: "electricity123",
		})
	),

	login: createApiCall(
		"POST",
		"Login",
		`${baseUrl}/login`,
		[headers.contentTypeJson],
		formatJson({
			username: "user@example.com",
			password: "password123",
		}),
		formatJson({
			token:
				"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVzZXIiLCJleHBpcmF0aW9uX2RhdGEiOiJleGFtcGxlIiwiaWF0IjoxNTY3ODkwMDAwfQ.S1VnhlTVhKnL-JAftU9oF7_nzys3wl9QyQ05RIr4J9M",
			refresh_token:
				"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVzZXIiLCJleHBpcmF0aW9uX2RhdGEiOiJleGFtcGxlIiwiaWF0IjoxNTY3ODkwMDAwfQ.S1VnhlTVhKnL-JAftU9oF7_nzys3wl9QyQ05RIr4J9M",
		})
	),
};
