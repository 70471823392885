import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

// Reusable Sidebar Link Component
export const SidebarLink = ({
	href,
	icon: Icon,
	label,
	location,
	subLinks,
	toggleMenu,
}) => {
	const [isSubMenuOpen, setSubMenuOpen] = useState(false);
	const [activeMethod, setActiveMethod] = useState("GET");

	const handleSubMenuClick = () => {
		setSubMenuOpen(!isSubMenuOpen);
	};

	const handleClick = (e, subLink) => {
		e.preventDefault();

		// Check if the current path matches the href
		if (location.pathname !== href) {
			localStorage.setItem("scrollTarget", subLink.id);
			window.location.href = href; // Navigate to parent path
		} else {
			localStorage.setItem("scrollTarget", subLink.id);
			window.location.href = href;
			if (typeof toggleMenu === "function") {
				toggleMenu(); // Close the menu if already on the right path
			} else {
				console.error("toggleMenu is not a function or not defined");
			}
		}
	};

	// Group subLinks by HTTP method
	const groupedLinks = subLinks?.reduce((acc, subLink) => {
		if (subLink.method) {
			if (!acc[subLink.method]) {
				acc[subLink.method] = [];
			}
			acc[subLink.method].push(subLink);
		} else {
			if (!acc["noMethod"]) {
				acc["noMethod"] = [];
			}
			acc["noMethod"].push(subLink);
		}
		return acc;
	}, {});

	return (
		<>
			<div
				onClick={(e) => {
					e.preventDefault(); // Prevent default navigation
					if (subLinks) {
						handleSubMenuClick(); // Toggle submenu
					} else {
						window.location.href = href; // Navigate if no submenu
					}
				}}
				className={`grid grid-cols-10 w-full justify-start items-center text-base gap-[1vw] py-xs px-[1vw] rounded-xl transition-all duration-300 cursor-pointer ${
					location.pathname === href
						? "bg-green-50 text-primary font-normal"
						: "text-gray-800 hover:bg-green-50 font-light"
				}`}
			>
				<span className="col-span-1">
					<Icon className="text-gray-500" />
				</span>
				<span className="col-span-7 md:col-span-8">{label}</span>

				{subLinks && (
					<span className="col-span-2 md:col-span-1">
						<MdKeyboardArrowDown className="text-1 mt-1 md:mt-[2px]" />
					</span>
				)}
			</div>

			{isSubMenuOpen && subLinks && (
				<div className="p-[1vw] md:p-[4vw] flex flex-col gap-[1vw] md:gap-[4vw] max-h-[60vh] overflow-scroll shadow-md rounded-xl w-full">
					{/* Render links without methods first */}
					{groupedLinks["noMethod"]?.map((subLink, index) => (
						<a
							key={index}
							href={subLink.id}
							className="block border-b group flex items-center justify-start gap-[1vw] font-light text-base md:text-sm py-[0.5vw] text-gray-800 hover:text-gray-800"
							onClick={(e) => handleClick(e, subLink)}
						>
							<subLink.icon className="aspect-square text-4 md:text-base border-2 lg:border-[1px] border-slate-700 group-hover:ring-2 ring-offset-2 ring-accent rounded-full" />
							<span className="group-hover:underline">{subLink.label}</span>
						</a>
					))}

					{/* Render navigation for methods */}
					{Object.keys(groupedLinks).length > 1 && (
						<div className="flex flex-wrap gap-2 mb-4">
							{Object.keys(groupedLinks)
								.filter((method) => method !== "noMethod")
								.map((method) => (
									<a
										key={method}
										onClick={() => setActiveMethod(method)}
										className={`px-4 py-2 rounded-lg text-xs font-medium cursor-pointer transition-colors duration-300 ease-in-out ${
											activeMethod === method
												? `border ${
														method === "GET"
															? "bg-green-100 text-green-700 border-green-700"
															: method === "POST"
															? "bg-yellow-50 text-yellow-800 border-yellow-600"
															: method === "PUT"
															? "bg-blue-100 text-blue-700 border-blue-500"
															: "bg-gray-200 text-gray-800 border-gray-300"
												  }`
												: `border ${
														method === "GET"
															? "bg-green-50 text-green-600 hover:bg-green-100 hover:text-green-700"
															: method === "POST"
															? "bg-yellow-50 text-yellow-700 hover:bg-yellow-100 hover:text-yellow-800"
															: method === "PUT"
															? "bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700"
															: "bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-800"
												  }`
										}`}
									>
										{method === "noMethod" ? "No Method" : method}
									</a>
								))}
						</div>
					)}

					{/* Render links based on active method */}
					{activeMethod && groupedLinks[activeMethod] && (
						<div>
							{groupedLinks[activeMethod].map((subLink, index) => (
								<a
									key={index}
									href={subLink.id}
									className={`block border-b group flex items-center justify-start gap-[1vw] font-light text-base md:text-sm py-[0.5vw] ${
										subLink.method === "GET"
											? "text-primary"
											: subLink.method === "POST"
											? "text-yellow-700"
											: subLink.method === "PUT"
											? "text-blue-500"
											: "text-gray-800"
									} hover:text-gray-800`}
									onClick={(e) => handleClick(e, subLink)}
								>
									{subLink.method ? (
										null
									) : (
										<subLink.icon className="aspect-square text-4 md:text-base border-2 lg:border-[1px] border-slate-700 group-hover:ring-2 ring-offset-2 ring-accent rounded-full" />
									)}
									<span className="group-hover:underline">{subLink.label}</span>
								</a>
							))}
						</div>
					)}
				</div>
			)}
		</>
	);
};
