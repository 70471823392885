import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import photo from "./partners.png";
import video from "./Partner.mp4";
import Button from "../Button";
import { SlArrowRightCircle } from "react-icons/sl";
import SVGGridPattern from "../Hero/SVGGridPattern";

const PartnerWithUs = ({ scrollToForm }) => {
	const history = useHistory();
	const [videoError, setVideoError] = useState(false);

	return (
		<div
			style={{
				background:
					"linear-gradient(to right, rgba(55,125,62,1) 0%, rgba(55,125,62,1) 10%, rgba(55,125,62,1) 50%)",
			}}
			className="w-full flex md:flex-col relative p-[4vw] md:p-[2vw] aspect-[1728/1000]"
		>
			<div
				style={{
					background:
						"linear-gradient(90deg, rgba(55,125,62,1) 0%, rgba(33,150,83,1) 78%, rgba(33,150,83,1) 100%,)",
				}}
				className="p-4 py-6 xs:px-4 xs:py-8 sm:px-4 sm:py-10 md:px-10 md:py-16 lg:px-12 lg:py-16 xl:px-14 xl:py-16 1xl:px-14 1xl:py-16 2xl:px-16 2xl:py-16 3xl:px-16 3xl:py-16 4xl:px-16 4xl:py-16 5xl:px-16 5xl:py-16 6xl:px-16 6xl:py-16"
			>
				<div
					className="relative z-20 
        w-[50%] 3xl:w-[80%] 2xl:w-[60%] md:w-full  font-sans
        my-auto flex flex-col md:pb-[0] md:w-full h-full 
        justify-center items-start md:items-center"
				>
					<h1
						className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
          text-[4vw] xl:text-[45px] 
          text-white leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[50px]
          tracking-[0.02em] lg:text-[35px]  text-left md:text-center 
          "
					>
						Partner With <br /> SaySwitch
					</h1>
					<p
						className="font-sans font-light
          mb-[40px]
          lg:mb-[20px] 
          max-w-6xl md:max-w-sm 
          text-left md:text-center text-white 
          text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
          3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
          tracking-wider 
          xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
          "
					>
						Partnering with us increases the value of your brand and expands
						your capacity altogether with our unique Switching and Transaction
						processing infrastructure. We are more than a traditional payment
						partner. We create signature experiences.
					</p>
					<div className="text-[#ffffff] md:mb-[40px] justify-start md:justify-center z-20">
						<div onClick={scrollToForm}>
							<Button
								onClick={scrollToForm}
								className="bg-accent font-light font-sans border-none text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px] 1xl:h-[88px] 2xl:h-[96px] rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
							>
								Become a Partner
								<SlArrowRightCircle className="text-black" />
							</Button>
						</div>
					</div>
				</div>
			</div>

			{/* Image with Overlay Gradient */}
			<div className="absolute top-0 right-0 h-full w-[55%] z-10 md:hidden">
				{!videoError ? (
					<video
						autoPlay
						muted
						loop
						className="h-full w-full object-cover opacity-100"
						style={{
							filter: "brightness(100%)",
						}}
						onError={() => setVideoError(true)}
					>
						<source src={video} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				) : (
					<img
						src={photo}
						alt="Partner with us"
						className="h-full w-full object-cover opacity-100"
					/>
				)}
				<div
					className="-left-20 z-10"
					style={{
						position: "absolute",
						top: 0,
						//left: 0,
						width: "100%",
						height: "100%",
						background:
							"linear-gradient(to right, rgba(55,125,62,1) 0%, rgba(55,125,62,1) 10%,  rgba(55,125,62,0.75) 25%, rgba(33,150,83,0) 50%)",
					}}
				/>
			</div>

			<div className="hidden md:block">
				<SVGGridPattern />
			</div>
		</div>
	);
};

export default PartnerWithUs;
