import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/NavBar/Navbar";
import Footer from "./components/Footer/Footer";
import AppRouter from "./routes/AppRouter";
import AOS from "aos";
import "aos/dist/aos.css";

import Overlay0ne from "./components/NavBar/Overlay0ne";
import OverlayTwo from "./components/NavBar/OverlayTwo";
import OverlayDev from "./components/NavBar/OverlayDev";

function App() {
	const [isLabel, setIsLabel] = useState("");

	const Location = useLocation();
	const locPath = Location.pathname;

	useEffect(() => {
		AOS.init({
			duration: 1000,
			easing: "ease-in-out",
		});
	}, []);

	return (
		<>
			<div className="relative">
				<Navbar isLabel={isLabel} setIsLabel={setIsLabel} locPath={locPath} />
				<AppRouter />
				<Footer locPath={locPath} />
				{/* Handle Overlay Logic */}
				{isLabel === "about" ? (
					<div
						onClick={() => setIsLabel("")}
						className="absolute top-[0px] lg:hidden left-0 backdrop-blur-xs w-full h-[100%] z-[1000]"
					>
						<Overlay0ne />
					</div>
				) : isLabel === "products" ? (
					<div
						onClick={() => setIsLabel("")}
						className="absolute top-[0px] lg:hidden left-0 backdrop-blur-xs w-full h-[100%] z-[1000]"
					>
						<OverlayTwo />
					</div>
				) : isLabel === "dev" ? (
					<div
						onClick={() => setIsLabel("")}
						className="absolute top-[0px] lg:hidden left-0 backdrop-blur-xs w-full h-[100%] z-[1000]"
					>
						<OverlayDev />
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}

export default App;
