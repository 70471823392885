import React from "react";

import POS from "../../components/Products/POS";

const POSView = () => {
  return (
      <div className="w-full scrollbar-hide relative">
        <POS />
      </div>
  );
};

export default POSView;
