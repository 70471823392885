import React from "react";
import { motion } from "framer-motion";

// Import images correctly
import token from "./images/token.png";
import atm from "./images/atm.png";
import cards from "./images/cards.png";
import solution from "./images/solution.png";
import cards_hover from "./images/card-hover.png";

const features = [
	{
		image: token,
		title: "Issuing Services",
		bgColor: "bg-[#E7BCED]",
		text: "text-black",
		description:
			"We remove the limits of innovation by supporting business growth in terms of customer experience, products, services, infrastructure, and strategic vision.",
		subdescription:
			"Our issuing services support any card type, token etc. You can launch your own issuing products through our solution.",
	},
	{
		image: atm,
		title: "ATM Acquiring Services",
		bgColor: "bg-[#377D3E]",
		text: "text-white",
		description:
			"Our ATM Solution enables Financial Institution to turn a network of cash dispensers into a secure, customer relationship tool. This allows banks and ATM deployers with the ability to offer more than a traditional self-service experience.",
		subdescription:
			"Provide your customers with low and no touch banking experiences through cardless transactions, issue virtual prepaid cards and more.",
	},
];

// Reusable FeatureCard component with framer-motion hover effect
const FeatureCard = ({
	image,
	title,
	description,
	subdescription,
	bgColor,
	text,
}) => (
	<motion.div
		className={`flex group relative flex-col items-start text-left gap-[4vw] lg:gap-[6vw] rounded-3xl ${bgColor} ${text}
			p-2xl py-4xl shadow-lg transition-all duration-300 overflow-clip`}
		whileHover={{
			scale: 1.05,
			transition: { duration: 0.3 },
		}}
	>
		<img
			src={image}
			alt={title}
			className="
		w-auto
		 aspect-rectangle absolute bottom-[1vw] -right-1"
		/>
		<div className="flex flex-col gap-[2vw] lg:gap-[3vw] tracking-wide">
			<h2
				className="text-1 font-normal leading-relaxed
			"
			>
				{title}
			</h2>
			<p className="font-light text-4 leading-loose mb-[1vw]">{description}</p>
			<p className="font-light text-4 leading-loose mb-[18vw] sm:mb-[40vw]">
				{subdescription}
			</p>
		</div>
	</motion.div>
);

const Solution = () => {
	return (
		<section className="px-[6vw] py-[10vw] bg-white text-black bg-white">
			<div className="text-left md:text-left mb-[4vw] lg:mb-[6vw]">
				<h1 className="h2 font-bold">SaySwitch Issuer solution</h1>
			</div>

			<div
				className="grid grid-cols-2 
            3xl:grid-cols-2
            1xl:grid-cols-2
            xl:grid-cols-2
            lg:grid-cols-2
            md:grid-cols-2
            sm:grid-cols-1
             gap-[4vw] 
			 mb-[4vw] lg:mb-[6vw]
			 "
			>
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						image={feature.image}
						title={feature.title}
						description={feature.description}
						subdescription={feature.subdescription}
						bgColor={feature.bgColor}
						text={feature.text}
					/>
				))}
			</div>
			<div
				className="grid grid-cols-2 
						3xl:grid-cols-2
						1xl:grid-cols-2
						xl:grid-cols-2
						lg:grid-cols-2
						md:grid-cols-2
						sm:grid-cols-1
						gap-[4vw]"
			>
				<motion.div
					className={`flex group relative flex-col items-start text-left gap-[4vw] lg:gap-[6vw] rounded-3xl bg-[#1C2029] text-white
			p-2xl py-4xl shadow-lg transition-all duration-300 overflow-clip`}
					whileHover={{
						scale: 1.05,
						transition: { duration: 0.3 },
					}}
				>
					<img
						src={cards}
						alt="cards"
						className="block group-hover:hidden
								w-auto md:w-[360px] sm:w-[280px]
								aspect-rectangle absolute -bottom-[18vw] -right-[4vw]"
					/>
					<img
						src={cards_hover}
						alt="cards"
						className="hidden group-hover:block
								w-auto md:w-[360px] sm:w-[280px]
								aspect-rectangle absolute  -bottom-[18vw] -right-[4vw]"
					/>
					<div className="flex flex-col gap-[2vw] lg:gap-[3vw] tracking-wide">
						<h2
							className="text-1 font-regular leading-relaxed
			"
						>
							Debit Card, Credit Card & Prepaid Card Solution
						</h2>

						<p className="font-light text-4 leading-loose mb-[18vw] sm:mb-[40vw]">
							We offer a centralized cost-effective, easily configurable
							solution for debit and credit processing to suit your needs and
							your customer demands. <br />
							<br />
							Our ecosystem supports full card-issuing product life cycle that
							enables you to launch products quickly. <br />
							<br />
							Our major card includes Traditional cards, Virtual cards for the
							major scheme.
						</p>
					</div>
				</motion.div>
				<div data-aos="zoom-in" className="relative group h-full rounded-3xl">
					<img
						src={solution}
						alt="solution"
						className="h-full w-full object-cover transition-all duration-300 group-hover:scale-105 rounded-3xl"
					/>
				</div>
			</div>
		</section>
	);
};

export default Solution;
