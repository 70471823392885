import React from "react";

import Collection from "../../components/Products/Collection";

const CollectionView = () => {
  return (
      <div className="w-full scrollbar-hide relative">
        <Collection />
      </div>
  );
};

export default CollectionView;
