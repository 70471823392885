import React from "react";

const PandCkYc = () => {
  return (
    <>
      You agree that, you are solely responsible for verifying the identities of
      your customers, ensuring that they are authorized to carry out the
      transactions on your platform, and determining their eligibility to
      purchase your products and services. You are also required to maintain
      information and proof of service or product delivery to your customer.
      Where a dispute occurs needing resolution, you may be required to provide
      Sayswitch with these.
    </>
  );
};

export default PandCkYc;
