import React from "react";

const PandCcustomerRights = () => {
  return (
    <>
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The right to request for your personal information.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The right to access your data held by us.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The right to restrict the processing of your personal information.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The right to rectify your personal information.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The right to erase personal information if there is no Legal or
          Regulatory requirement that this is held.
        </li>
      </ol>
    </>
  );
};

export default PandCcustomerRights;
