import React from "react";

const PandCpublicity = () => {
  return (
    <>
      You hereby grant Sayswitch permissions to use your name and logo in our
      marketing materials including, but not limited to use on our Portal, in
      customer listings, in interviews and in press releases. Such publicity
      does not imply an endorsement for your products and services.
    </>
  );
};

export default PandCpublicity;
