import React, { useEffect } from 'react';
import { FaCode, FaToolbox, FaPlay } from 'react-icons/fa'; // Importing icons
import { motion } from 'framer-motion'; // For animations


// Reusable Card component with icons and motion effects
const Card = ({ title, description, Icon }) => {
  return (
    <motion.div
     // Slight scale up on hover
      className="flex flex-col bg-white border hover:border-none hover:scale-105 p-6 gap-[2vw] shadow-lg rounded-2xl transition-all hover:shadow-2xl hover:bg-gradient-to-r from-yellow-400 to-green-500"
      data-aos="fade-up" // AOS animation
    >
      <div className="h5 text-green-600 mb-4">
        <Icon /> {/* Icon at the top */}
      </div>
      <h3 className="text-2 font-medium text-black">{title}</h3>
      <p className=" text-3">{description}</p>
    </motion.div>
  );
};

// Main FeaturesSection component
const FeaturesSection = () => {
  // Array of features with associated icons
  const features = [
    { title: "API Overview", description: "Get access to powerful APIs for payment processing, user management, and more.", icon: FaCode },
    { title: "Tools & SDKs", description: "Explore SDKs available in various programming languages to speed up integration.", icon: FaToolbox },
    { title: "Real-time Examples", description: "See real-time examples of API calls and integrations.", icon: FaPlay }
  ];

  return (
    <div className="flex flex-col gap-[2vw] py-2xl lg:p-8">
      <h2 className="text-1 font-bold text-left mb-8">Features & Highlights</h2>
      <div className="grid text-4 font-light grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-6">
        {features.map((feature, index) => (
          <Card 
            key={index} 
            title={feature.title} 
            description={feature.description} 
            Icon={feature.icon} 
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
