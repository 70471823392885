// Section.js
import React from 'react';

const Section = ({ id, refProp, Component }) => {
  return (
    <div id={id} ref={refProp}>
      <Component />
    </div>
  );
};

export default Section;
