import React, { useState } from "react";
import { FiSearch } from "react-icons/fi"; // Importing search icon from react-icons
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "../../Button";
import Logo from "../../../assets/images/sayswitchlogo.svg";
import { FiMenu, FiX } from "react-icons/fi";
import { SidebarLink } from "./SidebarLinks";
import { sectionConfig } from "../utils/sectionConfig";

const Navbar = () => {
	const history = useHistory();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
	const location = useLocation();

	return (
		<nav className="bg-[#F9F8F8] flex-1 relative text-black flex items-center justify-between pl-[0.5vw] md:pl-[2vw] pt-[1.5vw] pb-[1vw] pr-[3vw] min-h-[72px] md:min-h-[56px]">
			{/* Logo */}
			<div className="flex flex-row justify-center items-center hidden lg:flex ml-[4vw]">
				<div
					className={`flex items-center align-center`}
					onClick={() => history.push("/")}
				>
					<img
						src={Logo}
						alt=""
						className="aspect-[136/35] max-w-[150px] md:max-h-[32px]"
					/>
				</div>
			</div>

			{/* Search Bar */}
			<div className="relative w-1/3 block lg:hidden">
				<input
					type="text"
					placeholder="Search API, docs..."
					className="w-full p-[0.5vw] pl-[3vw] text-4 rounded-xl border border-gray-400 focus:ring-2 focus:ring-primary transition-all duration-300"
				/>
				<FiSearch className="absolute left-[1vw] top-[50%] transform -translate-y-1/2 text-black text-4" />
			</div>

			{/* Hamburger Icon - Visible on lg, md, sm, and xs */}
			<div className="lg:flex hidden items-center">
				<button
					onClick={toggleMenu}
					className="h3 text-primary bg-green-50 p-1 rounded-lg"
				>
					{isMenuOpen ? <FiX /> : <FiMenu />}{" "}
					{/* Toggle between menu and close icon */}
				</button>
			</div>

			{/* Sidebar / Dropdown Menu - Visible when toggled */}
			{isMenuOpen && (
				<div className="absolute flex flex-col top-full left-0 w-full bg-inherit shadow-lg z-50 p-[6vw] rounded-b-3xl gap-[4vw]">
					{/* Sidebar Links */}
					<nav className="flex flex-col gap-4">
						{sectionConfig.map((section, idx) => (
							<SidebarLink
								key={idx}
								href={section.href}
								icon={section.icon}
								label={section.label}
								location={location}
								subLinks={section.sections}
								toggleMenu={toggleMenu}
							/>
						))}
					</nav>
					{/* Search Bar */}
					<div className="relative w-full">
						<input
							type="text"
							placeholder="Search API, docs..."
							className="w-full p-[2vw] pl-[6vw] text-3 rounded-xl border border-gray-400 focus:ring-2 focus:ring-primary transition-all duration-300"
						/>
						<FiSearch className="absolute left-[2vw] top-[50%] transform -translate-y-1/2 text-black text-3" />
					</div>

					<div className="flex items-center w-full justify-between font-light">
						{/* Document Type Dropdown */}
						<div className="relative">
							<select
								className="bg-white border border-gray-300 rounded-xl text-black appearance-none text-sm xs:text-sm sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl
						w-[145px] sm:w-[120px] h-[48px] sm:h-[40px]"
								disabled
							>
								<option value="http">HTTP</option>
								<option value="javascript">JavaScript</option>
								<option value="python">Python</option>
								<option value="java">Java</option>
							</select>
						</div>

						{/* Get Started Button */}

						<button
							className="hover:bg-green-600 border border-primary rounded-xl hover:border-primary text-primary hover:text-white flex items-center justify-center
						text-sm xs:text-sm sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl
						bg-none w-[145px] sm:w-[100px] h-[48px] sm:h-[40px] font-light"
						>
							<Link to="/postman">Get Started </Link>
						</button>
					</div>
				</div>
			)}

			{/* Right-side Options */}
			<div className="flex items-center space-x-4 w-full justify-end lg:hidden font-light">
				{/* Document Type Dropdown */}
				<div className="relative">
					<select
						className="bg-white border border-gray-300 rounded-xl text-black appearance-none text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl
						w-[145px] h-[48px]"
						disabled
					>
						<option value="http">HTTP</option>
						<option value="javascript">JavaScript</option>
						<option value="python">Python</option>
						<option value="java">Java</option>
					</select>
				</div>

				{/* Get Started Button */}
				<Link to="/postman">
					<Button
						className="hover:bg-green-600 border-primary hover:border-primary text-primary hover:text-white flex items-center justify-center
						text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl
						bg-none w-[145px] h-[48px] font-light"
					>
						Get Started
					</Button>
				</Link>
			</div>
		</nav>
	);
};

export default Navbar;
