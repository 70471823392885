import React from "react";
import Button from "./Button";
import photo from "../assets/images/Agentlogo.png";
import logo from "../assets/images/googleplay.png";
import logo2 from "../assets/images/Subtract.png";
import store1 from "../assets/images/apple-vector.png";
import store2 from "../assets/images/appstore.png";
import photo2 from "../assets/images/makingbank.png";

const MakingBanking = () => {
  return (
    <div className="w-full mb-[96px] flex min-h-[615px]  lg:flex-col-reverse px-[152px] lg:px-[70px] md:px-[30px]">
      <div className="w-1/2 lg:w-full flex flex-col items-start ">
        <img src={photo} alt="" className="mt-[91px]" />
        <h1 className="font-Aeonik mt-[16px] text-[#1E2C24] text-left font-bold text-[48px] leading-[56px]  ">
          Making banking products more accessible
        </h1>
        <p className="font-Aeonik  pr-[90px] xl:pr-[0px] mt-[24px] text-[#3B443F] text-left text-[18px] leading-[26px] tracking-[0.002em]">
          Through this unique channel, banking products have become more
          accessible to customers where access to Banks/ATMs is difficult.
        </p>
        <div className="mt-[40px] flex md:flex-col md:items-start gap-[24px] ">
          <Button className="w-[190px] h-[54px] border-[#008037] ">
            <span className="flex gap-[13.78px] items-center  justify-center">
              <img src={logo2} alt="logo" />
              <img src={logo} alt="" />
            </span>
          </Button>
          <Button className="w-[190px] h-[54px]  border-[#008037]  ">
            <span className="flex gap-[13.78px] items-center justify-center">
              <img src={store1} alt="logo" />
              <img src={store2} alt="" />
            </span>
          </Button>
        </div>
      </div>
      <div className="w-1/2 lg:w-full flex justify-center mt-[26px]">
        <img src={photo2} alt="" width="387.78px" height="555px" />
      </div>
    </div>
  );
};

export default MakingBanking;
