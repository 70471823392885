import React from "react";

import Process from "../../components/Products/Process";

const ProcessView = () => {
  return (
      <div className="w-full scrollbar-hide relative">
        <Process />
      </div>
  );
};

export default ProcessView;
