import PandCcardNetworkRules from "./PandCcardNetworkRules";
import PandCaboutUs from "./PandCaboutUs";
import PandCacceptableUse from "./PandCacceptableUse";
import PandCaccountSecurity from "./PandCaccountSecurity";
import PandCageRestriction from "./PandCageRestriction";
import PandCagreement from "./PandCagreement";
import PandCapplicableLaw from "./PandCapplicableLaw";
import PandCchangeOfInfo from "./PandCchangeOfInfo";
import PandCchangesTo from "./PandCchangesTo";
import PandCconfidencialInfo from "./PandCconfidencialInfo";
import PandCcustomerRights from "./PandCcustomerRights";
import PandCdataCompliance from "./PandCdataCompliance";
import PandCdisclaimers from "./PandCdisclaimers";
import PandCdisputeAnd from "./PandCdisputeAnd";
import PandCexclusions from "./PandCexclusions";
import PandCinformationAuto from "./PandCinformationAuto";
import PandCintellectualProp from "./PandCintellectualProp";
import PandCintroduction from "./PandCintroduction";
import PandCISOPolicy from "./PandCISOPolicy";
import PandCkYc from "./PandCkYC";
import PandClegalDisputes from "./PandClegalDisputes";
import PandClimitationOfLiability from "./PandClimitationOfLiability";
import PandClinkingToThird from "./PandClinkingToThird";
import PandCookies from "./PandCookies";
import PandCprivacyPolicy from "./PandCprivacyPolicy";
import PandCpublicity from "./PandCpublicity";
import PandCregistration from "./PandCregistration";
import PandCrepresentationAndWty from "./PandCrepresentationAndWty";
import PandCreviewUpdates from "./PandCreviewUpdates";
import PandCsayswitchInt from "./PandCsayswitchInt";
import PandCsecurity from "./PandCsecurity";
import PandCsecurityOfData from "./PandCsecurityOfData";
import PandCseverability from "./PandCseverability";
import PandCsharingThisInfo from "./PandCsharingThisInfo";
import PandCsocialMedia from "./PandCsocialMedia";
import PandCsoftwareLicense from "./PandCsoftwareLicense";
import PandCtermsOfService from "./PandCtermsOfService";
import PandCtermsOfUse from "./PandCtermsOfUse";
import PandCtheInformation from "./PandCtheInformation";
import PandCtradeMarkLicense from "./PandCtradeMarkLicense";
import PandCwhatWeUse from "./PandCwhatWeUse";
import PandCcustomerPayments from "./PandCcustomerPayments";
import PandCoUrFeesAndPrices from "./PandCoUrFeesAndPrices";
import PandCTransactionHistory from "./PandCTransactionHistory";
import PandCPayoutSchedule from "./PandCPayoutSchedule";
import PandCHowWeHandleYourFunds from "./PandCHowWeHandleYourFunds";
import PandCSecurityAndFraud from "./PandCSecurityAndFraud";
import PandCNotificationOfErrors from "./PandCNotificationOfErrors";
import PandCChargebacks from "./PandCChargebacks";
import PandCReserves from "./PandCReserves";
import PandCRefunds from "./PandCRefunds";
import PandCtermination from "./PandCtermination";

import PandCIndemnity from "./PandCIndemnity";
import PandCMiscellaneous from "./PandCMiscellaneous";
import PandCRestrictedActivitiesAndPolicy from "./PandCRestrictedActivitiesAndPolicy";
import PandCRestrictedActivities from "./PandCRestrictedActivities";
import PandCcertainBusinessCategory from "./PandCcertainBusinessCategory";
import PandCActionsBySayswitch from "./PandCActionsBySayswitch";
import PandCRightToComplain from "./PandCRightToComplain";
import PandCUpdatesModificationAndAmends from "./PandCUpdatesModificationAndAmends";

export const PandCList = [
  { id: 1, title: "Introduction", content: <PandCintroduction /> },
  {
    id: 2,
    title: "Reviews, updates and amendments",
    content: <PandCreviewUpdates />,
  },
  { id: 3, title: "Age restriction", content: <PandCageRestriction /> },
  {
    id: 4,
    title: "The information we collect",
    content: <PandCtheInformation />,
  },
  {
    id: 5,
    title: "What we use the information for?",
    content: <PandCwhatWeUse />,
  },
  { id: 6, title: "Security of data", content: <PandCsecurityOfData /> },
  {
    id: 7,
    title: "Sharing this information",
    content: <PandCsharingThisInfo />,
  },
  { id: 8, title: "Changes to your details", content: <PandCchangesTo /> },
  { id: 9, title: "Customer rights", content: <PandCcustomerRights /> },
  {
    id: 10,
    title: "Information automatically collected from your computer",
    content: <PandCinformationAuto />,
  },
  { id: 11, title: "Cookies", content: <PandCookies /> },
  { id: 12, title: "Security", content: <PandCsecurity /> },
  {
    id: 13,
    title: "Linking to third parties websites and other aggregation websites",
    content: <PandClinkingToThird />,
  },
  { id: 14, title: "Social media sites", content: <PandCsocialMedia /> },
  { id: 15, title: "ISO policy", content: <PandCISOPolicy /> },
  {
    id: 16,
    title: "Sayswitch integrated management system objectives",
    content: <PandCsayswitchInt />,
  },
  { id: 17, title: "Terms of use", content: <PandCtermsOfUse /> },
  { id: 18, title: "About us", content: <PandCaboutUs /> },
  { id: 19, title: "Privacy policy", content: <PandCprivacyPolicy /> },
  { id: 20, title: "Disputes and reversal", content: <PandCdisputeAnd /> },
  { id: 21, title: "Acceptable use policy", content: <PandCacceptableUse /> },
  { id: 22, title: "Disclaimers", content: <PandCdisclaimers /> },
  {
    id: 23,
    title: "Limitation of liability",
    content: <PandClimitationOfLiability />,
  },
  { id: 24, title: "Exclusions", content: <PandCexclusions /> },
  { id: 25, title: "Applicable law", content: <PandCapplicableLaw /> },
  { id: 26, title: "Legal disputes", content: <PandClegalDisputes /> },
  { id: 27, title: "Severability", content: <PandCseverability /> },
  { id: 28, title: "Terms of service", content: <PandCtermsOfService /> },
  { id: 29, title: "Agreement", content: <PandCagreement /> },
  { id: 30, title: "Registration", content: <PandCregistration /> },
  { id: 31, title: "Change of information", content: <PandCchangeOfInfo /> },
  {
    id: 32,
    title: "Representation and warranties",
    content: <PandCrepresentationAndWty />,
  },
  { id: 33, title: "Account security", content: <PandCaccountSecurity /> },
  { id: 34, title: "Data compliance", content: <PandCdataCompliance /> },
  { id: 35, title: "Software license", content: <PandCsoftwareLicense /> },
  { id: 36, title: "Trademark license", content: <PandCtradeMarkLicense /> },
  {
    id: 37,
    title: "Intellectual property",
    content: <PandCintellectualProp />,
  },
  { id: 38, title: "Publicity", content: <PandCpublicity /> },
  {
    id: 39,
    title: "Confidential information",
    content: <PandCconfidencialInfo />,
  },
  { id: 40, title: "Know your customer", content: <PandCkYc /> },
  { id: 41, title: "Card network rules", content: <PandCcardNetworkRules /> },
  { id: 42, title: "Customer payments", content: <PandCcustomerPayments /> },
  {
    id: 43,
    title: "Our fees & pricing schedule",
    content: <PandCoUrFeesAndPrices />,
  },
  { id: 44, title: "Payouts", content: <PandCoUrFeesAndPrices /> },
  {
    id: 45,
    title: "Transaction history",
    content: <PandCTransactionHistory />,
  },
  { id: 46, title: "Payout schedule", content: <PandCPayoutSchedule /> },
  {
    id: 47,
    title: "How we handle your funds",
    content: <PandCHowWeHandleYourFunds />,
  },
  {
    id: 48,
    title: "Security and fraud controls",
    content: <PandCSecurityAndFraud />,
  },
  {
    id: 49,
    title: "Notification of errors",
    content: <PandCNotificationOfErrors />,
  },
  { id: 50, title: "Chargebacks", content: <PandCChargebacks /> },
  { id: 51, title: "Reserves", content: <PandCReserves /> },
  { id: 52, title: "Refunds", content: <PandCRefunds /> },
  { id: 53, title: "Termination", content: <PandCtermination /> },
  {
    id: 54,
    title: "Restricted activities & acceptable use policy",
    content: <PandCRestrictedActivitiesAndPolicy />,
  },
  { id: 55, title: "Indemnity", content: <PandCIndemnity /> },
  { id: 56, title: "Miscellaneous", content: <PandCMiscellaneous /> },
  {
    id: 57,
    title: "Restricted Activities",
    content: <PandCRestrictedActivities />,
  },
  {
    id: 58,
    title: "Certain business categories",
    content: <PandCcertainBusinessCategory />,
  },
  {
    id: 59,
    title: "Actions by Sayswitch",
    content: <PandCActionsBySayswitch />,
  },
  {
    id: 60,
    title: "Your right to complain",
    content: <PandCRightToComplain />,
  },
  {
    id: 61,
    title: "Updates, modification & amendments",
    content: <PandCUpdatesModificationAndAmends />,
  },
];
