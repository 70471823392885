import React, { useRef } from "react";
import PartnerWithUs from "./PartnerWithUs";
import PartnerWithUsBox from "./PartnerWithUsBox";
import TheSponsors from "./TheSponsors";
import WhyChooseUs from "./WhyChooseUs";
import WithPartnership from "./WithPartnership";
import ReadyToStart from "../Cta/ReadyToStart";

import { motion } from "framer-motion";
import Form from "./Form";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";

const Partners = () => {
	const formRef = useRef(null);

	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
    "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<PartnerWithUs scrollToForm={() => formRef.current.scrollIntoView({ behavior: "smooth" })}/>
			<TheSponsors />
			<WhyChooseUs />
			<WithPartnership />
			{/* <PartnerWithUsBox /> */}
			<Form ref={formRef}/>
			<FrequentlyAskedQuestion />
			<ReadyToStart />
		</motion.div>
	);
};

export default Partners;
