import React from "react";

const PandCprivacyPolicy = () => {
  return (
    <>
      Sayswitch is committed to managing your Personal Information in line with
      global industry best practices. You can read our Privacy Policy to
      understand how we use your information and the steps we take to protect
      your information.
    </>
  );
};

export default PandCprivacyPolicy;
