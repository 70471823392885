import React from "react";
import { motion } from "framer-motion";

// Import images correctly
import terminal from "./images/terminal.png";
import secure from "./images/secure.png";
import flexible from "./images/flexible.png";
import card from "./images/card.png";
import card_hover from "./images/card-hover.png";
import list from "./icons/list.png";
import { SlArrowRightCircle  } from "react-icons/sl";
import { Link } from "react-router-dom";

const features = [
	{
		image: terminal,
		hover: "hover:bg-[#2A99E3]",
		title: "Fully configured terminals",
		description:
			"SaySwitch POS application can be set-up and fully configured remotely which allows faster deployment to merchant locations. Get the free SaySwitch POS that works seamlessly with our range of hardware options.",
	},
	{
		image: flexible,
		hover: "hover:bg-[#E94F4F]",
		title: "Flexible terminals",
		description:
			"Our terminals are flexible, portable, and powerful solutions capable of doing business anywhere. It accepts chip & PIN, or contactless payments. Print receipts easily and quickly.",
	},
	{
		image: secure,
		hover: "hover:bg-[#00A861]",
		title: "Secure and Scalable",
		description:
			"Security standards using encryption to offer a safe and friction-free experience for your customers. Every device comes embedded with enhanced security keeping your cardholder data protected.",
	},
];

// Reusable FeatureCard component with framer-motion hover effect
const FeatureCard = ({ image, title, description, hover }) => (
	<motion.div
		className={`flex group flex-col items-start text-left gap-[4vw] lg:gap-[6vw] hover:border-none rounded-3xl 
			p-xl py-2xl bg-[#F8F8F9] ${hover} shadow-lg transition-all duration-300`}
		whileHover={{
			scale: 1.05,
			transition: { duration: 0.3 },
		}}
	>
		<img src={image} alt={title} className="h-24 lg:h-[56px] aspect-square" />
		<div className="flex flex-col gap-[2vw] lg:gap-[3vw]">
			<h2
				className="text-2 font-semibold
			 transition duration-[300ms] ease-in-out group-hover:text-white
			"
			>
				{title}
			</h2>
			<p className="text-gray-600 transition duration-[300ms] ease-in-out group-hover:text-white font-light text-3">
				{description}
			</p>
		</div>
	</motion.div>
);

const cards = [
	{
		description: "Multi EMV card support",
	},
	{
		description: "Remote software download",
	},
	{
		description: "Security (Anti-cloning of terminal ID)",
	},
	{
		description:
			"Unique status reporting support module that sends a report of;",
	},
];

const subcard = [
	{
		item: "Battery status",
	},
	{
		item: "Power connectivity",
	},
	{
		item: "Paper jam",
	},
	{
		item: "GPRS connectivity status and location",
	},
	{
		item: "Active / Inactive terminal status",
	},
];

const Features = () => {
	return (
		<section className="px-[6vw] py-[8vw] bg-white text-black">
			<div className="text-left mb-[4vw] lg:mb-[6vw]">
				<h1 className="h3 font-bold">
					Seamless Payment Experience <br /> for All Your Customers
				</h1>
			</div>

			<div
				className="grid grid-cols-3 
            3xl:grid-cols-3
            1xl:grid-cols-3
            xl:grid-cols-3
            lg:grid-cols-2
            md:grid-cols-2
            sm:grid-cols-1
             gap-[3vw]
			 mb-[8vw] lg:mb-[10vw]"
			>
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						image={feature.image}
						title={feature.title}
						description={feature.description}
						hover={feature.hover}
					/>
				))}
			</div>

			<section className="flex flex-row md:flex-col w-full gap-[4vw] h-full">
				{/* Left Side - Image */}
				<div className="flex group flex-col rounded-3xl w-[50%] lg:w-full justify-start lg:justify-center mb-0 lg:mb-10">
					<img
						src={card}
						alt="Developer"
						data-aos="zoom-in"
						className="w-full h-auto block group-hover:hidden hover:scale-105 transition ease object-cover opacity-100 rounded-3xl"
					/>
					<img
						src={card_hover}
						alt="Developer"
						data-aos="zoom-in"
						className="w-full h-auto hidden group-hover:block hover:scale-105 transition ease object-cover opacity-100 rounded-3xl"
					/>
				</div>
				{/* Right Side - Content */}
				<div
					data-aos="fade-left"
					className="w-[50%] lg:w-full flex flex-col justify-center items-stretch min-h-auto flex-grow"
				>
					<h1
						data-aos="fade-right"
						className="font-bold 
				h5
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[1vw] w-[80%] xl:w-full"
					>
						Fully integrated hardware
					</h1>
					<p
						data-aos="fade-right"
						className="font-light 
				text-3
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[1.5vw] w-full"
					>
						Sayswitch POS terminal application software is highly robust with
						extreme flexibility that allows for any customized type of
						transaction both traditional and digital that might be required by
						the merchant or acquirer. Some of its unique features include:
					</p>

					<div className="flex flex-col gap-[0.5vw]">
						{cards.map((card, index) => (
							<div
								key={index}
								className={`flex items-center justify-start p-[1vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-gray-100/10 w-full
              `}
								data-aos="flip-left"
								data-aos-delay={`${index * 100}`}
							>
								<img
									src={list}
									alt={card.description}
									className="aspect-square max-w-[24px] mr-[20px]"
								/>
								<div>
									<p className="text-black font-light text-3">
										{card.description}
									</p>
								</div>
							</div>
						))}
					</div>
					<div
						className="flex flex-col gap-[0.5vw] px-2xl
					hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-gray-100/10 w-auto pb-[1vw] mt-[1vw]
					"
					>
						{subcard.map((card, index) => (
							<div
								key={index}
								className={`flex items-center justify-start`}
								data-aos="flip-left"
								data-aos-delay={`${index * 100}`}
							>
								<div
									alt={card.item}
									className="aspect-square w-[8px] mr-[20px] bg-black/75 rounded-full"
								/>
								<div>
									<p className="text-black font-light text-3">{card.item}</p>
								</div>
							</div>
						))}
					</div>
					<div className="w-full flex flex-row items-center justify-start p-xs mt-sm">
						<Link to="/contactus">
							<button
								className="flex flex-row items-center justify-center gap-[1vw] bg-accent
						font-light hover:bg-green-hover px-2xl py-sm rounded-full text-3"
							>
								Contact Sales
								<SlArrowRightCircle  className="text-black" />
							</button>
						</Link>
					</div>
				</div>
			</section>
		</section>
	);
};

export default Features;
