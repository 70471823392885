import React from "react";

const PandCChargebacks = () => {
  return (
    <>
      A Chargeback usually happens when a customer files directly with or
      disputes through his or her credit or debit card issuer a payment on their
      bill. It may result in the reversal of a transaction. You may be assessed
      Chargebacks for (i) customer disputes; (ii) unauthorised or improperly
      authorised transactions; (iii) transactions that do not comply with Card
      Network Rules or theterms of this Agreement or are allegedly unlawful or
      suspicious; or (iv) any reversals for anyreason by the Card Network, our
      processor, or the acquiring or issuing banks. Where a Chargeback occurs,
      you are immediately liable for all claims, expenses, fines and liability
      we incur arising out of that Chargeback and agree that we may recover
      these amounts by debiting your Bank Account. Where these amounts are not
      recoverable through your Bank Account, you agree to pay all such amounts
      through any other means.
    </>
  );
};

export default PandCChargebacks;
