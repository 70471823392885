import React from "react";
import Partners from "../components/Partners/Partners";

function PartnersView() {
  return (
    <div className="w-full scrollbar-hide relative">
      <Partners />
    </div>
  );
}

export default PartnersView;
