import React, { useState } from "react";
import Logo from "../../assets/images/sayswitchlogo.svg";
import Keydown from "../../assets/icons/Keyboard arrow down.svg";

import menu from "./menu.svg";
import menu_close from "./close.svg";
import { FaAngleDown, FaAngleLeft } from "react-icons/fa";

import arrowright from "../../assets/icons/chevron-forward-outline (2).svg";
import arrowback from "../../assets/icons/chevron-back-outline (2).svg";
import { Link, useHistory } from "react-router-dom";
import Button from "../Button";
import SubMenuOne from "./SubMenuOne";
import SubMenuTwo from "./SubMenuTwo";
import SubMenuThree from "./SubMenuThree";
import { TfiAngleDown, TfiAngleLeft } from "react-icons/tfi";

const Navbar = ({ isLabel, setIsLabel, locPath }) => {
	const [navDisplay, setNavDisplay] = useState(false);
	const [mobileDisplay, setMobileDisplay] = useState("");
	const history = useHistory();

	const isLabelHandler = (identifier) => {
		if (isLabel === identifier) {
			setIsLabel("");
		} else {
			setIsLabel(identifier);
		}
	};

	const clearHandler = () => {
		setNavDisplay(false);
		setMobileDisplay("");
	};

	const menuItems = [
		{ label: "Home", path: "/", onClick: clearHandler },
		{
			label: "Products",
			path: "/products",
			subMenu: "Products",
			arrowIcon: arrowright,
		},
		{
			label: "About",
			path: "/about",
			subMenu: "About",
			arrowIcon: arrowright,
		},
		{ label: "Partners", path: "/partners", onClick: clearHandler },
		{ label: "Pricing", path: "/pricing", onClick: clearHandler },
		{
			label: "Developers",
			path: "/documents",
			subMenu: "Developers",
			arrowIcon: arrowright,
		},
		// { label: "Privacy", path: "/privacy" },
	];

	const ctaButtons = [
		{
			label: "Log in",
			path: "/login",
			className:
				"hover:text-[#008037] font-light border-transparent bg-none h-[48px] text-black text-md xs:text-md sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl",
		},
		{
			label: "Get Started",
			path: "/getstarted",
			className:
				"hover:bg-green-600 border-none text-[#FFFFFF] bg-[#008037] w-[145px] h-[48px] text-md xs:text-md sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl font-light",
		},
	];

	const navItems = [
		{ label: "Home", path: "/", onClick: clearHandler },
		{
			label: "Products",
			path: "/products",
			onClick: () => isLabelHandler("products"),
			arrowIcon: Keydown,
		},
		{
			label: "About",
			path: "/about",
			onClick: () => isLabelHandler("about"),
			arrowIcon: Keydown,
		},
		{ label: "Partners", path: "/partners", onClick: clearHandler },
		{ label: "Pricing", path: "/pricing", onClick: clearHandler },
		{
			label: "Dev",
			path: "/documents",
			onClick: () => isLabelHandler("dev"),
			arrowIcon: Keydown,
		},
	];

	return (
		<div className="scrollbar-hide mx-auto max-w-[1600px]">
			<div
				className={`w-full h-[94px] lg:h-[64px] z-[1000] flex justify-between items-center px-[2vw]
					${
						locPath === "/getstarted" ||
						locPath === "/login" ||
						locPath === "/api" ||
						locPath === "/documents"
							? "hidden"
							: ""
					}
				  `}
			>
				<div className={`flex w-full justify-start gap-[3vw]`}>
					{/* responsive logo display */}
					<div
						className={`flex items-center align-center lg:mb-[0px] mb-[10px] ${
							locPath === "/getstarted" ||
							locPath === "/login" ||
							locPath === "/api" ||
							locPath === "/documents"
								? "hidden"
								: ""
						}  `}
						onClick={() => history.push("/")}
					>
						<img
							className="select-none hidden md:block aspect-[130/30]"
							src={Logo}
							alt="logo"
							// width="24px"
							// height="24px"
						/>
						<img
							src={Logo}
							alt=""
							className="block md:hidden aspect-[136/35]"
						/>
					</div>

					{/* Large Screen MenuBar */}
					<MenuBar menuItems={navItems} locPath={locPath} />
				</div>

				{/* MenuButton */}
				<div
					className="hidden xl:block"
					onClick={() => setNavDisplay((prevDisplay) => !prevDisplay)}
				>
					<div className="rounded-xl bg-green-50 hover:bg-green-100 p-1">
						<img
							className="select-none fill-white w-[32px] h-[32px]"
							src={navDisplay ? menu_close : menu}
							alt="hamburger"
						/>
					</div>
				</div>

				{/* Cta Large Screen */}
				<div
					className={`flex flex-row 
						bg-hover:text-primary[#ffffff] xl:hidden `}
				>
					<Link to="/login">
						<Button
							className={` w-[145px] h-[48px]  font-light
								text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
								hover:text-[#008037] border-transparent`}
						>
							Log in
						</Button>
					</Link>
					<Link to="/getstarted">
						<Button
							className="hover:bg-green-600 border-primary hover:border-primary text-primary hover:text-white flex items-center justify-center
						text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl
						bg-none w-[145px] h-[48px] font-light"
						>
							Get Started
						</Button>
					</Link>
				</div>

				{/* Menu Dropdown */}
				{navDisplay && (
					<div
						className="bg-gray-600/30  
					backdrop-blur-xs
					w-full h-[100%] fixed left-0 top-[64px] z-[100] hidden xl:block"
					>
						<MenuComponent
							menuItems={menuItems}
							ctaButtons={ctaButtons}
							clearHandler={clearHandler}
							locPath={locPath}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Navbar;

const MenuComponent = ({ menuItems, ctaButtons, clearHandler, locPath }) => {
	const [mobileDisplay, setMobileDisplay] = useState("");
	const isLabel = mobileDisplay; // Example state logic

	return (
		<div className="mt-[20px] mx-auto grid text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl text-[#000000] text-center gap-2 w-[90%] bg-white rounded-lg font-light p-6 relative">
			{!mobileDisplay && (
				<>
					{menuItems.map((item, index) => (
						<div
							key={index}
							onClick={
								item.onClick || (() => setMobileDisplay(item.subMenu || ""))
							}
							className={`flex ${
								index > 0 ? "border-t-[1px] border-[#D0CDCD]" : ""
							} justify-between items-center select-none group p-4 hover:text-primary
							${locPath === item.path ? "text-[#008037] font-normal" : ""}
							`}
						>
							<li className="flex justify-between w-full items-center flex-row">
								<Link
									to={item.path}
									onClick={item.subMenu ? (e) => e.preventDefault() : undefined}
								>
									{item.label}
								</Link>
								{item.arrowIcon && (
									<span
										className={`bg-green-50 cursor-pointer group-hover:bg-green-100 p-2 rounded-full ${
											isLabel === item.subMenu && "transition-all rotate-180"
										}`}
									>
										<img
											src={item.arrowIcon}
											alt=""
											className="w-4 h-4 select-none rotate-90"
										/>
									</span>
								)}
							</li>
						</div>
					))}

					{/* CTA Buttons */}
					<div className="w-full flex border-t border-[#D0CDCD] flex-row justify-between p-4">
						{ctaButtons.map((btn, index) => (
							<Link key={index} to={btn.path}>
								<Button className={btn.className}>{btn.label}</Button>
							</Link>
						))}
					</div>
				</>
			)}

			{/* Mobile Display Submenu */}
			{mobileDisplay && (
				<div className="absolute max-h-[84vh] top-0 left-0 w-full min-h-[200px] z-200 bg-white overflow-y-scroll rounded-lg flex scrollbar-hide p-5">
					<div
						onClick={() => setMobileDisplay("")}
						className="flex flex-col text-left w-full"
					>
						<div className="flex flex-row w-full">
							<span className="mr-2 flex items-center">
								{/* <img src={arrowback} alt="" className="w-6 pt-1 h-6 text-black" /> */}
								<TfiAngleLeft
									size={24}
									className="p-1 text-[24px] text-primary rounded-full hover:bg-primary hover:text-white bg-green-50"
								/>
							</span>
							<h2 className="text-primary w-full text-2xl font-normal">
								{mobileDisplay}
							</h2>
						</div>
						<div className="px-6 sm:px-2">
							{mobileDisplay === "About" ? (
								<SubMenuTwo clearHandler={clearHandler} />
							) : mobileDisplay === "Products" ? (
								<SubMenuOne clearHandler={clearHandler} />
							) : (
								<SubMenuThree clearHandler={clearHandler} />
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const MenuBar = ({ menuItems, locPath }) => {
	return (
		<div className="flex items-center gap-[3vw] text-black xl:hidden">
			{menuItems.map((item, index) => (
				<Link
					key={index}
					to={item.path}
					onClick={
						["About", "Products", "Dev"].includes(item.label)
							? (e) => e.preventDefault()
							: undefined
					}
				>
					<li
						onClick={item.onClick || (() => {})}
						className={`flex flex-row items-center justify-center
				text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
				cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 
				before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600
				${locPath === item.path ? "text-[#008037] font-normal" : "font-light"}`}
					>
						{item.label}
						{item.arrowIcon && (
							<span className={`ml-[8px] mt-[3px]`}>
								{/* <img
									src={item.arrowIcon}
									alt=""
									className="aspect-square max-w-[32px]"
								/> */}
								<TfiAngleDown size={20} />
							</span>
						)}
					</li>
				</Link>
			))}
		</div>
	);
};
