import React from "react";
import ReadyToStart from "../Cta/ReadyToStart";
import { motion } from "framer-motion";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestion";
import Hero from "./Checkout/Hero";
import PaymentExperience from "./Checkout/PaymentExperience";
import Services from "./Checkout/Services";

const Checkout = () => {
	return (
		<motion.div
			className="scrollbar-hide overflow-hidden w-full flex flex-col justify-between
  "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<Hero />
			<PaymentExperience />
			<Services />
			<FrequentlyAskedQuestion />
			<ReadyToStart />
		</motion.div>
	);
};

export default Checkout;
