const apiData = [
	{
		title: "Verify Transaction",
		endpoint: "/transaction/verify/:reference",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: {
					transaction: {
						reference: "1234567890",
						status: "verified",
					},
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [],
		pathParams: [
			{
				name: "reference",
				type: "string",
				required: true,
				description:
					"The transaction reference used to initiate the transaction.",
			},
		],
	},
	{
		title: "Initialize Transaction",
		endpoint: "/transaction/initialize",
		method: "POST",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: JSON.stringify(
			{
				amount: 5000,
				currency: "NGN",
				reference: "your_unique_reference",
				callback_url: "https://yourwebsite.com/callback",
			},
			null,
			2
		),
		response: JSON.stringify(
			{
				status: "success",
				data: {
					reference: "your_unique_reference",
					payment_url: "https://paymentgateway.com/checkout",
				},
			},
			null,
			2
		),
		status: "201 Created",
		params: [
			{
				name: "amount",
				type: "number",
				required: true,
				description: "The amount of the transaction",
			},
			{
				name: "currency",
				type: "string",
				required: true,
				description: "Currency code",
			},
			{
				name: "reference",
				type: "string",
				required: true,
				description: "Unique transaction reference",
			},
			{
				name: "callback_url",
				type: "string",
				required: false,
				description: "URL for callback",
			},
		],
		pathParams: [],
	},
	{
		title: "Transactions Query",
		endpoint: "/transactions/query",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: [
					{ reference: "1234567890", status: "success", amount: 5000 },
					{ reference: "0987654321", status: "failed", amount: 3000 },
				],
			},
			null,
			2
		),
		status: "200 OK",
		params: [
			{
				name: "status",
				type: "string",
				required: false,
				description: "Filter by transaction status",
			},
			{
				name: "start_date",
				type: "string",
				required: true,
				description: "Start date for the query",
			},
			{
				name: "end_date",
				type: "string",
				required: true,
				description: "End date for the query",
			},
		],
		pathParams: [],
	},
	{
		title: "Fetch Transaction",
		endpoint: "/transaction/:reference",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: {
					reference: "1234567890",
					status: "success",
					amount: 5000,
					currency: "NGN",
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [],
		pathParams: [
			{
				name: "reference",
				type: "string",
				required: true,
				description: "The unique reference of the transaction to fetch.",
			},
		],
	},
	{
		title: "View Transaction Timeline",
		endpoint: "/transaction/timeline/:reference",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: [
					{ event: "initialized", timestamp: "2024-01-01T12:00:00Z" },
					{ event: "payment_received", timestamp: "2024-01-01T12:05:00Z" },
				],
			},
			null,
			2
		),
		status: "200 OK",
		params: [],
		pathParams: [
			{
				name: "reference",
				type: "string",
				required: true,
				description:
					"The unique reference of the transaction to view the timeline.",
			},
		],
	},
	{
		title: "Transaction Totals",
		endpoint: "/transaction/totals",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: {
					total_amount: 100000,
					currency: "NGN",
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [
			{
				name: "start_date",
				type: "string",
				required: true,
				description: "Start date for the totals query",
			},
			{
				name: "end_date",
				type: "string",
				required: true,
				description: "End date for the totals query",
			},
		],
		pathParams: [],
	},
	{
		title: "List Transactions",
		endpoint: "/transactions/list",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: [
					{ reference: "1234567890", status: "success", amount: 5000 },
					{ reference: "0987654321", status: "failed", amount: 3000 },
				],
			},
			null,
			2
		),
		status: "200 OK",
		params: [
			{
				name: "start_date",
				type: "string",
				required: true,
				description: "Start date for the list query",
			},
			{
				name: "end_date",
				type: "string",
				required: true,
				description: "End date for the list query",
			},
		],
		pathParams: [],
	},
	{
		title: "Account Name Verify",
		endpoint: "/account/verify",
		method: "POST",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: JSON.stringify(
			{
				bank_code: "044",
				account_number: "0123456789",
			},
			null,
			2
		),
		response: JSON.stringify(
			{
				status: "success",
				data: {
					account_name: "John Doe",
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [
			{
				name: "bank_code",
				type: "string",
				required: true,
				description: "Bank code",
			},
			{
				name: "account_number",
				type: "string",
				required: true,
				description: "Account number",
			},
		],
		pathParams: [],
	},
	{
		title: "Get Wallet Balance",
		endpoint: "/wallet/balance",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: {
					balance: 10000,
					currency: "NGN",
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [],
		pathParams: [],
	},
	{
		title: "Initiate Bulk Bank Transfer",
		endpoint: "/transfer/bulk",
		method: "POST",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: JSON.stringify(
			{
				transfers: [
					{ bank_code: "044", account_number: "0123456789", amount: 5000 },
					{ bank_code: "058", account_number: "0987654321", amount: 3000 },
				],
			},
			null,
			2
		),
		response: JSON.stringify(
			{
				status: "success",
				data: {
					batch_id: "bulk-1234567890",
					status: "pending",
				},
			},
			null,
			2
		),
		status: "201 Created",
		params: [
			{
				name: "transfers",
				type: "array",
				required: true,
				description: "Array of transfer objects",
			},
		],
		pathParams: [],
	},
	{
		title: "Bulk Transfer Status",
		endpoint: "/transfer/bulk/:batch_id",
		method: "GET",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: null,
		response: JSON.stringify(
			{
				status: "success",
				data: {
					batch_id: "bulk-1234567890",
					status: "completed",
					transfers: [
						{ account_number: "0123456789", amount: 5000, status: "success" },
						{ account_number: "0987654321", amount: 3000, status: "failed" },
					],
				},
			},
			null,
			2
		),
		status: "200 OK",
		params: [],
		pathParams: [
			{
				name: "batch_id",
				type: "string",
				required: true,
				description:
					"The ID of the bulk transfer batch to check the status of.",
			},
		],
	},
	{
		title: "Initiate Bank Transfer",
		endpoint: "/transfer/initiate",
		method: "POST",
		headers: [
			{ name: "Authorization", type: "string", required: true },
			{ name: "Content-Type", type: "string", required: true },
		],
		body: JSON.stringify(
			{
				bank_code: "044",
				account_number: "0123456789",
				amount: 5000,
				currency: "NGN",
				reference: "unique_reference_1234",
				narration: "Payment for services",
			},
			null,
			2
		),
		response: JSON.stringify(
			{
				status: "success",
				data: {
					transfer_id: "transfer-1234567890",
					status: "pending",
					amount: 5000,
					currency: "NGN",
					reference: "unique_reference_1234",
					narration: "Payment for services",
					initiated_at: "2024-09-16T12:34:56Z",
				},
			},
			null,
			2
		),
		status: "201 Created",
		params: [],
		pathParams: [],
	},
];

export default apiData;
