import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import photo from "./gateway.png";
import Button from "../../Button";
import { SlArrowRightCircle  } from "react-icons/sl";
import SVGGridPattern from "../../Hero/SVGGridPattern";

const TheBestWay = () => {
	const history = useHistory();

	return (
		<div
			style={{
				background:
					"linear-gradient(90deg, rgba(0,128,55,1) 0%, rgba(0,128,55,1) 5%,rgba(0,128,55,1) 45%, rgba(0,128,55,1) 55%, rgba(0,128,55,1) 78%, rgba(0,128,55,1) 100%)",
			}}
			className="w-full flex md:flex-col relative p-[4vw] md:p-[2vw] aspect-[1728/1000] overflow-clip"
		>
			<div
				style={{
					background:
						"linear-gradient(90deg, rgba(0,128,55,1) 0%, rgba(0,128,55,1) 78%, rgba(0,128,55,1) 100%,)",
				}}
				className="md:px-[4vw] md:py-[8vw]
				"
			>
				<div
					className="relative z-20 
		font-sans
        my-auto flex flex-col md:pb-[0] md:w-full h-full 
        justify-center items-start md:items-center overflow-scroll scrollbar-hide"
					data-aos="fade-up"
				>
					<p
						className="font-sans font-semibold
      
      max-w-6xl md:max-w-sm
      text-left md:text-center text-[#99BB92] 
      text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
      3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
      tracking-normal
      xs:leading-[20px] sm:leading-[24px] xl:leading-[30px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
      "
						data-aos="fade-right"
					>
						PAYMENT GATEWAY
					</p>
					<h1
						className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
          text-[3vw] xl:text-[40px] capitalize
          text-white leading-[50px] xl:leading-[50px] 1xl:leading-[50px] 2xl:leading-[70px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[40px]
          tracking-normal lg:text-[35px]  text-left md:text-center 
          "
						data-aos="fade-left"
					>
						The best way to <br /> collect money globally.
					</h1>
					<p
						className="font-sans font-light
          mb-[40px]
          lg:mb-[20px] 
          md:max-w-sm 
          text-left md:text-center text-white 
          xs:text-xs sm:text-sm md:text-sm lg:text-md xl:text-md 1xl:text-lg 2xl:text-xl 
          3xl:text-2xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl
          tracking-wider 
          xs:leading-[20px] sm:leading-[24px] xl:leading-[30px] 1xl:leading-[40px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[24px]
          "
						data-aos="fade-up"
					>
						Partnering with us increases the value of your brand and expands
						your capacity altogether with our unique Switching and Transaction
						processing infrastructure. We are more than a traditional payment
						partner. We create signature experiences.
					</p>
					<div className="text-[#ffffff] md:mb-[40px] justify-start md:justify-center z-20">
						<div
							onClick={() => {
								history.push("/contactus");
								window.scrollTo(0, 0);
							}}
							data-aos="zoom-in"
						>
							<Button
								className="bg-accent font-light font-sans border-none 
							text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
							w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
							h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[72px] xl:h-[88px] 1xl:h-[88px] 2xl:h-[96px]
							 rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
							>
								Contact Sales
								<SlArrowRightCircle  className="text-black" />
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div
				className="flex flex-row  items-center justify-center md:hidden aspect-[900/800] 
				max-w-[40vw] xl:max-w-[40vw]
			"
			></div>
			<div
				className="flex flex-row absolute
				-right-[8vw] -bottom-[7vw]
				 items-center justify-center md:hidden aspect-[868.84/777.46] 
				 3xl:w-[50vw]
				 max-w-[50vw]
			"
				data-aos="fade-left"
			>
				<img src={photo} alt="" className="object-fit w-full h-full" />
			</div>

			<div className="hidden md:block">
				<SVGGridPattern />
			</div>
		</div>
	);
};

export default TheBestWay;
