import React from "react";

const PandCtradeMarkLicense = () => {
  return (
    <>
      We hereby grant you a revocable, non-exclusive, non-transferable license
      to use Sayswitch’s trademarks used to identify our services (the
      “Trademarks”) solely in conjunction with the use of our services. You
      agree that you will not at any time during or after this Agreement assert
      or claim any interest in or do anything that may adversely affect the
      validity of any Trademark or any other trademark, trade name or product
      designation belonging to or licensed to Sayswitch (including, without
      limitation registering or attempting to register any Trademark or any such
      other trademark, trade name or product designation). Upon expiration or
      termination of this Agreement, you will immediately cease all display,
      advertising and use of all of the Trademarks.
    </>
  );
};

export default PandCtradeMarkLicense;
