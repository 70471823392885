import React from "react";

const PandCacceptableUse = () => {
  return (
    <>
      You are independently responsible for complying with all applicable laws
      related to your use of our website and services. However, by accessing or
      using Sayswitch, you agree to comply with the terms and conditions of our
      Acceptable Use Policy which you can read on our Acceptable Use Policy
      page.
      <span id="disclaimer"></span>
    </>
  );
};

export default PandCacceptableUse;
