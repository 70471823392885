import React, { useState } from "react";
import Button from "../Button";
import photo from "./images/careers.png";
import SVGGridPattern from "../Hero/SVGGridPattern";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SlArrowRightCircle  } from "react-icons/sl";
import { motion } from "framer-motion";

const Hero = ({ scrollToForm }) => {
	return (
		<motion.div
			style={{
				background:
					"linear-gradient(to right, rgba(55,125,62,1) 0%, rgba(55,125,62,1) 10%, rgba(55,125,62,1) 50%)",
			}}
			className="w-full flex md:flex-col relative p-[4vw] md:p-[2vw]
    			gap-[4vw]
    "
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<div
				style={{
					background:
						"linear-gradient(90deg, rgba(55,125,62,1) 0%, rgba(33,150,83,1) 78%, rgba(33,150,83,1) 100%,)",
				}}
				className="p-4 py-6 my-2[vw]  2xl:my-[4vw] xs:px-4 xs:py-8 sm:px-4 sm:py-10 md:px-10 md:py-16 lg:px-12 lg:py-16 xl:px-14 xl:py-16 1xl:px-14 1xl:py-16 2xl:px-16 2xl:py-16 3xl:px-16 3xl:py-16 4xl:px-16 4xl:py-16 5xl:px-16 5xl:py-16 6xl:px-16 6xl:py-16"
			>
				<div
					className="relative z-20 
      w-[50%] 3xl:w-[80%] 2xl:w-[60%] md:w-full  font-sans
      my-auto flex flex-col md:pb-[0] md:w-full h-full 
      justify-center items-start md:items-center overflow-scroll scrollbar-hide"
					data-aos="fade-up"
				>
					<h1
						className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
        text-[4vw] xl:text-[45px] capitalize
        text-white leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[50px]
        tracking-[0.02em] lg:text-[35px]  text-left md:text-center 
        "
					>
						Come Join our Team at SaySwitch
					</h1>
					<p
						className="font-sans font-light
        mb-[40px]
        lg:mb-[20px] 
        max-w-6xl md:max-w-sm 
        text-left md:text-center text-white 
        text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
        3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
        tracking-wider 
        xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
        "
					>
						We strive to provide our team and their loved ones with a
						world-class benefits experience, focused on supporting their
						physical, financial, and emotional wellbeing.
					</p>
					<div
						onClick={scrollToForm}
						className="text-black flex justify-center items-center z-20 mt-[30px]
          flex-row mb-10
        "
					>
						<Button
							onClick={scrollToForm}
							className="bg-accent border-none z-20 font-light
            text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
            w-[140px] xs:w-[148px] sm:w-[160px] md:w-[204px] lg:w-[204px] xl:w-[204px] 1xl:w-[228px] 2xl:w-[280px] 
            h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[72px] xl:h-[72px]  1xl:h-[80px] 2xl:h-[96px] 
            rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
            hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
						>
							View Openings
							<SlArrowRightCircle  className="text-black" />
						</Button>
					</div>
				</div>
			</div>

			{/* Image with Overlay Gradient */}
			<div className="absolute top-0 right-0 h-full w-[55%] z-10 md:hidden">
				<img
					src={photo}
					alt="Partner with us"
					className="h-full w-full group-hover:w-full object-cover opacity-100 absolute bottom-0"
				/>

				<div
					className="-left-20 z-10"
					style={{
						position: "absolute",
						top: 0,
						//left: 0,
						width: "100%",
						height: "100%",
						background:
							"linear-gradient(to right, rgba(55,125,62,1) 0%, rgba(55,125,62,1) 10%,  rgba(55,125,62,0.75) 25%, rgba(33,150,83,0) 50%)",
					}}
				/>
			</div>

			<div className="hidden md:block">
				<SVGGridPattern />
			</div>
		</motion.div>
	);
};

export default Hero;
