import React, { useState } from "react";
import {
	AiOutlineUser,
	AiOutlineMail,
	AiOutlinePhone,
	AiOutlineFileText,
	AiOutlineLoading3Quarters,
} from "react-icons/ai";

const ApplyModal = ({ isOpen, onClose, onSubmit, jobTitle }) => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		resume: "",
		coverLetter: "",
	});
	const [isLoading, setIsLoading] = useState(false); // Loading state

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// Handle form submission (real or mock API call)
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setIsLoading(true); // Start loading
			await onSubmit({ ...formData, jobTitle }); // Submit form data to parent component
		} catch (error) {
			console.error("Error submitting form:", error);
			alert("An error occurred. Please try again later.");
		} finally {
			setIsLoading(false); // Stop loading
			onClose(); // Close modal regardless of success or failure
		}
	};

	if (!isOpen) return null; // Don't render modal if it's not open

	return (
		<div className="fixed inset-0 bg-black overflow-scroll bg-opacity-50 flex justify-center items-center z-50">
			<div className="bg-white rounded-3xl shadow-xl p-8 m-6 w-full max-w-lg relative">
				<h2 className="text-2xl font-bold mb-4 text-center">
					Apply for {jobTitle}
				</h2>
				<form onSubmit={handleSubmit}>
					<div className="mb-4">
						<label className="block mb-2 text-md font-medium text-gray-700">
							Name
						</label>
						<div className="flex items-center border border-gray-300 rounded-xl p-2">
							<AiOutlineUser className="text-gray-600 mr-2" />
							<input
								type="text"
								name="name"
								placeholder="Your Name"
								value={formData.name}
								onChange={handleChange}
								required
								className="block w-full border-none bg-gray-100 rounded-xl"
							/>
						</div>
					</div>
					<div className="mb-4">
						<label className="block mb-2 text-md font-medium text-gray-700">
							Email
						</label>
						<div className="flex items-center border border-gray-300 rounded-xl p-2">
							<AiOutlineMail className="text-gray-600 mr-2" />
							<input
								type="email"
								name="email"
								placeholder="Your Email"
								value={formData.email}
								onChange={handleChange}
								required
								className="block w-full border-none bg-gray-100 rounded-xl"
							/>
						</div>
					</div>
					<div className="mb-4">
						<label className="block mb-2 text-md font-medium text-gray-700">
							Phone
						</label>
						<div className="flex items-center border border-gray-300 rounded-xl p-2">
							<AiOutlinePhone className="text-gray-600 mr-2" />
							<input
								type="tel"
								name="phone"
								placeholder="+1234567890"
								value={formData.phone}
								onChange={handleChange}
								required
								className="block w-full border-none bg-gray-100 rounded-xl"
							/>
						</div>
					</div>
					<div className="mb-4">
						<label className="block mb-2 text-md font-medium text-gray-700">
							Resume (URL)
						</label>
						<div className="flex items-center border border-gray-300 rounded-xl p-2">
							<AiOutlineFileText className="text-gray-600 mr-2" />
							<input
								type="url"
								name="resume"
								placeholder="Resume URL"
								value={formData.resume}
								onChange={handleChange}
								required
								className="block w-full border-none bg-gray-100 rounded-xl"
							/>
						</div>
					</div>
					<div className="mb-4">
						<label className="block mb-2 text-md font-medium text-gray-700">
							Cover Letter
						</label>
						<div className="border border-gray-300 rounded-xl p-2">
							<textarea
								name="coverLetter"
								placeholder="Your cover letter (optional)"
								value={formData.coverLetter}
								onChange={handleChange}
								className="block w-full border-none bg-gray-100 rounded-xl p-4"
							/>
						</div>
					</div>
					<div className="flex justify-between">
						<button
							type="button"
							onClick={onClose}
							className="bg-red-500 text-white p-4 hover:bg-rose-600 rounded-2xl ring-2 ring-offset-2 ring-red-500"
						>
							Close
						</button>
						<button
							type="submit"
							className="bg-green-600 text-white p-4 rounded-2xl hover:bg-green-hover hover:text-black hover:ring-green-hover flex items-center ring-2 ring-offset-2 ring-green-500"
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									<AiOutlineLoading3Quarters className="animate-spin mr-2" />{" "}
									Submitting...
								</>
							) : (
								"Submit Application"
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ApplyModal;
