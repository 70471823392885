//src/iew/HomeView.jsx

import React from "react";
import TheSponsors from "../components/Hero/TheSponsors";
import CardSection from "../components/features/CardSection";
import AcceptPayment from "../components/Services/AcceptPayment";
import PosTerminal from "../components/PosTerminal";
import MobileApp from "../components/MobileApp";
import Insights from "../components/Insights";
import ReadyToStart from "../components/Cta/ReadyToStart";
import Hero from "../components/Hero/Hero";
import SubHero from "../components/SubHero/SubHero";
import SecuritySection from "../components/Security/SecuritySection";

function HomeView() {
	return (
		<div className=" m-auto scrollbar-hide overflow-hidden xl:top-[96px] lg:top-[64px] left-[0] ">
			<Hero />
			<SubHero />
			<CardSection />
			<AcceptPayment />
			{/* <PosTerminal /> */}
			<SecuritySection />
			{/* <MobileApp /> */}
			{/* <Insights /> */}
			<ReadyToStart />
		</div>
	);
}

export default HomeView;
