import React from "react";

const PandCTransactionHistory = () => {
  return (
    <>
      Sayswitch shall be responsible for maintaining Transaction History.
      Equally, Sayswitch shall avail you the technical opportunity and resources
      to view POS and WEB transactions in real time, store and retrieve POS and
      WEB transaction history.
    </>
  );
};

export default PandCTransactionHistory;
