import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import paymentGatewayImg from "./paymentGatewayImg.png";
import checkoutImg from "./checkoutImg.png";
import pointOfSalesImg from "./pointOfSalesImg.png";
import switchProcessingImg from "./switchProcessingImg.png";
import collectionPlatformImg from "./collectionPlatformImg.png";
import mobileAppImg from "./mobileAppImg.svg";
import agentBankingImg from "./agentBankingImg.svg";
import rocket from "./rocket.png";
import { SlArrowRightCircle  } from "react-icons/sl";
import Button from "./Button";

const OverlayTwo = () => {
	const [isBouncing, setIsBouncing] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsBouncing(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div className="mx-auto mt-[94px] lg:mt-[64px]">
			<div
				className={`w-full bg-white shadow-lg sticky 
       ${isBouncing ? "bounce" : ""}
         top-[72px] lg:top-[64px] opacity-[100%] rounded-b-3xl`}
			>
				<div className="w-full grid grid-cols-4 gap-4 p-[1.6vw] pt-0">
					<div
						className="mx-auto gap-6 col-span-1 flex items-start justify-start flex-col text-center hover:scale-105 bg-green-100 
          rounded-b-3xl border-t-[16px] border-primary py-[1vw] pb-[1.8vw] px-[1.8vw] w-full
          transition ease-in-out delay-[100] "
					>
						<div className="flex flex-col gap-2 items-start justify-start">
							<h1
								className="font-normal 
              text-md xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
              "
							>
								Our Products
							</h1>
							<p
								className="text-left font-light
              text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
              "
							>
								We’re powering Businesses and Industries Forward with Innovative Solutions
							</p>
						</div>
						<Link to="/getstarted">
							<Button
								className="bg-primary border-none 
                  text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
                  w-[180px]
                  h-[64px] 
                  rounded-full
                   group text-white hover:text-black font-light
                  hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
							>
								Get Started
								<SlArrowRightCircle  className="text-white group-hover:text-black" />
							</Button>
						</Link>
						<img
							src={rocket}
							alt="rocket"
							className="aspect-square w-[148px] hover:scale-105"
						/>
					</div>

					{/* Right Columns */}
					<div className="flex flex-col col-span-3 w-full px-[1.8vw] py-[2vw] justify-start">
						<div className="grid grid-cols-3 gap-4">
							{submenuSections.map((section, sectionIndex) => (
								<div key={sectionIndex} className="col-span-1">
									<h1
										className="mb-4
                    text-md xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
                    font-normal"
									>
										{section.sectionTitle}
									</h1>
									{section.links.map((item, index) => (
										<Link
											to={item.path}
											data-aos="fade-right"
											data-aos-delay={item.aosDelay}
											key={index}
										>
											<li className="flex group gap-4 mt-8 flex-row items-center justify-start ease-in-out">
												<img
													src={item.imgSrc}
													alt={item.label}
													className="w-[56px] xs:w-10 xs:h-10 h-[56px] group-hover:scale-125 transition ease-in-out delay-[100] group-hover:border group-hover:border-primary rounded-full"
												/>
												<div
													className="flex flex-col group-hover:text-primary 
                        text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
                        "
												>
													<h1 className="font-normal 1xl:font-normal">
														{item.label}
													</h1>
													<p className="hidden 1xl:block font-light">{item.description}</p>
												</div>
											</li>
										</Link>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OverlayTwo;

const submenuSections = [
	{
		sectionTitle: "Online Solutions",
		links: [
			{
				path: "/products",
				imgSrc: paymentGatewayImg,
				label: "Payment Gateway",
				description: "Experience the finest of web acquiring",
				aosDelay: "0",
			},
			{
				path: "/checkout",
				imgSrc: checkoutImg,
				label: "Checkout",
				description: "Payment solution for online storefront",
				aosDelay: "100",
			},
			// {
			// 	path: "/mobileapp",
			// 	imgSrc: mobileAppImg,
			// 	label: "Mobile App",
			// 	description: "Access our services on the go",
			// 	aosDelay: "150",
			// },
		],
	},
	{
		sectionTitle: "Offline Solutions",
		links: [
			{
				path: "/pos",
				imgSrc: pointOfSalesImg,
				label: "Point of Sales",
				description: "Access payment with terminal and devices",
				aosDelay: "200",
			},
			{
				path: "/sayprocessing",
				imgSrc: switchProcessingImg,
				label: "Switch and Processing",
				description: "Supporting business growth",
				aosDelay: "300",
			},
			// {
			// 	path: "/agentbanking",
			// 	imgSrc: agentBankingImg,
			// 	label: "Agent Banking",
			// 	description: "Banking services through local agents",
			// 	aosDelay: "350",
			// },
		],
	},
	{
		sectionTitle: "Collection & Payouts",
		links: [
			{
				path: "/collection",
				imgSrc: collectionPlatformImg,
				label: "Collection Platform",
				description: "Offers web-based solution for businesses",
				aosDelay: "400",
			},
		],
	},
];
