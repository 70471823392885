import React from "react";

const PandCrepresentationAndWty = () => {
  return (
    <>
      You represent and warrant to ITEX that:
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          You have full power and authority to enter into, execute, deliver and
          perform this Agreement. more than the acceptable operational loss
          limit.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          You are duly organized, authorized and in good standing under the laws
          of the Federal Republic of Nigeria or any state, region or country of
          your organization and are duly authorized to do business in all other
          states, regions or countries in which your business operates.
        </li>
      </ol>
    </>
  );
};

export default PandCrepresentationAndWty;
