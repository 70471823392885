import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import about from "./about.png";
import career from "./career.png";
import contact from "./contact.png";
import idea from "./idea.png";
import { SlArrowRightCircle  } from "react-icons/sl";
import Button from "./Button";

function Overlay0ne() {
	const [isBouncing, setIsBouncing] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsBouncing(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);
	return (
		<div className="max-w-[1000px] mx-auto mt-[94px] lg:mt-[64px]">
			<div
				className={`w-full bg-white sticky shadow-lg
       ${isBouncing ? "bounce" : ""}
         top-[72px] lg:top-[64px] opacity-[100%] rounded-b-3xl`}
			>
				<div className="w-full grid grid-cols-2 gap-4 p-[1.6vw] pt-0">
					<div className="mx-auto gap-6 md:col-span-2 flex items-start justify-start flex-col text-center bg-green-100 hover:scale-105
          rounded-b-3xl border-t-[16px] border-primary py-[1vw] pb-[1.8vw] px-[1.8vw] w-full
          transition ease-in-out delay-[100] "
		  >
						<div className="flex flex-col gap-2 items-start justify-start">
							<h1 className="font-normal 
              text-md xs:text-md sm:text-lg md:text-xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
              ">
								Company
							</h1>
							<p className="text-left font-light
              text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
              ">
								We’re powering Businesses and Industries Forward with Innovative
								Solutions
							</p>
						</div>
						<Link to="/getstarted">
							<Button
								className="bg-primary border-none 
                  text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
                  w-[180px]
                  h-[64px] 
                  rounded-full font-light
                   group text-white hover:text-black
                  hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
							>
								Get Started
								<SlArrowRightCircle  className="text-white group-hover:text-black" />
							</Button>
						</Link>
						<img src={idea} alt="idea" className="aspect-square w-[148px] hover:scale-105" />
					</div>


					<div className="flex flex-col w-full px-[1.8vw] py-[2vw] justify-start">
						{submenuItems.map((item, index) => (
							<Link
								to={item.path}
								className="w-full"
								data-aos="fade-right"
								data-aos-delay={item.aosDelay}
								key={index}
							>
								<li className="flex w-full gap-4 group mt-4 flex-row items-center justify-start ease-in-out">
									<img
										src={item.imgSrc}
										alt={item.label}
										className="w-[56px] h-[56px] 
							group-hover:scale-125 transition ease-in-out delay-[100] group-hover:border group-hover:border-primary rounded-full"
									/>
									<div className="flex flex-col group-hover:text-primary
                  text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
                  ">
										<h1 className="font-normal">{item.label}</h1>
										<p className="font-light">{item.description}</p>
									</div>
								</li>
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Overlay0ne;

const submenuItems = [
	{
		path: "/about",
		imgSrc: about,
		label: "About us",
		description: "Overview of Our Core Value",
		aosDelay: "0", // No delay
	},
	{
		path: "/aboutcareers",
		imgSrc: career,
		label: "Career",
		description: "See our openings",
		aosDelay: "100",
	},
	{
		path: "/contactus",
		imgSrc: contact,
		label: "Support",
		description: "We are here to serve you better",
		aosDelay: "200",
	},
];
