import React from "react";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import { SlArrowRightCircle  } from "react-icons/sl";
import photo from "./shake.png";

const WithPartnership = () => {
	const history = useHistory();

	return (
		<div className="w-full flex md:flex-col relative p-[8.25vw]">
			<div className="w-full p-[6vw] bg-black rounded-3xl group relative">
				<div
					className="
          "
				>
					<div
						className="z-20 
        w-[50%] 3xl:w-[80%] 2xl:w-[60%] md:w-full  font-sans
        my-auto flex flex-col md:pb-[0] md:w-full h-full 
        justify-center items-start"
					>
						<h1
							className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
          text-[4vw] xl:text-[45px] 
          text-white leading-[50px] xl:leading-[60px] 1xl:leading-[70px] 2xl:leading-[80px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[50px]
          tracking-[0.02em] lg:text-[35px]  text-left 
          "
						>
							Our Partnership
						</h1>
						<p
							className="font-sans font-normal
          mb-[40px]
          lg:mb-[20px] 
          max-w-6xl md:max-w-sm 
          text-left text-white 3xl:max-w-[1000px]
          text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
          3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
          tracking-wider z-20
          xs:leading-[20px] sm:leading-[24px] xl:leading-[40px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
          "
						>
							With a partnership with SaySwitch, anything is possible. Our work
							ethics and business models are based on your growth plan because
							we believe that together we can make payment seamless.
						</p>
						<div className="text-[#ffffff] md:mb-[40px] justify-start md:justify-center z-20">
							<div
								onClick={() => {
									history.push("/contactus");
									window.scrollTo(0, 0);
								}}
							>
								<Button className="bg-accent font-normal font-sans border-none text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px] 1xl:h-[88px] 2xl:h-[96px] rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3">
									Contact Sales
									<SlArrowRightCircle  className="text-black" />
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="absolute bottom-[2vw] right-[2vw] z-10 hidden md:block group-hover:block " data-aos="fade-left">
					<img
						src={photo}
						alt="Partner with us"
						className="aspect-square max-w-[640px] 3xl:max-w-[600px] 2xl:max-w-[580px] 1xl:max-w-[480px] xl:max-w-[400px] lg:max-w-[300px] md:max-w-[224px] sm:max-w-[150px]"
					/>
				</div>
			</div>
		</div>
	);
};

export default WithPartnership;
