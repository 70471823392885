import React from "react";
import pay from "./images/pay.png";
import dev from "./images/dev.png";
import settle from "./images/settlement.png";
import list from "./icons/list.png";

const Features = () => {
	// Step 1: Define an array of card objects
	const pay_cards = [
		{
			description:
				"Disburse funds worldwide with our API or by uploading a CSV.",
		},
		{
			description:
				"Payouts to bank account in Nigeria and some banks in the world",
		},
		{
			description:
				"Payout to one or multitude of recipients across the countries and currencies",
		},
	];
	const dev_cards = [
		{
			description: "Robust API",
		},
		{
			description: "Built-in Compliance",
		},
		{
			description: "Global Reach",
		},
		{
			description: "Detailed Reporting",
		},
	];
	const payout_cards = [
		{
			description:
				"Mobile wallet provider seeking to enable wallet payouts and remittances",
		},
		{
			description:
				"Digital bank seeking to facilitate funds transfers or disbursements to your consumers",
		},
		{
			description:
				"Looking for a solution on a real-time claims’ payout, merchant settlement and payroll payment to employees among other use cases.",
		},
	];

	return (
		<main
			data-aos="fade-up"
			className="flex flex-col w-full px-[6vw] py-[10vw] bg-white gap-[4vw] h-full"
		>
			{/* Section 1 */}
			<section className="flex flex-row md:flex-col w-full gap-[4vw] h-full">
				{/* Left Side - Image */}
				<div className="flex flex-col w-1/2 lg:w-full justify-start lg:justify-center mb-0 lg:mb-10">
					<img
						src={pay}
						alt="Payment"
						data-aos="zoom-in"
						className="w-full h-auto hover:scale-105 transition ease object-cover shadow-md opacity-100 rounded-3xl"
					/>
				</div>
				{/* Right Side - Content */}
				<div
					data-aos="fade-left"
					className="w-1/2 lg:w-full flex flex-col justify-center items-stretch min-h-auto flex-grow"
				>
					<h1
						data-aos="fade-right"
						className="font-bold 
				h4
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[3vw] w-[80%] xl:w-full"
					>
						Pay contractors, suppliers, or any business anywhere in the world.
					</h1>

					{/* Step 2: Map the card array */}
					<div className="flex flex-col gap-[1vw]">
						{pay_cards.map((card, index) => (
							<div
								key={index}
								className={`flex items-center justify-start p-[1vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-green-100/10 w-[80%] xl:w-full
              `}
								data-aos="flip-left"
								data-aos-delay={`${index * 100}`}
							>
								<img
									src={list}
									alt={card.title}
									className="w-[24px] h-[24px] mr-[20px]"
								/>
								<div>
									<p className="text-black mt-[10px] font-light text-2">
										{card.description}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Section 2 */}
			<section
				className="flex flex-row md:flex-col w-full gap-[4vw] h-full relative 
			bg-gradient-to-r from-[#E8F0FC] via-[#E8F0FC] via-35% to-[#D1E1FB] to-92%
 overflow-clip p-[6vw] md:p-[8vw] rounded-3xl"
			>
				{/* Left Side - Image */}
				<div
					className="
					max-h-[480px]
					3xl:max-h-[480px]
					2xl:max-h-[400px]
					1xl:max-h-[320px]
					xl:max-h-[280px]
					lg:max-h-[240px]
					md:max-h-[231.37px]
					sm:max-h-[200px]
					aspect-[599/444] absolute -bottom-10 right-0
					flex flex-col w-auto justify-start lg:justify-center mb-0 lg:mb-10"
				>
					<img
						src={settle}
						alt="Settlement"
						data-aos="zoom-in"
						className="w-full h-auto hover:scale-105 transition ease object-cover shadow-md opacity-100 rounded-3xl"
					/>
				</div>
				{/* Right Side - Content */}
				<div
					data-aos="fade-left"
					className="w-full lg:w-full flex flex-col justify-center items-stretch min-h-auto flex-grow"
				>
					<h6 className="text-3 text-primary uppercase">Global Payout</h6>
					<h1
						data-aos="fade-right"
						className="font-bold 
				h4
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[1vw] w-[80%] xl:w-full"
					>
						Disbursement & Collections
					</h1>
					<p
						data-aos="fade-right"
						className="font-regular 
				text-2
				leading-leading-[72px] tracking-widest lg:leading-wider mb-[3vw] w-[50%] xl:w-full"
					>
						It is a web-based solution for payroll, marketplace sellers and
						other corporate payments. Disbursement is for you if you are:
					</p>

					{/* Step 2: Map the card array */}
					<div className="flex flex-col gap-[1vw] w-[80%] lg:w-full mb-[18vw] lg:mb-[25vw] md:mb-[50vw]">
						{payout_cards.map((card, index) => (
							<div
								key={index}
								className={`flex items-center justify-start p-[1vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-blue-100/10 w-[80%] xl:w-full
              `}
								data-aos="flip-left"
								data-aos-delay={`${index * 100}`}
							>
								<img
									src={list}
									alt={card.title}
									className="w-[24px] h-[24px] mr-[20px]"
								/>
								<div>
									<p className="text-black mt-[10px] font-light text-2">
										{card.description}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
			{/* Section 3 */}
			<section className="flex flex-row md:flex-col w-full gap-[4vw] h-full">
				{/* Left Side - Image */}
				<div className="flex flex-col bg-gray-700 rounded-3xl w-1/2 lg:w-full justify-start lg:justify-center mb-0 lg:mb-10">
					<img
						src={dev}
						alt="Developer"
						data-aos="zoom-in"
						className="w-full h-auto hover:scale-105 transition ease object-cover shadow-md opacity-100 rounded-3xl"
					/>
				</div>
				{/* Right Side - Content */}
				<div
					data-aos="fade-left"
					className="w-1/2 lg:w-full flex flex-col justify-center items-stretch min-h-auto flex-grow"
				>
					<h6 className="text-3 text-primary uppercase">
						Designed for developers
					</h6>
					<h1
						data-aos="fade-right"
						className="font-bold 
				h4
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[1vw] w-[80%] xl:w-full"
					>
						Sayswitch APIs power innovation
					</h1>
					<p
						data-aos="fade-right"
						className="font-regular 
				text-2
				leading-leading-[72px] tracking-wide lg:leading-tight mb-[3vw] w-[80%] xl:w-full"
					>
						Build and deploy globally scalable payment acceptance and
						disbursement from a single platform.
					</p>

					{/* Step 2: Map the card array */}
					<div className="flex flex-col gap-[1vw]">
						{dev_cards.map((card, index) => (
							<div
								key={index}
								className={`flex items-center justify-start p-[1vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-green-100/10 w-[80%] xl:w-full
              `}
								data-aos="flip-left"
								data-aos-delay={`${index * 100}`}
							>
								<img
									src={list}
									alt={card.title}
									className="w-[24px] h-[24px] mr-[20px]"
								/>
								<div>
									<p className="text-black font-light text-2">
										{card.description}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</main>
	);
};

export default Features;
