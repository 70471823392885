import React, { useEffect } from "react";
import BankingDoneBetter from "../components/BankingDoneBetter";
import DownloadSayswitch from "../components/DownloadSayswitch";
import EverythingYouNeed from "../components/EverythingYouNeed";
import FeaturesSection from "../components/FeaturesSection";
import HowToGetStarted from "../components/HowToGetStarted";

const MobileAppView = () => {
	useEffect(() => {
		if (!sessionStorage.getItem("reloaded")) {
			sessionStorage.setItem("reloaded", "true");
			window.location.reload();
		}
	}, []);
	return (
		<div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
			<EverythingYouNeed />
			<FeaturesSection />
			<HowToGetStarted />
			<BankingDoneBetter />
			<DownloadSayswitch />
		</div>
	);
};

export default MobileAppView;
