import React from "react";

const PandCageRestriction = () => {
  return (
    <>
      The Site is intended for persons eighteen (18) years or older. Persons
      under the age of eighteen (18) should not access, use and/or browse the We
      do not knowingly collect information from children under 18. If as a
      parent or guardian, you become aware that your child or ward child has
      provided us with any information without your consent, please contact us
      through the details provided in this Privacy Policy.
    </>
  );
};

export default PandCageRestriction;
