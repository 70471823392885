import React, { useState } from "react";
import { SlArrowRightCircle } from "react-icons/sl";
import SVGGridPattern from "../../Hero/SVGGridPattern";
import { Link } from "react-router-dom";

const HeroSection = () => {
    const [openDocs, setOpenDocs] = useState(false);



    return (
        <div className="relative p-8 bg-green-50 text-black flex flex-col w-full gap-[4vw] items-center justify-center rounded-2xl lg:rounded-none overflow-scroll">
            <div className="">
                <SVGGridPattern />
            </div>

            <div className="flex flex-col w-full text-center gap-[2vw] z-10">
                <h1 className="h5 font-medium">
                    Welcome to Our Developer Portal
                </h1>
                <p className="text-3 font-light">
                    Build with our APIs and power your app. Access detailed
                    documentation,
                    <br />
                    explore our API references, and use our SDKs to get started
                    quickly.
                </p>
            </div>

            <div className="flex flex-row w-full items-center justify-center z-10">
                <button
                    className="bg-green-hover flex items-center justify-center group font-light
            gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3 
            hover:bg-green-500 text-3 text-black hover:text-white px-10 lg:px-5 py-5 lg:py-3 rounded-full w-auto"
                >
                    <Link to="/postman">Get Started </Link>
                    <SlArrowRightCircle className="text-black group-hover:text-white" />
                </button>
            </div>

            {/* Animated Light Effect */}
            <div className="absolute inset-0 z-0 animate-pulse opacity-50">
                <div className="bg-gradient-to-r from-green-400 via-transparent to-blue-500 opacity-20 h-full"></div>
            </div>
        </div>
    );
};

export default HeroSection;
