import React from "react";

const Introduction = () => {
	// Constants for repeated items
	const links = [
		{
			label: "Account Creation",
			url: "https://merchant.sayswitchgroup.com/create-account",
		},
		{ label: "Login", url: "https://merchant.sayswitchgroup.com/login" },
		{ label: "Base Url", url: "https://merchant.sayswitchgroup.com" },
	];

	const accountCreationSteps = [
		"Create an account via the Account Creation link provided above.",
		"Receive an OTP via email and verify it.",
		"Provide the necessary information, including your password.",
		"Login through the link provided.",
		"Your account will be activated by an admin after verification.",
	];

	const notes = [
		"Signature authentication ensures the highest level of security for your payment creation requests. Not only does it provide a secure authentication mechanism, but it also ensures the integrity of your request payload. The request content must not have been altered since transmission from your side. SaySwitch signatures are calculated using HMAC-SH512, applied to the payload and signed with your public key.",
		"Make sure your payload is arranged alphabetically to ensure proper validation.",
	];

	return (
		<section className="p-8 text-black tracking-wide text-base font-light">
			<div className="mx-auto">
				<h1 className="text-2xl font-medium mb-6">SaySwitch API Integration</h1>
				<p className="text-gray-700 mb-4 text-md">
					To make API calls on SaySwitch, you'll need to pass a{" "}
					<span className="font-medium text-slate-600">Bearer Token</span>{" "}
					(your_secret_key).
				</p>

				<div className="bg-green-50 rounded-r-xl border-l-4 text-sm border-green-600 p-4 mb-6">
					<p className="text-gray-800 font-medium">
						<strong>BASE_URL:</strong>{" "}
						<span className="text-red-500">
							https://backendapi.staging-sayswitchgroup.com/api/v1
						</span>
					</p>
					<ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
						<li className="mb-2">
							Pass your secret key as the Bearer token in the Authorization
							Header.
						</li>
						<li>
							Pass your HMAC Signature as Encryption Header.{" "}
							<span className="text-gray-500">
								(Required for Payout and Bills payment)
							</span>
						</li>
					</ul>
				</div>

				{notes.map((note, index) => (
					<p className="text-gray-700 mb-4 leading-loose" key={index}>
						<span className="font-medium">NOTE:</span> {note}
					</p>
				))}

				<div className="bg-slate-50 border-l-4 border-green-600 rounded-r-xl bg-green-50 p-4 mb-6">
					<p className="text-gray-800 font-medium">
						<strong>AUTHORIZATION:</strong>{" "}
						<span className="text-slate-600">Bearer Token</span>
					</p>
					<p className="text-gray-800 mt-2">
						<strong>Token:</strong>{" "}
						<span className="text-gray-600">{`{{sk}}`}</span>
					</p>
				</div>

				<h2 className="text-lg font-medium text-slate-600 mb-4">
					Payment API
				</h2>
				<p className="text-gray-700 mb-4">
					Follow the steps below to integrate with the payment API:
				</p>

				<h3 className="text-md font-normal text-slate-600 mb-2">
					Steps To Use this API Collection:
				</h3>
				<ul className="list-decimal text-sm whitespace-pre-wrap w-full overflow-x-auto break-all border-l-4 border-green-600 list-inside p-2 bg-green-50 rounded-r-xl text-gray-700 mb-4">
					{links.map((link, index) => (
						<li className="mb-2" key={index}>
							{link.label}:{" "}
							<a href={link.url} className="text-red-600">
								{link.url}
							</a>
						</li>
					))}
				</ul>

				<h3 className="text-lg font-normal text-slate-600 mt-6 mb-2">
					Account Creation
				</h3>
				<ul className="list-decimal text-sm border-l-4 border-green-600 list-inside p-2 bg-green-50 rounded-r-xl text-gray-700 mb-4">
					{accountCreationSteps.map((step, index) => (
						<li className="mb-2" key={index}>
							{step}
						</li>
					))}
				</ul>

				<h3 className="text-lg font-normal text-slate-600 mt-6 mb-2">
					Authentication
				</h3>
				<p className="text-gray-700 mb-4 leading-loose">
					Testing all endpoints requires an authentication token. To generate
					the token, follow the steps above and retrieve your{" "}
					<span className="text-slate-600">Bearer Token</span>.
				</p>

				<div className="bg-green-50 border-l-4 border-green-600 rounded-r-xl p-4 mb-6">
					<p className="text-gray-800 font-medium">
						<strong>AUTHORIZATION:</strong>{" "}
						<span className="text-slate-600">Bearer Token</span>
					</p>
					<p className="text-gray-800 mt-2">
						<strong>Token:</strong>{" "}
						<span className="text-gray-600">{`{{sk}}`}</span>
					</p>
				</div>
			</div>
		</section>
	);
};

export default Introduction;
