import { useHistory } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SlArrowRightCircle  } from "react-icons/sl";
import Button from "../Button";
import image from "./Pattern.png";
import SVGGridPattern from "./SVGGridPattern";

const ReadyToStart = () => {
	const history = useHistory();

	return (
		<section
			className="w-full relative flex flex-col flex-1 justify-center items-center bg-primary 
        p-[10vw] pb-0 overflow-hidden
        "
		>
			<div
				className="absolute top-0 
        -left-40 xs:-left-40 sm:-left-40 md:-left-40 lg:-left-40 xl:-left-40 1xl:left-0 
        "
				style={{ transform: "rotate(360deg)" }}
        
			>
				<img src={image} alt="pattern" className="aspect-square" />
			</div>
			<div
				className="absolute top-0 sm:hidden xs:hidden
        -right-40 xs:-right-40 sm:-right-40 md:-right-40 lg:-right-40 xl:-right-40 1xl:right-0 
        "
				style={{ transform: "rotate(360deg)", transform: "scaleX(-1)" }}
			>
				<img src={image} alt="pattern" className="aspect-square" />
			</div>
			<motion.div
				className="flex z-20 relative bg-white w-full rounded-t-2xl p-[4.5vw] flex-col space-y-10 flex-1 justify-between text-center items-center"
				initial={{ opacity: 0, x: -100 }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.8 }}
			>
				<h1
					className="capitalize text-black font-[800] 
text-[2.1rem] xs:text-[2.1rem] sm:text-[2.45rem] md:text-[2.8rem] lg:text-[3.15rem] xl:text-[3.5rem] 1xl:text-[3.5rem] 2xl:text-[4.2rem]
tracking-[0.002em]
        "
				>
					Open your account in <br /> 10 minutes or less.
				</h1>
				<div className="text-black font-light text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl  leading-[26px] tracking-[0.002em]">
					Empower Your Business with Our Innovative Payment Platform, <br />{" "}
					Offering Fast, Secure, and Convenient Transactions.
				</div>
				<div
					className="text-black flex justify-center items-center z-10
          flex-col xs:flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 1xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row 5xl:flex-row 6xl:flex-row
        gap-2 xs:gap-4 sm:gap-6 md:gap-8 lg:gap-10 xl:gap-12 1xl:gap-12 2xl:gap-14"
				>
					<Link to="/getstarted">
						<Button
							className="bg-accent border-none font-light
            text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
            w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
            h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px]  1xl:h-[88px] 2xl:h-[96px] 
            rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
            hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
						>
							Create Account
							<SlArrowRightCircle  className="text-black" />
						</Button>
					</Link>

					<Link to="/pricing">
						<Button
							className="bg-none font-light
            text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
            w-[140px] xs:w-[152px] sm:w-[160px] md:w-[200px] lg:w-[220px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
            h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px] 1xl:h-[88px] 2xl:h-[96px] 
            rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
            border-black hover:border-primary text-black flex items-center justify-center 
            gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3 group hover:text-primary"
						>
							See Pricing
							<SlArrowRightCircle  className="text-black transition-colors duration-300 group-hover:text-primary" />
						</Button>
					</Link>
				</div>{" "}
				<SVGGridPattern />
			</motion.div>{" "}
		</section>
	);
};

export default ReadyToStart;
