import React from "react";
import ApiSection from "./ApiSection";

const ApiSectionContainer = ({
	method,
	title,
	description,
	endpoint,
	headers = [], // default to empty array
	body,
	response,
	status,
	params = [], // default to empty array
	pathParams = [], // default to empty array
}) => {
	return (
		<section className="p-8 text-black tracking-wide text-base font-light">
			<div className="mx-auto">
				<h2 className="text-1 font-medium mb-4">
					<span
						className={`mr-2 ${
							method === "GET"
								? "text-green-500"
								: method === "PUT"
								? "text-blue-500"
								: method === "POST"
								? "text-yellow-600"
								: "text-red-500"
						}`}
					>
						{method}
					</span>
					{title}
				</h2>
				<p className="font-light mb-2">{description}</p>
				<ApiSection
					title="Request"
					endpoint={endpoint}
					headers={headers}
					body={body}
					method={method}
					params={params}
					pathParams={pathParams}
				/>
				<ApiSection title="Response" response={response} status={status} />
			</div>
		</section>
	);
};

export default ApiSectionContainer;
