import React from "react";
import global from "./icons/global.png"; // Make sure to replace this with the correct icon
import list from "./icons/list.png"; // Make sure to replace this with the correct icon

const GlobalAcceptance = () => {
	// Step 1: Define an array of card objects
	const cards = [
		{
			title: "Expand into new markets",
			description: "We offer payment methods customers expect",
		},
		{
			title: "Simple payments",
			description: "Accept, process, and settle payments with one platform.",
		},
		{
			title: "Beautiful, intuitive dashboard",
			description: "Improved approval rate by issuers",
		},
		{
			title: "One integration",
			description:
				"Connect to global payment providers and acquirers with one integration",
		},
		{
			title: "Fully certified, standards compliant",
			description:
				"Payments within secured environment that align with industry standards - PCIDSS, ISO 20000, ISO 23001, ISO 27001",
		},
	];

	return (
		<div data-aos="fade-up" className="flex flex-row lg:flex-col w-full px-[70px] lg:px-[30px] py-[100px] lg:py-[50px] bg-white gap-[4vw]">
			{/* Left Side - Image */}
			<div className="flex flex-col w-2/5 lg:w-full flex justify-start lg:justify-center mb-0 lg:mb-10">
				<img
					src={global} // Replace with your actual image source
					alt="Global Acceptance"
          data-aos="zoom-in"
					className="w-full h-auto hover:scale-105 transition ease object-cover opacity-100 rounded-2xl"
				/>
			</div>

			{/* Right Side - Content */}
			<div data-aos="fade-left" className="w-3/5 lg:w-full h-full flex flex-col justify-start items-stretch">
				<h1 data-aos="fade-right" className="text-primary font-bold text-[60px] lg:text-[40px] sm:text-[32px] xs:text-[28px] leading-leading-[72px] tracking-wide lg:leading-tight mb-[30px]">
					Global Acceptance with all <br />
					<span className="text-black">
						popular payment methods at SaySwitch
					</span>
				</h1>

				{/* Step 2: Map the card array */}
				<div className="grid grid-cols-2 md:grid-cols-1 gap-[40px] lg:gap-[20px]">
					{cards.map((card, index) => (
						<div
							key={index}
							className={`flex items-start p-[2vw] group hover:scale-105 hover:shadow-lg
              transition-transform transform  ease-in-out duration-300
              rounded-xl bg-green-100/10
              ${
                index === 4 ? "col-span-2 md:col-span-1" : ""
              }
              `}
              data-aos="flip-left"
							data-aos-delay={`${index * 100}`} 
						>
							<img
								src={list}
								alt={card.title}
								className="w-[24px] h-[24px] mr-[20px]"
							/>
							<div>
								<h1 className="text-black group-hover:text-primary font-medium 
               text-[1vw] 1xl:text-[24px] xl:text-[24px] lg:text-[16px] md:text-[16px]
                ">
									{card.title}
								</h1>
								<p className="text-black mt-[10px] font-light
                xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 
										1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl 
                ">{card.description}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default GlobalAcceptance;
