import React from "react";
import { motion } from "framer-motion";
import Icon1 from "./images/security.png";
import Icon2 from "./images/agility.png";
import Icon3 from "./images/technology.png";
import Icon4 from "./images/excellence.png";
import vector from "../Partners/Vector.png";

const missions = [
	{
		number: "01 —",
		title: "We provide secured payment, peace of mind",
		description:
			"We protect your data, your business reputation, and bottom line with multi-layered fraud and cyber-crime security measures.",
		color: "#0DAB61",
		icon: Icon1,
	},
	{
		number: "02 —",
		title: "Enhancing Business Agility for Optimal Success",
		description:
			"We are on a mission to innovate. We are agile and unceasingly intense in our pursuit to make transactions seamless and convenient.",
		color: "#A19CE4",
		icon: Icon2,
	},
	{
		number: "03 —",
		title: "We are passionate about payment technology",
		description:
			"We build our infrastructure around innovation. We are connecting Nigeria and Africa to the global payment ecosystem.",
		color: "#15D4F0",
		icon: Icon3,
	},
	{
		number: "04 —",
		title: "We are committed to Excellence",
		description:
			"We take ownership and commit to becoming better when new perspectives arise, focusing on the bigger picture.",
		color: "#F9C795",
		icon: Icon4,
	},
];

const MissionCard = ({ number, title, description, color, icon }) => (
	<motion.div
		whileHover={{ scale: 1.05 }} // Framer Motion hover effect
		className="group flex flex-col p-[2vw] xl:p-[4vw] gap-[1vw] xl:gap-[2vw] md:gap-[6vw] sm:p-[6vw] bg-[#1B1F28] 
         transition-transform duration-300 ease-in-out transform-gpu hover:scale-105
        rounded-3xl shadow-lg aspect-[567/563] max-w-[567px] md:max-w-[342px] justify-between overflow-scroll scrollbar-hide"
		style={{
			backgroundImage: `url(${vector})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
		}}
		data-aos="fade-up" // AOS animation
	>
		<div className="flex flex-row w-full justify-between items-center h-full">
			<p
				data-aos="fade-right"
				className="text-left text-white font-light xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 
1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl 
text-ellipsis overflow-hidden"
				style={{ color: color }}
			>
				{number}
			</p>
			<motion.img
				src={icon}
				alt=""
				className="aspect-square max-w-[117px] xl:max-w-[80px] md:max-w-[65px] 
                         transition-transform duration-300 ease-in-out group-hover:scale-105"
			/>
		</div>
		<div className="flex flex-col gap-[1vw] xl:gap-[2vw] md:g-[4vw] h-full">
			<h1
				data-aos="fade-right"
				className="font-normal text-white text-left 
            6xl:text-[48px] 5xl:text-[40px] 4xl:text-[40px] 3xl:text-[38px] 2xl:text-[36px] 1xl:text-[32px] xl:text-[32px] lg:text-[28px] md:text-[28px] sm:text-[24px] xs:text-[24px]
            text-ellipsis overflow-hidden
            "
			>
				{title}
			</h1>
			<p
				data-aos="fade-right"
				className="text-left text-white font-extralight 
            6xl:text-[28px] 5xl:text-[26px] 4xl:text-[26px] 3xl:text-[24px] 2xl:text-[22px] 1xl:text-[22px] xl:text-[22px] lg:text-[20px] md:text-[20px] sm:text-[16px] xs:text-[14px]
            text-ellipsis overflow-hidden"
			>
				{description}
			</p>
		</div>
	</motion.div>
);

const MissionSection = () => {
	return (
		<section className="bg-black p-[6vw] md:p-[8vw] flex flex-col justify-center items-center">
			<header className="text-center mb-[6vw] xl:mb-[8vw] md:mb-[10vw] max-w-[1000px] w-full">
				<h1 className="text-white text-[20px] font-normal hidden md:block font-light">
					MISSION
				</h1>
				<p className="text-white 6xl:text-[72px] 5xl:text-[64px] 4xl:text-[56px] 3xl:text-[42px] 2xl:text-[42px] 1xl:text-[40px] xl:text-[40px] lg:text-[34px] md:text-[34px] sm:text-[30px] xs:text-[30px] tracking-wide font-normal mt-4">
					Accept and request payments from anyone, anywhere with ease
				</p>
			</header>
			<div className="grid grid-cols-2 lg:grid-cols-1 gap-12 justify-items-center h-full">
				{missions.map((mission, index) => (
					<MissionCard
						key={index}
						number={mission.number}
						title={mission.title}
						description={mission.description}
						color={mission.color}
						icon={mission.icon}
					/>
				))}
			</div>
		</section>
	);
};

export default MissionSection;
