import React from "react";
import phone from "../assets/images/Group cashdepo.png";
import apple from "../assets/icons/Icons.png";
import Button from "./Button";

const CashDeposits = () => {
  return (
    <div className="w-full flex max-h-[442px] xl:min-h-[442px]  lg:flex:col pl-[132px] xl:pl-[30px]  pr-[60px] xl:pr-[30px] xl:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
      <div className="w-[40%] lg:w-full py-[100px] xl:py-[56px] flex flex-col justify-start align-center">
        <h1 className="mb-[24px] text-[#ffffff] font-bold text-[36px] leading-[44px] tracking-[0.025em]">
          Cash Deposits / Withdrawals
        </h1>
        <p className="mb-[40px] text-[#BBC4BF] font-bold text-[18px] leading-[26px] tracking-[0.01em] ">
          Make cash deposits or withdrawal through our agents. Instant
          notification of all transactions facilitated through our agent
          networks
        </p>
        <Button className="bg-[#018038] h-[56px] min-w-[229px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
          Download App{" "}
          <span class>
            <img src={apple} alt="" className="w-[39px] ml-[20px]" />
          </span>
        </Button>
      </div>
      <div className="w-[60%] lg:w-full mt-[56px] flex justify-end md:hidden">
        <img src={phone} alt="" className="" width="511px" height="639.67px" />
      </div>
    </div>
  );
};

export default CashDeposits;
