import React from "react";
import Icon from "./icons/support.png";
import Icon2 from "./icons/cashflow.png";
import Icon3 from "./icons/improve.png";
import Icon4 from "./icons/software.png";

// Define the card data in an array
const cardData = [
	{
		img: Icon,
		title: "Full support",
		text: "Expect the best from us. Get a dedicated support team that will handle all your support needs, any time or day.",
	},
	{
		img: Icon2,
		title: "Track cashflow",
		text: "Stay on top of your working capital, taxes and cashflow to ensure your business is performing at it’s best.",
	},
	{
		img: Icon3,
		title: "Always improving",
		text: "We’re proud to ship improvements to our platform quickly and regularly. Check out our changelog.",
	},
	{
		img: Icon4,
		title: "Powerful software",
		text: "Included in our services, each customer gets in-house software to access live financial dashboards.",
	},
];

const OurGoal = () => {
	return (
		<div className="w-full flex flex-col px-[70px] lg:px-[30px] py-[100px] lg:py-[50px] bg-white">
			<div className="w-full grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-[24px]">
				{/* Header text column for large screens */}
				<div className="col-span-1 lg:col-span-2 md:col-span-1">
					{/* Empty div to take the space for large screens */}
					<p
						className="font-sans font-light
						max-w-6xl md:max-w-sm
						text-left text-black
						text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
						3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl
						tracking-normal
						xs:leading-[20px] sm:leading-[24px] xl:leading-[30px] 1xl:leading-[44px] 2xl:leading-[48px] 3xl:leading-[50px] md:leading-[30px] lg:leading-[30px]
						"
						data-aos="fade-right"
					>
						OUR GOAL IS SIMPLE
					</p>
					<h1
						className="font-bold mb-[1.6vw] lg:mb-[12px] md:mt-[24px]
						text-[3vw] xl:text-[40px] capitalize
						text-black leading-[50px] xl:leading-[50px] 1xl:leading-[50px] 2xl:leading-[70px] 3xl:leading-[120px] md:leading-[50px] lg:leading-[40px]
						tracking-normal lg:text-[35px]  text-left
						"
						data-aos="fade-left"
					>
						{" "}
						Everything you need to accept
						<br /> payment and control your finances
					</h1>
				</div>
				{/* Cards grid */}
				<div className="grid grid-cols-2 col-span-2 md:grid-cols-1 gap-[1.5vw] xl:gap-[2.5vw] md:gap-[8vw]">
					{cardData.map((card, index) => (
						<div
							key={index}
							className="h-auto group w-auto border rounded-3xl col-span-1 border-black transition-transform duration-300 hover:z-10 hover:transform hover:scale-[1.05] hover:shadow-lg hover:bg-black"
							style={{ transition: "flex-grow 0.5s ease-in-out" }}
						>
							<div className="aspect-square p-[2vw] lg:p-[4vw] md:p-[6vw] flex flex-col justify-center">
								<img
									src={card.img}
									alt=""

									className="mb-[72px] aspect-square 3xl:max-w-[100px] 1xl:max-w-[72px] xl:max-w-[60px] md:max-w-[56px]"
								/>
								<div>
									<h1
										data-aos="fade-right"
										className="font-sans font-semibold text-black text-left 
										text-[2vw] xl:text-[45px] lg:text-[35px] md:text-[25px]
										text-ellipsis overflow-hidden group-hover:text-white
       								     "
									>
										{card.title}
									</h1>
									<p
										data-aos="fade-right"
										className="font-sans text-left text-black group-hover:text-white font-light xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 
										1xl:text-xl 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl 
										tracking-wider leading-[50px]
										text-ellipsis overflow-hidden
										"
									>
										{card.text}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default OurGoal;
