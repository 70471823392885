import React from "react";
import PaymentGateway from "../../components/Products/PaymentGateway";

const ProductsView = () => {
  return (
    <div className="w-full scrollbar-hide relative">
      <PaymentGateway />
    </div>
  );
};

export default ProductsView;
