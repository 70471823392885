// src/routes/AppRouter.jsx
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./router";
import ScrollToTop from "./ScrollToTop";

function AppRouter(props) {
	return (
		<ScrollToTop>
			<Switch>
				{routes(props).map(
					({ path, component: Component, exact, props: routeProps }) => (
						<Route
							key={path}
							path={path}
							exact={exact}
							render={(routeComponentProps) => (
								<Component {...routeComponentProps} {...routeProps} />
							)}
						/>
					)
				)}
				<Redirect to="/" />
			</Switch>
		</ScrollToTop>
	);
}

export default AppRouter;
