import React from "react";
import HeroSection from "./HeroSection";
import HeroContent from "./HeroContent";

const SubHero = () => {
	return (
		<div className="w-full bg-[#F3F6F9] flex flex-col justify-between">
			<HeroSection>
				<HeroContent />
			</HeroSection>
		</div>
	);
};

export default SubHero;
