import React from "react";

const SVGGridPattern = () => {
	return (
		<div className="absolute inset-0 z-0 overflow-hidden">
			<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
				<defs>
					{/* Larger grid pattern with very subtle opacity */}
					<pattern
						id="grid"
						width="160"
						height="160"
						patternUnits="userSpaceOnUse"
					>
						{/* Background grid lines with very low opacity */}
						<rect width="160" height="160" fill="rgba(255, 255, 255, 0.02)" />
						<path
							d="M 160 0 L 0 0 0 160"
							fill="none"
							stroke="rgba(255, 255, 255, 0.1)"
							strokeWidth="1.25"
						/>

						{/* Randomly placed opaque squares with even lower opacity */}
						<rect width="80" height="80" fill="rgba(0, 0, 0, 0.02)" />
						<rect
							x="80"
							y="80"
							width="80"
							height="80"
							fill="rgba(0, 0, 0, 0.03)"
						/>
						<rect
							x="20"
							y="20"
							width="80"
							height="80"
							fill="rgba(0, 0, 0, 0.01)"
						/>
					</pattern>
				</defs>

				<rect width="100%" height="100%" fill="url(#grid)" />
			</svg>
		</div>
	);
};

export default SVGGridPattern;
