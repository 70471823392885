import React from "react";

const AboutSection = () => {
	const data = [
		{
			header: "Our Vision",
			description:
				"Our vision is to become the premier choice for businesses seeking seamless and efficient payment solutions in Nigeria and beyond.",
		},
		{
			header: "Our Mission",
			description:
				"Our mission is to provide innovative and reliable payment solutions that empower businesses to thrive in an ever-evolving digital landscape.",
		},
		{
			header: "Our Goal",
			description:
				"Our simple goal is to Revolutionize Your Business with Our Innovative Payment Platform, Offering Fast, Secure, and Convenient Transactions.",
		},
	];

    const headerTextSize = "6xl:text-[72px] 5xl:text-[64px] 4xl:text-[56px] 3xl:text-[42px] 2xl:text-[42px] 1xl:text-[40px] xl:text-[36px] lg:text-xl md:text-lg sm:text-md xs:text-md leading-loose tracking-wide";
    const decriptionTextSize = "6xl:text-[50px] 5xl:text-[44px] 4xl:text-[40px] 3xl:text-[36px] 2xl:text-[32px] 1xl:text-[32px] xl:text-[32px] lg:text-lg md:text-md sm:text-sm xs:text-sm leading-loose tracking-wide";

	return (
		<main className="flex flex-col p-[6vw] md:p-[8vw]">
			{data.map((item, index) => (
				<section
					key={index}
					className={`grid grid-cols-3 md:grid-cols-1 gap-[4vw] md:gap-[2vw] mb-[4vw]  pb-[4vw]
                        ${index === 2 ? 'border-none' :"border-b"
                        }
                        `}
				>
					<div className="flex justify-start items-start">
						<h1 className={`text-primary font-bold font-aeonik
                        ${headerTextSize} 
                        `}>
							{item.header}
						</h1>
					</div>
					<div className="col-span-2 md:col-span-1 flex justify-start items-start">
						<p className={` font-aeonik font-normal md:font-light
                        ${decriptionTextSize} 
                        `}
                        >{item.description}</p>
					</div>
				</section>
			))}
		</main>
	);
};

export default AboutSection;
