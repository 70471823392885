import React from "react";

const PandCchangeOfInfo = () => {
  return (
    <>
      In the event that you change any information provided to us at
      registration including your business name, address, financial institution,
      mode of payments or the products and services that you offer, or where a
      corporate restructuring occurs you agree to notify us within 14 days of
      such change. We may be unable to respond to you if you contact us from an
      address, telephone number or email account that is not registered with us.
    </>
  );
};

export default PandCchangeOfInfo;
