import React from "react";

const PandCSecurityAndFraud = () => {
  return (
    <>
      Sayswitch is responsible for protecting the security of Payment Data
      including CHD in our possession and will maintain commercially reasonable
      administrative, technical, and physical procedures to protect all the
      personal information regarding you and your customers that is stored in
      our servers from unauthorized access and accidental loss or modification.
      Although, we cannot guarantee that unauthorized third parties will never
      be able to defeat those measures or use such personal information for
      improper purposes. We will however take all reasonable and commercially
      achievable measures to address any security breach as soon as we become
      aware. You agree to use other procedures and controls provided by us and
      other measures that are appropriate for your business to reduce the risk
      of fraud. In the event that you suspect any fraudulent activity by a
      customer, you agree to notify Sayswitch immediately and quit the delivery
      of the service. In addition, where we suspect that there have been
      frequent fraudulent transactions on your account, we reserve the right to
      cancel our service to you and/or your account.
    </>
  );
};

export default PandCSecurityAndFraud;
