import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SlArrowRightCircle  } from "react-icons/sl";
import Button from "../Button";
import TheSponsors from "./TheSponsors";

const HeroContent = () => {
	return (
		<motion.div
			className="flex w-full flex-col
			space-y-10 xs:space-y-10 sm:space-y-12 md:space-y-12 lg:space-y-14 lg:space-y-16 xl:space-y-16 1xl:space-y-20
			2xl:space-y-20 3xl:space-y-20 4xl:space-y-20 5xl:space-y-20 6xl:space-y-20
			flex-1 justify-between text-center items-center"
			initial={{ opacity: 0, x: -100 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.8 }}
		>
			<h1
				className="capitalize text-white font-[800] 
        text-[28px] xs:text-[32px] sm:text-[36px] md:text-[50px] lg:text-[56px] xl:text-[60px] 1xl:text-[60px] 2xl:text-[72px] 
        mt-8 md:mt-12 lg:mt-12 xl:mt-20 1xl:mt-24 2xl:mt-20 leading-[1.5] 1xl:leading-[1.0] 2xl:leading-[1.0] 3xl:leading-[1.0]
		max-w-[1480px] xl:px-6 1xl:px-18 2xl:px-24"
			>
				A comprehensive, Feature-Rich Payment Solution for seamless offline and
				online transactions.
			</h1>

			<div
				className="text-white font-light leading-[2.0] 
        text-xs xs:text-base sm:text-base md:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-2xl 
        xs:flex sm:flex md:hidden lg:hidden xl:hidden 1xl:hidden 2xl:hidden 3xl:hidden"
			>
				The financial technology platform that helps you to accept, process, and
				settle payments online or offline anywhere.
			</div>

			<div
				className="text-white font-base leading-[2.0] xl:leading-[1.5] 1xl:leading-[1.5] 2xl:leading-[1.5] 3xl:leading-[1.5] 4xl:leading-[1.5] 5xl:leading-[1.5]
        text-xs xs:text-base sm:text-base md:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 2xl:text-4xl 1xl:max-w-[1000px] 2xl:max-w-[1200px]
        xs:hidden sm:hidden md:flex md:px-4 xl:px-20 1xl:px-30"
			>
				Unlock a World of Possibilities with Our Seamless and Reliable Payment
				Solutions, Designed to Empower Your Business Growth
			</div>

			<div
				className="text-white flex justify-center items-center flex-row
        gap-2 xs:gap-4 sm:gap-6 md:gap-8 lg:gap-10 xl:gap-12 1xl:gap-12 2xl:gap-14"
			>
				<Link to="/getstarted">
					<Button
						className="bg-accent border-none font-light
            text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
            w-[140px] xs:w-[152px] sm:w-[160px] md:w-[204px] lg:w-[228px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
            h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px]  1xl:h-[88px] 2xl:h-[96px] 
            rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
            hover:bg-green-hover text-black flex items-center justify-center gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3"
					>
						Create Account
						<SlArrowRightCircle  className="text-black" />
					</Button>
				</Link>

				<Link to="/pricing">
					<Button
						className="bg-none font-light
            text-xs xs:text-xs sm:text-sm md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl 
            w-[140px] xs:w-[152px] sm:w-[160px] md:w-[200px] lg:w-[220px] xl:w-[256px] 1xl:w-[256px] 2xl:w-[280px] 
            h-[48px] xs:h-[55px] sm:h-[60px] md:h-[72px] lg:h-[80px] xl:h-[88px] 1xl:h-[88px] 2xl:h-[96px] 
            rounded-[40px] xs:rounded-[48px] sm:rounded-[56px] md:rounded-[60px] lg:rounded-[70px] xl:rounded-[80px] 1xl:rounded-[80px] 2xl:rounded-[90px] 
            border-white hover:border-green-hover text-white flex items-center justify-center 
            gap-2 xs:gap-3 sm:gap-3 md:gap-3 lg:gap-3 xl:gap-3 1xl:gap-3 2xl:gap-3 group hover:text-green-hover"
					>
						See Pricing
						<SlArrowRightCircle  className="text-white transition-colors duration-300 group-hover:text-green-hover" />
					</Button>
				</Link>
			</div>

			<TheSponsors />
		</motion.div>
	);
};

export default HeroContent;
