import React from "react";

const PandCActionsBySayswitch = () => {
  return (
    <>
      If, in our sole discretion, we believe that you may have engaged in any
      violation of this Acceptable Use Policy, we may (with or without notice to
      you) take such actions as we deem appropriate to mitigate risk to
      Sayswitch and any impacted third parties and to ensure compliance with
      this Acceptable Use Policy. Such actions may include, without limitation:
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Blocking the settlement or completion of one or more payments.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Suspending, restricting or terminating your access to and use of the
          Sayswitch’s Services;
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Terminating our business relationship with you, including termination
          without liability to Sayswitch of any payment service agreement
          between you and Sayswitch.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Taking legal action against you.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Contacting and disclosing information related to such violations to
          (i) persons who have sold/purchased goods or services from you, (ii)
          any banks or Card Networks involved with your business or
          transactions, (iii) law enforcement or regulatory agencies, and (iv)
          other third parties that may have been impacted by such violations; or
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Assessing against you any fees, penalties, assessments or expenses
          (including reasonable attorneys’ fees) that we may incur as a result
          of such violations, which you agree to pay promptly upon notice
        </li>
      </ol>
    </>
  );
};

export default PandCActionsBySayswitch;
