import React from "react";

const PandCintellectualProp = () => {
  return (
    <>
      We do not grant any right or license to any Sayswitch intellectual
      property rights by implication, estoppel or otherwise other than those
      expressly mentioned in this Agreement. Each party shall retain all
      intellectual property rights including all ownership rights, title, and
      interest in and to its own products and services, subject only to the
      rights and licenses specifically granted herein.
    </>
  );
};

export default PandCintellectualProp;
