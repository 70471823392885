import React from "react";
import { motion } from "framer-motion";
import icon from "./icon.png";
import AOS from "aos";
import "aos/dist/aos.css";

// Initialize AOS
AOS.init();

const SecuritySection = () => {
	const securityData = [
		{
			title: "Financial Security",
			description:
				"We are PCI-DSS compliant. This is a global standard of security implemented by the most reliable financial institutions worldwide.",
		},
		{
			title: "Strict Business Verification",
			description:
				"We have strict business verification rules regulated by the CBN to ensure that all users and businesses are real and legitimate.",
		},
		{
			title: "24/7 Security Monitoring",
			description:
				"Our security and compliance team are constantly at alert to keep your money safe.",
		},
		// {
		// 	title: "Data Encryption",
		// 	description:
		// 		"All your data is encrypted using advanced encryption standards, ensuring that your personal and financial information is secure.",
		// },
	];

	return (
		<section className="w-full p-[6vw] md:p-[8vw]">
			{/* Security Content and Image Side by Side */}
			<div
				className="flex flex-col xs:flex-col sm:flex-col md:space-x-4 lg:flex-row xl:flex-row
            1xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row 4xl:flex-row 5xl:flex-row 6xl:flex-row
             justify-start items-center lg:space-x-4 mb-8 border-b-0 xs:border-b-0 sm:border-b-0 md:border-b
			 lg:border-b xl:border-b 1xl:border-b 2xl:border-b 3xl:border-b 4xl:border-b
			 pb-[2vw]
			 "
			>
				<motion.div
					className="w-full flex flex-col justify-center"
					initial={{ opacity: 0, x: -50 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 0.8 }}
				>
					<div className="mb-4 text-left space-y-4">
						<motion.button
							className="
							px-[16px] md:px-[10px] xs:px-[8px] py-[10px] md:py-[6px]
							border border-primary hover:border-green-hover rounded-3xl font-Aeonik font-bold text-primary hover:text-green-hover 
							6xl:text-[28px] 5xl:text-[28px] 4xl:text-[28px] 3xl:text-[24px] 2xl:text-[24px] 1xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[14px] xs:text-[12px]
							leading-[26px] tracking-[0.02em]"
							initial={{ opacity: 0, scale: 0.8 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.6 }}
						>
							SECURITY
						</motion.button>
						<motion.h2
							className="text-[40px] font-semibold mb-2"
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.8 }}
						>
							Our Security
						</motion.h2>
						<motion.p
							className="text-gray-600 
6xl:text-[28px] 5xl:text-[26px] 4xl:text-[26px] 3xl:text-[24px] 2xl:text-[22px] 1xl:text-[22px] xl:text-[22px] lg:text-[20px] md:text-[20px] sm:text-[16px] xs:text-[14px]						
							leading-relaxed tracking-[0.002em]"
							initial={{ opacity: 0, y: 10 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 1 }}
							data-aos="fade-up"
						>
							We've implemented strict measures to ensure your money and
							connection are fully protected. Our advanced security protocols
							and robust encryption techniques safeguard your financial data and
							personal information, giving you peace of mind. With continuous
							monitoring and regular updates, we provide the highest level of
							protection to keep your transactions and communications secure.
						</motion.p>
					</div>
				</motion.div>

				<motion.div
					className="flex justify-center
                items-center h-full w-full 
                lg:w-1/2 xl:w-1/2 1xl:w-1/2 2xl:w-1/2 3xl:w-1/2 4xl:w-1/2 5xl:w-1/2 6xl:w-1/2 
                mb-8 lg:mb-0"
					initial={{ opacity: 0, scale: 0.8 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.8 }}
				>
					<img
						src={icon}
						alt="Security"
						className="aspect-square max-w-[200px] xs:max-w-[200px] sm:max-w-[200px] md:max-w-[160px] 
                        lg:max-w-[200px] xl:max-w-[200px] 1xl:max-w-[200px] 2xl:max-w-[200px] 3xl:max-w-[200px] 
                        4xl:max-w-[200px] 5xl:max-w-[200px] 6xl:max-w-[200px]"
						data-aos="zoom-in"
					/>
				</motion.div>
			</div>

			{/* Map through the security data and render SecurityCard components */}
			<div className="grid grid-cols-1 gap-4 w-full xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3 4xl:grid-cols-3 5xl:grid-cols-3 6xl:grid-cols-3">
				{securityData.map((item, index) => (
					<motion.div
						key={index}
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.7, delay: index * 0.2 }}
					>
						<SecurityCard title={item.title} description={item.description} />
					</motion.div>
				))}
			</div>
		</section>
	);
};

export default SecuritySection;

const SecurityCard = ({ title, description }) => {
	return (
		<div className="py-4 space-y-2" data-aos="fade-up">
			<h3 className="font-normak 
			6xl:text-[28px] 5xl:text-[28px] 4xl:text-[28px] 3xl:text-[24px] 2xl:text-[24px] 1xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-lg sm:text-lg xs:text-lg
			">{title}</h3>
			<p className="text-gray-600 font-light
			6xl:text-[28px] 5xl:text-[28px] 4xl:text-[28px] 3xl:text-[24px] 2xl:text-[24px] 1xl:text-[20px] xl:text-[20px] lg:text-[18px] md:text-lg sm:text-lg xs:text-lg
			tracking-[0.002em]">
				{description}
			</p>
		</div>
	);
};
