import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Image1 from "./Card_1.png";
import Image2 from "./Card_2.png";
import Image3 from "./Card_3.png";
import Image4 from "./Card_4.png";
import Icon1 from "./icon_1.png";
import Icon2 from "./icon_2.png";
import Icon3 from "./icon_3.png";
import Icon4 from "./icon_4.png";
import Icon5 from "./icon_5.png";
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const AcceptPayment = () => {
	useEffect(() => {
		AOS.init({ duration: 1200, easing: "ease-in-out" });
	}, []);

	console.log(`Width: ${window.innerWidth}px, Height: ${window.innerHeight}px`);

	return (
		<div className="w-full bg-[#0E0D02] px-6 py-20 flex flex-col justify-center items-center gap-[120px] min-h-screen">
			<div
				className="w-full flex flex-col justify-center gap-[12px] items-center"
				data-aos="fade-up"
			>
				<p className="px-4 py-1 border border-accent-green hover:border-green-hover rounded-2xl font-Aeonik font-bold text-accent-green hover:text-green-hover text-[12px] leading-[26px] tracking-[0.02em]">
					SERVICES
				</p>
				<h1 className="font-Aeonik max-w-xl text-center font-semibold text-white text-[34px] leading-[56px] tracking-[0.025em]">
					Accept and request payments from anyone, anywhere with ease
				</h1>
			</div>

			{/* Cards Section */}
			<div className="relative mb-20 max-w-5xl z-10 w-full grid grid-cols-2 grid-flow-row justify-items-center lg:grid-cols-2 grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center">
				{/* First Image Card */}
				<div
					className="relative max-w-[530px] aspect-[530/670] overflow-hidden rounded-2xl group"
					data-aos="zoom-in"
				>
					<img
						src={Image1}
						alt="Card 1"
						className="w-full h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
					/>
				</div>
				{/* Second Card with Payment Gateway */}
				<div
					className="relative group max-w-[530px] aspect-[530/670] p-8 bg-[#9FE870] text-black transition-colors duration-300 hover:bg-[#9FE870]/95 shadow-lg rounded-2xl flex flex-col justify-between"
					data-aos="fade-up"
				>
					<div className="space-y-10">
						<h1 className="font-Aeonik text-[40px] lg:text-[24px] font-semibold">
							Payment <br /> Gateway
						</h1>
						<p className="mt-4 font-light text-[16px] lg:text-[18px] leading-[1.6]">
							Seamlessly integrate secure, fast, and reliable payment solutions
							for your business. Our Payment Gateway supports multiple payment
							methods, ensuring smooth transactions for both merchants and
							customers. Simplify your payment process with real-time tracking
							and advanced security features.
						</p>
					</div>
					<div className="flex items-center justify-between">
						<Link to="/products">
							<div className="flex items-center space-x-2 cursor-pointer  hover:text-white">
								<p className="font-Aeonik text-[16px]">Learn More</p>

								<HiOutlineArrowCircleRight className="w-6 h-6 font-light" />
							</div>{" "}
						</Link>
						{/* Hidden Icon on Hover */}
						<div className="hidden group-hover:flex transition-opacity duration-300">
							<img
								src={Icon1}
								alt="Icon"
								className="absolute bottom-0 right-0 h-48"
							/>
						</div>
					</div>
				</div>
				{/* Third Card with Checkout */}
				<div
					className="relative group border border-white/10 max-w-[540px] aspect-[540/675] p-8 bg-[#191C1F] text-white shadow-lg rounded-2xl flex flex-col justify-between"
					data-aos="fade-up"
				>
					<div className="space-y-10">
						<h1 className="font-Aeonik text-[40px] lg:text-[24px] font-semibold">
							Checkout
						</h1>
						<p className="mt-4 font-light text-[16px] lg:text-[18px] leading-[1.6]">
							Boost Your Business with Our Payment Solutions: Accept payments
							anywhere with features tailored to your needs, ensuring success
							for your merchants and enhancing our partnership.
						</p>
					</div>
					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-2 cursor-pointer hover:text-green-hover">
							<Link to="/checkout">
								{" "}
								<p className="font-Aeonik text-[14px]">Learn More</p>
							</Link>
							<HiOutlineArrowCircleRight className="w-6 h-6 font-light" />
						</div>
						{/* Hidden Icon on Hover */}
						<div className="hidden group-hover:block transition-opacity duration-300">
							<img
								src={Icon2}
								alt="Icon"
								className="absolute bottom-14 -right-5 h-40"
							/>
						</div>
					</div>
				</div>
				{/* Fourth Image Card */}
				<div
					className="relative max-w-[540px] aspect-[540/675] overflow-hidden rounded-2xl group"
					data-aos="zoom-in"
				>
					<img
						src={Image2}
						alt="Card 4"
						className="w-full h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
					/>
				</div>
				{/* 5th Card with POS */}
				<div
					className="relative overflow-clip col-span-1 xs:col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2 1xl:col-span-2 2xl:col-span-2 3xl:col-span-2 4xl:col-span-2 5xl:col-span-2 5xl:col-span-2 
					group border border-white/10 
					max-w-[530px] aspect-[530/1200]
					xs:max-w-[530px] xs:aspect-[530/1200]
					sm:max-w-[530px] sm:aspect-[530/1000]
					md:max-w-[540px] md:aspect-[530/680]
					lg:max-w-[1190px] md:aspect-[1190/800]
					xl:max-w-[1190px] xl:aspect-[1190/800]
					1xl:max-w-[1190px] 1xl:aspect-[1190/800]
					2xl:max-w-[1190px] 2xl:aspect-[1190/800]
					3xl:max-w-[1190px] 3xl:aspect-[1190/800]
					4xl:max-w-[1190px] 4xl:aspect-[1190/800]
					5xl:max-w-[1190px] 5xl:aspect-[1190/800]
					6xl:max-w-[1190px] 6xl:aspect-[1190/800]
					p-8 bg-[#B1CF5F] text-black shadow-lg rounded-2xl flex flex-col justify-between"
					data-aos="fade-up"
				>
					<div
						className="space-y-10 
					max-w-[100%] xs:max-w-[100%] sm:max-w-full md:max-w-[80%] lg:max-w-[65%] 
					xl:max-w-[65%]
					1xl:max-w-[65%]
					2xl:max-w-[65%]
					3xl:max-w-[65%]
					4xl:max-w-[65%]
					5xl:max-w-[65%]
					6xl:max-w-[65%]
					"
					>
						<h1 className="font-Aeonik text-[40px] lg:text-[24px] font-semibold">
							Point of Sales <span className="font-light">(POS)</span> <br />
							Terminals
						</h1>
						<div className="space-y-4">
							<p>
								SaySwitch POS application can be set-up and fully configured
								remotely which allows faster deployment to merchant locations.{" "}
							</p>
							<p>
								Our applications run on many types of terminals including
								Horizon K11, Morefun, Verifone etc, and operate over many
								communication types including GPRS, Wi-Fi, Dial-Up etc. This
								gives us an option to deploy the right type of terminal to your
								location
							</p>
						</div>
					</div>
					<div className="flex items-center justify-between">
						<Link to="/pos">
							<div className="flex z-20 items-center space-x-2 cursor-pointer hover:text-primary">
								<p className="font-Aeonik text-[14px]">Learn More</p>

								<HiOutlineArrowCircleRight className="w-6 h-6 font-light" />
							</div>
						</Link>
						{/* Hidden Icon on Hover */}
						<div className="hidden z-10 group-hover:block transition-opacity duration-300">
							<img
								src={Icon3}
								alt="Icon"
								className="absolute aspect-square 
								max-w-[450px] -bottom-[100px] -right-36
								xs:max-w-[450px] xs:-bottom-[100px] xs:-right-36
								sm:max-w-[450px] sm:-bottom-[100px] sm:-right-36
								md:max-w-[600px] md:-bottom-[160px] md:-right-40
								lg:max-w-xl lg:-bottom-40 lg:-right-48
								xl:max-w-xl xl:-bottom-40 xl:-right-48
								1xl:max-w-3xl 1xl:-bottom-60 1xl:-right-40
								2xl:max-w-3xl 2xl:-bottom-60 2xl:-right-40
								3xl:max-w-3xl 3xl:-bottom-60 3xl:-right-40
								4xl:max-w-3xl 4xl:-bottom-60 4xl:-right-40
								5xl:max-w-3xl 5xl:-bottom-60 5xl:-right-40
								6xl:max-w-3xl 6xl:-bottom-60 6xl:-right-40
								 "
							/>
						</div>
					</div>
				</div>

				{/* 7th Card with Switching and processing */}
				<div
					className="relative overflow-clip group max-w-[530px] aspect-[530/670] p-8 bg-[#191C1F] text-white transition-colors duration-300 hover:bg-[#191C1F]/95 shadow-lg rounded-2xl flex flex-col justify-between"
					data-aos="fade-up"
				>
					<div className="space-y-10">
						<h1 className="font-Aeonik text-[40px] lg:text-[24px] font-semibold">
							Switching and processing
						</h1>
						<p className="mt-4 font-light text-[16px] xs:text-[14px]  lg:text-[18px] leading-[1.6]">
							We offer a centralized cost-effective, easily configurable
							solution for debit and credit processing to suit your needs and
							your customer demands.
						</p>
					</div>
					<div className="flex items-center justify-between">
						<Link to="/sayprocessing">
							<div className="flex items-center space-x-2 cursor-pointer  hover:text-white">
								<p className="font-Aeonik text-[16px]">Learn More</p>

								<HiOutlineArrowCircleRight className="w-6 h-6 font-light" />
							</div>
						</Link>
						{/* Hidden Icon on Hover */}
						<div className="hidden group-hover:flex transition-opacity duration-300">
							<img
								src={Icon4}
								alt="Icon"
								className="absolute -bottom-20 -right-24 h-100"
							/>
						</div>
					</div>
				</div>
				{/* 6th Image Card */}
				<div
					className="relative max-w-[564px] aspect-[564/730] overflow-hidden rounded-2xl group"
					data-aos="zoom-in"
				>
					<img
						src={Image3}
						alt="Card 6"
						className="w-full h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
					/>
				</div>
				{/* 9th Image Card */}
				<div
					className="relative max-w-[563px] aspect-[563/730] overflow-hidden rounded-2xl group"
					data-aos="zoom-in"
				>
					<img
						src={Image4}
						alt="Card 9"
						className="w-full h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-125"
					/>
				</div>
				{/* 8th Card with Collection */}
				<div
					className="relative overflow-clip group max-w-[540px] aspect-[540/675] p-8 bg-[#E5F9C9] text-black shadow-lg rounded-2xl flex flex-col justify-between"
					data-aos="fade-up"
				>
					<div className="space-y-10">
						<h1 className="font-Aeonik text-[40px] lg:text-[24px] font-semibold">
							Collection Platform
						</h1>
						<p className="mt-4 font-light text-[16px] xs:text-[14px] lg:text-[18px] leading-[1.6]">
							SaySwitch Collect is a collection and disbursement, monitoring and
							reporting solution that helps organizations receive payments from
							their customers across various channels, including banks, POS,
							Payment Gateway and payout to their partners.
						</p>
					</div>
					<div className="flex items-center justify-between">
						<Link to="/collection">
							<div className="flex items-center space-x-2 cursor-pointer hover:text-green-hover">
								<p className="font-Aeonik text-[14px]">Learn More</p>
								<HiOutlineArrowCircleRight className="w-6 h-6 font-light" />
							</div>
						</Link>
						{/* Hidden Icon on Hover */}
						<div className="hidden group-hover:block transition-opacity duration-300">
							<img
								src={Icon5}
								alt="Icon"
								className="absolute bottom-0 right-0 h-40"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AcceptPayment;
