import React from "react";
import Button from "./Button";
import photo from "../assets/images/saswitch-mobile.png";
import logo from "../assets/images/googleplay.png";
import logo2 from "../assets/images/Subtract.png";
import store1 from "../assets/images/apple-vector.png";
import store2 from "../assets/images/appstore.png";
import photo2 from "../assets/images/Dashboard 3 REMOVE BG 1.png";

const EverythingYouNeed = () => {
  return (
    <div className="w-full flex min-h-[615px]  lg:flex-col-reverse px-[152px] lg:px-[70px] md:px-[30px]">
      <div className="w-1/2 lg:w-full pr-[90px] xl:pr-[0px] flex flex-col items-start ">
        <img src={photo} alt="" className="mt-[91px] lg:mt-[40px]" />
        <h1 className="font-Aeonik mt-[16px] text-[#1E2C24] text-left font-bold text-[48px] leading-[56px]  ">
          Everything you need to live a digital banking lifestyle
        </h1>
        <p className="font-Aeonik mt-[24px] text-[#3B443F] text-left text-[18px] leading-[26px] tracking-[0.002em]">
          Make or accept online payments, pay bills and do alot many more on the
          mobile app. Start living a digital lifestyle and enjoy instant
          transactions all in one place.
        </p>
        <div className="mt-[40px] flex md:flex-col md:items-start gap-[24px] lg:mb-[50px]">
          <Button className="w-[190px] h-[54px] border-[#008037] ">
            <span className="flex gap-[13.78px] items-center  justify-center">
              <img src={logo2} alt="logo" />
              <img src={logo} alt="" />
            </span>
          </Button>
          <Button className="w-[190px] h-[54px]  border-[#008037]  ">
            <span className="flex gap-[13.78px] items-center justify-center">
              <img src={store1} alt="logo" />
              <img src={store2} alt="" />
            </span>
          </Button>
        </div>
      </div>
      <div className="w-1/2 lg:w-full flex justify-end lg:justify-center mt-[26px]">
        <img src={photo2} alt="" width="458.16px" height="640px" />
      </div>
    </div>
  );
};

export default EverythingYouNeed;
