import React, { useEffect, useState, forwardRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { SlArrowRightCircle } from "react-icons/sl";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import { IoBriefcaseOutline } from "react-icons/io5";
import { PiClockCountdownDuotone } from "react-icons/pi";
import ApplyModal from "./ApplyModal"; // Import the modal component

// Define PositionAttributes component
const attributes = [
	{ icon: PiClockCountdownDuotone, key: "status" },
	{ icon: SlLocationPin, key: "location" },
	{ icon: IoBriefcaseOutline, key: "type" },
];

const PositionAttributes = ({ status, location, type }) => (
	<div className="flex flex-row gap-[4vw] font-regular text-2">
		{attributes.map(({ icon: Icon, key }) => (
			<div key={key} className="flex items-center gap-[1vw] text-primary">
				<Icon className="text-primary" />
				<span>
					{
						{
							status,
							location,
							type,
						}[key]
					}
				</span>
			</div>
		))}
	</div>
);

// Define PositionDetails component
const PositionDetails = ({ title, company }) => (
	<div className="flex flex-col gap-[2vw]">
		<div>
			<h2 className="h5 font-regular">{title}</h2>
			<p className="text-2 text-gray-500 font-light">{company}</p>
		</div>
	</div>
);

// Define HiringSection component
const HiringSection = forwardRef((props, ref) => {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	const [showAll, setShowAll] = useState(false);
	const [hiring, setHiring] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	// Open modal when "Apply now" is clicked
	const openModal = (jobTitle) => {
		setSelectedJob(jobTitle);
		setModalOpen(true);
	};

	// Close modal
	const closeModal = () => {
		setModalOpen(false);
		setSelectedJob(null);
	};

	// Simulate form submission
	const handleFormSubmit = async (formData) => {
		try {
			// Simulate API call delay
			await new Promise((resolve) => setTimeout(resolve, 1000));

			// Mock response (you can control this for success or failure)
			const mockResponse = { ok: true }; // Change to false to simulate failure

			if (mockResponse.ok) {
				alert("Application submitted successfully!");
			} else {
				throw new Error("Failed to submit application."); // Simulate failure
			}
		} catch (error) {
			console.error("Error submitting form:", error);
			alert(error.message);
		} finally {
			closeModal(); // Close the modal even if an error occurs
		}
	};

	const positions = [
		{
			title: "Senior Product Designer",
			company: "SaySwitch",
			status: "Ongoing",
			location: "Lagos",
			type: "Full-Time",
		},
		{
			title: "Head of Product",
			company: "SaySwitch",
			status: "Ongoing",
			location: "Lagos",
			type: "Full-Time",
		},
		{
			title: "UX/UI Designer",
			company: "Tech Innovators",
			status: "New",
			location: "Remote",
			type: "Part-Time",
		},
		{
			title: "Software Engineer",
			company: "FutureTech",
			status: "Ongoing",
			location: "Lagos",
			type: "Full-Time",
		},
		{
			title: "Marketing Manager",
			company: "Creative Agency",
			status: "New",
			location: "Abuja",
			type: "Contract",
		},
		{
			title: "Data Scientist",
			company: "DataWorks",
			status: "Ongoing",
			location: "Lagos",
			type: "Full-Time",
		},
		{
			title: "Project Manager",
			company: "BuildIt",
			status: "Upcoming",
			location: "Hybrid",
			type: "Full-Time",
		},
		{
			title: "Content Strategist",
			company: "ContentPlus",
			status: "New",
			location: "Remote",
			type: "Part-Time",
		},
	];

	const displayedPositions = showAll ? positions : positions.slice(0, 4);

	const headerTextSize =
		"6xl:text-[72px] 5xl:text-[64px] 4xl:text-[56px] 3xl:text-[42px] 2xl:text-[42px] 1xl:text-[40px] xl:text-[40px] lg:text-[34px] md:text-[34px] sm:text-[34px] xs:text-[34px] tracking-wide";

	return (
		<main
			ref={ref}
			className="p-[6vw] md:p-[8vw] pt-[18vw] md:pt-[18vw] relative"
		>
			<div
				data-aos="fade-down"
				className="absolute w-full z-0 right-0 top-0 aspect-[1839/638] bg-about md:bg-feature bg-center bg-cover"
			></div>
			{hiring ? (
				<section className="z-10 gap-[6vw] w-full">
					<div className="mb-20 md:mb-12 z-20">
						<h1 className={`${headerTextSize} font-bold text-left`}>
							<span className="text-gray-500">We’re Hiring! </span>
							<br />
							Open Positions
						</h1>
					</div>
					<div className="flex flex-col gap-[4vw] w-full">
						{displayedPositions.map((position, index) => (
							<div
								key={index}
								className="flex flex-row md:flex-col w-full justify-between gap-[4vw] border-b pb-[2vw]"
							>
								<div className="flex flex-col gap-[2vw]">
									<PositionDetails
										title={position.title}
										company={position.company}
									/>
									<PositionAttributes
										status={position.status}
										location={position.location}
										type={position.type}
									/>
								</div>
								<div className="flex items-end justify-start">
									<button
										onClick={() => openModal(position.title)}
										className="bg-accent font-light text-3 px-lg py-sm rounded-full hover:bg-green-hover text-black flex items-center justify-center gap-[1vw]"
									>
										Apply now
										<SlArrowRightCircle className="text-black transition-colors duration-300 hover:text-primary" />
									</button>
								</div>
							</div>
						))}
					</div>
					<div className="mt-20 md:mt-12">
						<button
							onClick={() => setShowAll(!showAll)}
							className="font-light border text-3 px-lg py-sm rounded-full hover:bg-green-hover text-black flex items-center justify-center gap-[1vw]"
						>
							{showAll ? "Show Less" : "View More"}
							{showAll ? (
								<FaChevronUp className="text-black transition-colors duration-300 hover:text-primary" />
							) : (
								<FaChevronDown className="text-black transition-colors duration-300 hover:text-primary" />
							)}
						</button>
					</div>
				</section>
			) : (
				<div className="mb-20 md:mb-12 z-20 flex flex-col items-center justify-center">
  <h1 className={`${headerTextSize} font-bold text-left`}>
    <span className="text-gray-500">No open positions</span> 😞
    <br />
    at this moment
  </h1>
</div>

			)}

			{/* Apply Modal */}
			<ApplyModal
				isOpen={modalOpen}
				onClose={closeModal}
				onSubmit={handleFormSubmit}
				jobTitle={selectedJob}
			/>
		</main>
	);
});

export default HiringSection;
