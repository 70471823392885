import React from "react";
import { motion } from "framer-motion";

// Import images correctly
import evoucherImage from "./images/evoucher.png";
import mobileDataImage from "./images/mobile-data.png";
import bulkDataImage from "./images/bulk-data.png";
import smsServiceImage from "./images/sms-service.png";
import utilityPaymentImage from "./images/utility-payment.png";
import rewardsImage from "./images/rewards.png";
import photo from "./images/features.png";

const features = [
	{
	  image: evoucherImage,
	  hover: "hover:bg-[#2A99E3]",
	  title: "E-Vouchers",
	  description:
		"We supply a broad range of e-vouchers for the world's leading brands, supporting your business to grow its product portfolio and/or offer incentives and rewards to staff and customers digitally.",
	},
	{
	  image: mobileDataImage,
	  hover: "hover:bg-[#E94F4F]",
	  title: "Mobile Data Top Ups & Data Plans",
	  description:
		"Providing mobile data top up plans, without the hassle of first topping up airtime and dialling operator specific data activation codes. Empowering international migrants and globally mobile end-users to pay locally and top up, ensuring they are always connected.",
	},
	{
	  image: bulkDataImage,
	  hover: "hover:bg-[#00A861]",
	  title: "Bulk Data",
	  description:
		"We provide businesses with unique data solutions to keep their staff connected, maintain digital assets, or sponsor data for their customers to interact with their brand.",
	},
	{
	  image: smsServiceImage,
	  hover: "hover:bg-[#F4B400]",
	  title: "SMS Services",
	  description:
		"Our SMS platform enables our partners to send bulk SMS to their customers for marketing, transaction completions, and verification purposes.",
	},
	{
	  image: utilityPaymentImage,
	  hover: "hover:bg-[#6B47DC]",
	  title: "Lifestyle, Utility and Bill Payments",
	  description:
		"Providing access to digital products and services like TV subscriptions, electricity payments, and food vouchers from over 150 countries worldwide.",
	},
	{
	  image: rewardsImage,
	  hover: "hover:bg-[#FF5722]",
	  title: "Rewards and Incentive Programs",
	  description:
		"We enable partners to achieve their goals by using digital offerings like mobile data as an incentive to drive outcomes such as customer acquisition, product adoption, or loyalty rewards.",
	},
  ];

// Reusable FeatureCard component with framer-motion hover effect
const FeatureCard = ({ image, title, description, hover }) => (
	<motion.div
		className={`flex group flex-col items-start text-left gap-[2vw] md:gap-[4vw] hover:border-none rounded-xl 
			p-md py-lg bg-white ${hover} shadow-lg transition-all duration-300`}
		whileHover={{
			scale: 1.05,
			transition: { duration: 0.3 },
		}}
	>
		<img src={image} alt={title} className="h-20 lg:h-[56px] aspect-square" />
		<div className="flex flex-col gap-[1vw] md:gap-[2vw]">
			<h2
				className="text-4 font-regular
			 transition duration-[300ms] ease-in-out group-hover:text-white
			"
			>
				{title}
			</h2>
			<p className="text-gray-600 transition duration-[300ms] ease-in-out group-hover:text-white font-light text-4">
				{description}
			</p>
		</div>
	</motion.div>
);

const Features = () => {
	return (
		<section className="px-[6vw] py-[8vw] bg-slate-100 text-black border-b">
			<div className="flex items-center justify-center">
				<img src={photo} alt="processing" className="h-auto object-cover max-w-3xl lg:max-w-md md:max-w-sm aspect-[809.76/652.93]"/>
			</div>
			<div className="text-center mb-[4vw] space-y-[2vw] lg:mb-[6vw] flex flex-col items-center justify-center">
				<h1 className="h4 font-bold">Value Added Services</h1>
				<p className="text-gray-600 font-light text-4 max-w-3xl lg:max-w-lg items-center">
					SaySwitch VAS is an easy-to-use channel designed for smooth customer
					experience specializing in the distribution of digital products and
					services via a single point API connection. Our VAS platform connects
					financial institution, aggregators, and users to over 5000 billers.
					Our key product offerings include;
				</p>
			</div>

			<div
				className="grid grid-cols-3 
            3xl:grid-cols-3
            1xl:grid-cols-3
            xl:grid-cols-3
            lg:grid-cols-3
            md:grid-cols-2
            sm:grid-cols-1
             gap-[3vw]
			 mb-[8vw] lg:mb-[10vw]"
			>
				{features.map((feature, index) => (
					<FeatureCard
						key={index}
						image={feature.image}
						title={feature.title}
						description={feature.description}
						hover={feature.hover}
					/>
				))}
			</div>
		</section>
	);
};

export default Features;
