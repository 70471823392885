import React, { forwardRef, useEffect } from "react";
import photo from "./contact.png";
import ContactForm from "./ContactForm";

const Form = forwardRef((props, ref) => {
	return (
		<div ref={ref} className="w-full grid grid-cols-2 gap-[4vw] lg:gap-[8vw] p-[6vw] py-[10vw] lg:grid-cols-1 bg-[#F3F9F3]">
			{/* Left Section */}
			<div
				data-aos="fade-right"
				className="flex flex-col justify-center items-start bg-none rounded-3xl h-full"
			>
				<h1
					className="font-bold mb-[1.6vw] text-[3vw] 
				6xl:text-[80px] 5xl:text-[70px] 4xl:text-[60px] 3xl:text-[55px] 2xl:text-[50px] 1xl:text-[45px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] xs:text-[20px]
				 text-black leading-tight tracking-wide text-left"
				>
					Take a step to become a <span className="text-primary">Partner</span>{" "}
					right away, send us a message now.
				</h1>
				<p
					className="font-sans font-light mb-[2.5vw] text-left text-black 
				6xl:text-[30px] 5xl:text-[28px] 4xl:text-[25px] 3xl:text-[22px] 2xl:text-[20px] 1xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[12px] xs:text-[11px]
				 leading-tight tracking-wider"
				>
					You can write to us on any inquiries for <br /> quick responses and
					assistance.
				</p>
				<img
					src={photo}
					data-aos="zoom-in"
					alt="Partner with us"
					className="w-full h-auto hover:scale-105 transition ease object-cover opacity-100 rounded-3xl"
				/>
			</div>

			{/* Right Section */}
			<div className="h-full flex items-stretch" data-aos="fade-left">
				<ContactForm />
			</div>
		</div>
	);
});

export default Form;
