import React from "react";
import HeroSection from "./HeroSection";
import HeroContent from "./HeroContent";

const Hero = () => {
	return (
		<div className="bg-hero-color w-full flex flex-col justify-between">
			<HeroSection>
				<HeroContent />
			</HeroSection>
		</div>
	);
};

export default Hero;
