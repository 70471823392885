import React from "react";
import { motion } from "framer-motion";

export default function Button({ type = "submit", className = "", children }) {
	return (
		<motion.div
			transition={{ type: "spring" }}
			whileTap={{ scale: 0.9, rotate: 2, opacity: 0.8 }}
		>
			<button
				type={type}
				data-aos="fade-right" // <-- AOS animation
				className={
					`  outline-none flex justify-center items-center rounded-full font-light text-[16px] leading-[16px] select-none border py-6  
          } ` + className
				}
			>
				{children}
			</button>
		</motion.div>
	);
}
