import React from "react";

const PandCtermsOfService = () => {
  return (
    <>
      By signing up for an account on this Portal, any of our Portals and/or
      services, you are deemed a merchant and agree to these Merchant Terms of
      Service (the “Agreement”). PLEASE READ THESE MERCHANT TERMS OF SERVICE
      CAREFULLY BEFORE SIGNING UP AS A MERCHANT. If you do not agree to any or
      all of these Terms of Service, DO NOT USE THIS SITE!
    </>
  );
};

export default PandCtermsOfService;
