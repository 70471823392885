import React from "react";
import ApiPage from "../../components/Developer/Api";

const ApiView = () => {
  return (
    <div className="w-full scrollbar-hide relative">
      <ApiPage />
    </div>
  );
};

export default ApiView;
