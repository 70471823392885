import React from "react";

const PandCintroduction = () => {
  return (
    <>
      We are Sayswitch, a Financial Technology Company registered in Nigeria
      with offices at 15, Idowu Olo Lane, Lagos Island, Lagos. For any access
      request, questions, or inquiries about how we use your Personal
      Information, please contact us at privacy@sayswitchgroup.com. The privacy
      of our Users is important to us and we are committed to safeguarding it.
      Hence, this Privacy Policy explains your Personal Information which we
      collect, why we collect it, and what we do with it. This data privacy,
      protection describes your privacy rights regarding our collection, use,
      storage, sharing and protection of your personal information. It applies
      to our website and all related sites, applications, services and tools
      regardless of how you access or use them. This Data Privacy, Protection
      and Cookie Policy (the “Privacy Policy”) will help you understand how we
      use your information and what we do with it. This Privacy Policy does not
      apply to services that are not owned or controlled by Sayswitch, including
      third-party websites and the services of other Sayswitch’s merchants. Our
      goal is to handle personal data provided to us in compliance with
      applicable data privacy and protection laws. To use our website or any of
      our products or services, your consent is required for the use of your
      data as described in this Privacy Policy.
    </>
  );
};

export default PandCintroduction;
