import React, { useState } from "react";
import PropTypes from "prop-types";
import { PiCopyDuotone, PiCopyFill } from "react-icons/pi";

const ApiSection = ({
	title,
	endpoint,
	headers = [], // default to empty array
	body,
	method,
	response,
	status,
	params = [], // default to empty array
	pathParams = [], // default to empty array
}) => {
	const [bodyCopied, setBodyCopied] = useState(false);
	const [responseCopied, setResponseCopied] = useState(false);

	const handleCopy = (text, setCopied) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
			})
			.catch((err) => console.error("Failed to copy text:", err));
	};

	return (
		<div className="w-full text-base">
			<div className="flex flex-row w-full justify-between">
				<h3
					className={`font-medium text-lg my-2 ${
						title === "Response" ? "text-blue-600" : "text-green-600"
					}`}
				>
					{title}
				</h3>
			</div>
			<div className="flex flex-col">
				{endpoint && (
					<div className="flex flex-col bg-gray-100 p-2 rounded-lg w-full whitespace-pre-wrap w-full overflow-x-auto break-all">
						<p className="font-light text-sm text-gray-700">{endpoint}</p>
					</div>
				)}
				{headers.length > 0 && (
					<div className="my-2 w-full whitespace-pre-wrap w-full overflow-x-auto break-all">
						<h4 className="font-medium text-base mb-2 text-gray-700">
							Headers
						</h4>
						<table className="min-w-full bg-gray-100 rounded-lg overflow-hidden">
							<thead className="text-sm">
								<tr>
									<th className="py-2 px-4 text-left font-normal">Header</th>
									<th className="py-2 px-4 text-left font-normal">Type</th>
									<th className="py-2 px-4 text-left font-normal">Required?</th>
								</tr>
							</thead>
							<tbody className="text-sm">
								{headers.map((header, index) => (
									<tr key={index}>
										<td className="py-2 px-4">{header.name}</td>
										<td className="py-2 px-4">{header.type}</td>
										<td className="py-2 px-4">
											{header.required ? "Yes" : "No"}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{Array.isArray(params) && params.length > 0 && (
					<div className="mt-2 w-full whitespace-pre-wrap w-full overflow-x-auto break-all">
						<h4 className="font-medium text-base mb-2 text-gray-700">
							Parameters
						</h4>
						<table className="min-w-full bg-gray-100 rounded-lg overflow-hidden">
							<thead className="text-sm">
								<tr>
									<th className="py-2 px-4 text-left font-normal">Parameter</th>
									<th className="py-2 px-4 text-left font-normal">Type</th>
									<th className="py-2 px-4 text-left font-normal">Required?</th>
									<th className="py-2 px-4 text-left font-normal">Description</th>
								</tr>
							</thead>
							<tbody className="text-sm">
								{params.map((param, index) => (
									<tr key={index}>
										<td className="py-2 px-4">{param.name}</td>
										<td className="py-2 px-4">{param.type}</td>
										<td className="py-2 px-4">
											{param.required ? "Yes" : "No"}
										</td>
										<td className="py-2 px-4">{param.description}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{Array.isArray(pathParams) && pathParams.length > 0 && (
					<div className="mt-2 w-full whitespace-pre-wrap w-full overflow-x-auto break-all">
						<h4 className="font-medium text-base mb-2 text-gray-700">
							Path Parameters
						</h4>
						<table className="min-w-full bg-gray-100 rounded-lg overflow-hidden">
							<thead>
								<tr>
									<th className="py-2 px-4 text-left font-normal">Parameter</th>
									<th className="py-2 px-4 text-left font-normal">Description</th>
								</tr>
							</thead>
							<tbody>
								{pathParams.map((param, index) => (
									<tr key={index}>
										<td className="py-2 px-4">{param.name}</td>
										<td className="py-2 px-4">{param.description}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{body && (
					<div className="mt-2">
						<h4 className="font-medium text-base text-gray-700">Body</h4>
						<div
							className={`flex flex-col ${
								method === "PUT" ? "bg-blue-500/10" : "bg-yellow-600/10"
							} text-sm rounded-lg mt-4 relative w-full overflow-x-auto`}
						>
							<div
								className={`flex justify-end ${
									method === "PUT" ? "bg-blue-500" : "bg-yellow-600"
								} px-4 py-1.5 md:py-1`}
							>
								<button
									onClick={() => handleCopy(body, setBodyCopied)}
									className="text-white text-xs transition-all duration-300 flex flex-row items-center gap-1 justify-center"
								>
									{bodyCopied ? <PiCopyFill /> : <PiCopyDuotone />}
									{bodyCopied ? "Copied!" : "Copy code"}
								</button>
							</div>
							<pre className="whitespace-pre-wrap break-all w-full p-4">
								{body}
							</pre>
						</div>
					</div>
				)}
				{response && (
					<>
						<div className="flex justify-between">
							<span className="font-medium text-base text-green-700">
								{status}
							</span>
							<span className="font-light text-sm text-gray-600">
								application/json
							</span>
						</div>
						<div className="flex flex-col bg-green-50 text-sm rounded-lg mt-4 w-full overflow-x-auto">
							<div className="flex justify-end bg-green-500 px-4 py-1.5 md:py-1">
								<button
									onClick={() => handleCopy(response, setResponseCopied)}
									className="text-white text-xs transition-all duration-300 flex flex-row items-center gap-1 justify-center"
								>
									{responseCopied ? <PiCopyFill /> : <PiCopyDuotone />}
									{responseCopied ? "Copied!" : "Copy code"}
								</button>
							</div>

							<pre className="whitespace-pre-wrap p-4 break-all w-full">
								{response}
							</pre>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

ApiSection.propTypes = {
	title: PropTypes.string.isRequired,
	endpoint: PropTypes.string,
	headers: PropTypes.array,
	body: PropTypes.string,
	response: PropTypes.string,
	status: PropTypes.string,
	params: PropTypes.array,
	pathParams: PropTypes.array,
};

export default ApiSection;
