import Nga from "../../assets/images/NGA.png";
import { useHistory } from "react-router-dom";
import logo from "./logo.png";
import { BsChevronDown } from "react-icons/bs";
import {
	FaFacebook,
	FaInstagram,
	FaLinkedinIn,
	FaTwitter,
} from "react-icons/fa";
import { motion } from "framer-motion";
import Cbn from "./cbn.png";

const Footer = ({ locPath }) => {
	const history = useHistory();

	// const checkHash = () => {
	//   const hash = window.location.hash;

	//   if (hash) {
	//     const id = hash.replace("#", "");
	//     const element = document.getElementById(id);
	//     if (element) {
	//       element.scrollIntoView();
	//     }
	//   } else {
	//     window.scrollTo(0, 0);
	//   }
	// };

	const redirectToUrl = (path) => {
		history.push(path);
		window.scrollTo(0, 0);
		// checkHash();
	};

	// useEffect(() => {
	//   checkHash();
	// }, []);

	return (
		<div
			className={`relative mx-auto z-auto scrollbar-hide bg-black px-[152px] lg:px-[70px] md:px-[30px] py-[32px]
			${locPath === "/getstarted" ? "hidden" : ""}
		`}
		>
			<div className={`w-full px-[0px] flex flex-col lg:w-full md:flex-col  `}>
				<div
					className="w-full flex py-[34px]
        sm:flex-col 1xl:flex-row 
        gap-8 justify-between h-full sm:items-start"
				>
					<div className="w-full h-full space-y-2">
						<img src={logo} alt="" className="aspect-[140/39]" />
						<div
							className="cursor-pointer max-w-2xl text-gray-400
            text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
            "
						>
							The financial technology platform that helps you to accept,
							process and settle payment online or offline anywhere.
						</div>
					</div>
					<div className="flex items-center gap-2">
						<img
							src={Nga}
							alt=""
							className="w-[20px] h-[18.76px] block bg-white rounded-full border border-green-500"
						/>
						<p
							className="font-Aeonik font-normal cursor-pointer text-white 
            text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
            "
						>
							Nigeria
						</p>
						<span>
							<BsChevronDown
								className="text-white 
                          text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl leading-[26px] tracking-[0.002em]

              mt-1"
							/>
						</span>
					</div>
				</div>
			</div>
			<div
				className="w-full py-[10vw] border-y-[1px] border-gray-700 
             grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 
             lg:grid-cols-3 xl:grid-cols-3 1xl:grid-cols-4 2xl:grid-cols-5 
             3xl:grid-cols-5 4xl:grid-cols-5 5xl:grid-cols-5 6xl:grid-cols-5 
             gap-y-[60px] gap-x-[20px]"
			>
				<Section
					title="ABOUT US"
					items={[
						{ text: "Overview", onClick: () => redirectToUrl("/about") },
						{
							text: "Our brands",
							onClick: () => redirectToUrl("/"),
							href: "#brand",
						},
						{
							text: "Social responsibility",
							onClick: () => {
								redirectToUrl("/about");
							},
							href: "#vision",
						},
						{ text: "Careers", onClick: () => redirectToUrl("/aboutcareers") },
						{ text: "Partners", onClick: () => redirectToUrl("/partners") },
					]}
				/>
				<Section
					title="OUR PRODUCTS"
					items={[
						{
							text: "Payment gateway",
							onClick: () => redirectToUrl("/products"),
						},
						{ text: "Checkout", onClick: () => redirectToUrl("/checkout") },
						{ text: "Mobile App", onClick: () => redirectToUrl("/mobileapp") },
						{ text: "POS", onClick: () => redirectToUrl("/pos") },
						{
							text: "Collect platform",
							onClick: () => redirectToUrl("/collection"),
						},
					]}
				/>
				<Section
					title="LEGAL"
					items={[
						{
							text: "Terms and Conditions",
							onClick: () => redirectToUrl("/privacycookies"),
							href: "#terms",
						},
						{
							text: "Privacy Policy",
							onClick: () => redirectToUrl("/privacycookies"),
							href: "#privacy",
						},
						{
							text: "Cookies Policy",
							onClick: () => redirectToUrl("/privacycookies"),
							href: "#cookies",
						},
						{
							text: "Merchant Service Agreement",
							onClick: () => redirectToUrl("/privacycookies"),
							href: "#terms",
						},
						{ text: "Pricing", onClick: () => redirectToUrl("/pricing") },
					]}
				/>
				<Section
					title="DEVELOPERS"
					items={[
						{
							text: "API Documentation",
							onClick: () => redirectToUrl("/documents"),
						},
						{ text: "API Reference", onClick: () => redirectToUrl("/api") },
					]}
				/>
				<Section
					title="CONTACT"
					items={[
						{
							text: "hello@sayswitchgroup.com",
							href: "mailto:hello@sayswitchgroup.com",
						},
						{ text: "Twitter Support", href:'https://twitter.com/SaySwitch_NG' },
						{ text: "07066032484", href:"tel:+2347066032484"  },
					]}
				/>
			</div>

			<div className=" pt-[34px] flex sm:flex-col-reverse sm:items-start items-center justify-between gap-[16px]">
				<div className="flex items-center">
					<li
						className="text-left font-Aeonik font-normal cursor-pointer text-gray-400 
            text-3 leading-[26px] tracking-[0.002em]
          "
					>
						All Rights Reserved © 2024
					</li>
				</div>

				<div
					className="flex items-center gap-[24px] md:gap-[6px]
        "
				>
					<SocialIcons />
				</div>
			</div>
			<div className="pt-[34px] font-light text-gray-400 flex items-start flex-row md:flex-col-reverse justify-between gap-[6px]">
        <div className="flex items-center justify-start gap-[2vw]">
          <span>
            <img src={Cbn} className="h-[58px] lg:h-auto lg:w-[32px] sm:w-[22px] aspect-[64/84]" />
          </span>
          <p className="text-left text-2xl xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]">
          Sayswitch is regulated by the <br className=""/>
          Central Bank of Nigeria
          </p>
        </div>
        <div className="flex items-center gap-[6px]">
      
        </div>
        <div className="flex items-center gap-[20px] justify-center">
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#disclaimer"
            className=" text-2xl xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
		  hover:underline"
          >
            Disclaimer
          </a>
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#isopolicy"
            className=" text-2xl xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
		  hover:underline"
          >
            ISO Policy
          </a>
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#trademark"
            className=" text-2xl xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
		  hover:underline"
          >
            Trademark License
          </a>
        </div>
      </div>
		</div>
	);
};

export default Footer;

const Section = ({ title, items }) => (
	<div className="space-y-[24px] md:space-y-[12px]" data-aos="fade-up">
		<h4
			className="font-Aeonik text-white font-normal
		text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl leading-[26px] tracking-[0.002em]
	  "
		>
			{title}
		</h4>
		{items.map((item, index) => (
			<li
				key={index}
				onClick={item.onClick}
				className="font-Aeonik cursor-pointer text-gray-400 font-light
		  text-2xl xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 1xl:text-xl 2xl:text-xl leading-[26px] tracking-[0.002em]
		  hover:underline"
			>
				<a href={item.href} onClick={item.onClick}>
					{item.text}
				</a>
			</li>
		))}
	</div>
);

const size =
	"text-sm xs:text-sm sm:text-md md:text-lg lg:text-xl xl:text-xl 1xl:text-xl 2xl:text-2xl";

	const socialIcons = [
		{ id: 1, icon: <FaFacebook className={size} />, name: "Facebook", url: "https://rb.gy/ctq8ba" },
		{ id: 2, icon: <FaTwitter className={size} />, name: "Twitter", url: "https://twitter.com/SaySwitch_NG" },
		{ id: 3, icon: <FaInstagram className={size} />, name: "Instagram", url: "https://www.instagram.com/SaySwitch_ng" },
		{ id: 4, icon: <FaLinkedinIn className={size} />, name: "LinkedIn" },
	];

const SocialIcons = () => (
	<div className="flex items-center gap-[24px] md:gap-[6px]">
		{socialIcons.map((item) => (
			<motion.a
				key={item.id}
				href={item.url}
				whileHover={{ scale: 1.1 }} // Scale up on hover
				whileTap={{ scale: 0.9 }} // Scale down on tap (optional)
				transition={{ duration: 0.2 }} // Smooth transition
			>
				<IconWrapper>{item.icon}</IconWrapper>
			</motion.a>
		))}
	</div>
);

const IconWrapper = ({ children }) => (
	<div className="bg-white p-1 rounded-full">{children}</div>
);