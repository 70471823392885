import React from "react";
import Image1 from "../../assets/images/Shape.png";
import Image2 from "../../assets/images/Vector-3.png";
import Image3 from "../../assets/images/Zenith-Bank-900x0 1.png";
import Image4 from "../../assets/images/1110px-Verifone_Logo 1.png";
import Image5 from "../../assets/images/Wema bank.png";
import Image6 from "../../assets/images/black-logo 1.png";

function Homepage2() {
	return (
		<div
		data-aos="fade-left"
			className="w-full grid grid-cols-12 
			space-x-4
			px-[6vw] md:px-[3vw]"
		>
			<div
				className="text-slate-500 font-bold col-span-4
			xs:text-xs sm:text-xs md:text-md lg:text-xl xl:text-2xl 
			1xl:text-2xl 2xl:text-3xl 3xl:text-3xl 4xl:text-3xl 5xl:text-3xl 6xl:text-3xl 
			flex justify-start items-center font-sans
			"
			>
				Here's Our Partners:
			</div>
			<div className="col-span-8 flex flex-row h-[60px] sm:h-[80px] md:h-[100px] lg:h-[120px] xl:h-[140px]
			1xl:h-[140px] 2xl:h-[160px] 3xl:h-[180px] 4xl:h-[200px] 5xl:h-[220px] 6xl:h-[240px]
			overflow-x-scroll scrollbar-hide
			">
				<div
					className="items-center justify-start flex flex-row 
					space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-10 xl:space-x-12 
					1xl:space-x-12 2xl:space-x-14 3xl:space-x-16 4xl:space-x-18 5xl:space-x-20 6xl:space-x-24
					"
				>
					<img
						src={Image1}
						alt="uba"
						className="h-6 sm:h-6 md:h-10 lg:h-12 xl:h-14 1xl:h-14 2xl:h-14 3xl:h-14 4xl:h-14 
						5xl:h-14 6xl:h-14"
					/>
					<img
						src={Image2}
						alt="coralpay"
						className="h-6 sm:h-6 md:h-10 lg:h-12 xl:h-14 1xl:h-14 2xl:h-14 3xl:h-14 4xl:h-14 
						5xl:h-14 6xl:h-14"
					/>
					<img
						src={Image3}
						alt="zenith"
						className="h-6 sm:h-6 md:h-10 lg:h-12 xl:h-14 1xl:h-14 2xl:h-14 3xl:h-14 4xl:h-14 
						5xl:h-14 6xl:h-14"
					/>
					<img
						src={Image4}
						alt="verifone"
						className="h-4 sm:h-4 md:h-8 lg:h-8 xl:h-10 1xl:h-10 2xl:h-10 3xl:h-10 4xl:h-10 
						5xl:h-10 6xl:h-10 mt-0 sm:mt-1 md:mt-2 lg:mt-2 xl:mt-2 1xl:mt-2 2xl:mt-2 3xl:mt-2 4xl:mt-2"
					/>
					<img
						src={Image5}
						alt="wemabank"
						className="h-6 sm:h-6 md:h-10 lg:h-12 xl:h-14 1xl:h-14 2xl:h-14 3xl:h-14 4xl:h-14 
						5xl:h-14 6xl:h-14"
					/>
					<img
						src={Image6}
						alt="horizonedev"
						className="h-6 sm:h-8 md:h-10 lg:h-12 xl:h-14 1xl:h-14 2xl:h-14 3xl:h-14 4xl:h-14 
						5xl:h-14 6xl:h-14"
					/>
				</div>
			</div>
		</div>
	);
}

export default Homepage2;
