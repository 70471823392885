import React from "react";
import { PandCList } from "./P&CList";
import { Link } from "react-scroll";

const PandCsideBar = ({ active, setActive }) => {
  return (
    <div className=" w-full sticky top-0 max-h-[100vh] overflow-y-scroll scrollbar-thin bg-white">
      <ul
        className="px-[32px] lg:px-[15px] md:px-[10px] sm:px-[5px] 
      lg:text-sm md:text-xs py-[40px] flex flex-col gap-[16px]
      font-normal text-[#3B443F] text-[16px] leading-[22px] tracking-wide list-decimal"
      >
        {PandCList.map(({ id, title }) => (
          <li className="grid grid-cols-10 items-center justify-center" key={id}>
            <span className="col-span-1 rounded-full bg-black mr-1 w-2 md:w-1 aspect-square"></span>
            <Link
              to={title}
              smooth={true}
              offset={-50}
              duration={500}
              onClick={() => {
                setActive(id);
              }}
              className={`cursor-pointer col-span-9 ${
                id === active ? "text-[#008037] underline" : "hover:underline"
              }`}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PandCsideBar;
