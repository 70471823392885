import React, { useState } from "react";
import { PiCopyDuotone, PiCopyFill } from "react-icons/pi";

const ApiSection = ({
	title,
	endpoint,
	headers,
	body,
	response,
	status,
	method,
}) => {
	const [bodyCopied, setBodyCopied] = useState(false);
	const [responseCopied, setResponseCopied] = useState(false);

	const handleCopy = (text, setCopied) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
			})
			.catch((err) => console.error("Failed to copy text:", err));
	};

	return (
		<div className="mb-6 w-full">
			<div className="flex flex-row w-full justify-between">
				<h3
					className={`font-medium text-lg mb-2 ${
						title === "Response" ? "text-blue-600" : "text-green-600"
					}`}
				>
					{title}
				</h3>
			</div>
			<div className="flex flex-col">
				{endpoint && (
					<div className="flex flex-col bg-gray-100 p-2 rounded-lg w-full whitespace-pre-wrap w-full overflow-x-auto break-all">
						<p className="font-light text-sm text-gray-700">{endpoint}</p>
					</div>
				)}
				{headers && (
					<div className="mt-2">
						<h4 className="font-medium text-base mb-2 text-gray-700">
							Headers
						</h4>
						<pre className="bg-gray-100 p-2 text-sm rounded-md whitespace-pre-wrap w-full overflow-x-auto break-all">
							{headers.map((header, index) => (
								<div key={index}>{header}</div>
							))}
						</pre>
					</div>
				)}
				{body && (
					<div className="mt-2">
						<h4 className="font-medium text-base text-gray-700">Body</h4>
						<div
							className={`flex flex-col ${
								method === "PUT" ? "bg-blue-500/10" : "bg-yellow-600/10"
							} text-sm rounded-lg mt-4 relative w-full overflow-x-auto`}
						>
							<div
								className={`flex justify-end ${
									method === "PUT" ? "bg-blue-500" : "bg-yellow-600"
								} px-4 py-1.5 md:py-1`}
							>
								<button
									onClick={() => handleCopy(body, setBodyCopied)}
									className="text-white text-xs transition-all duration-300 flex flex-row items-center gap-1 justify-center"
								>
									{bodyCopied ? <PiCopyFill /> : <PiCopyDuotone />}
									{bodyCopied ? "Copied!" : "Copy code"}
								</button>
							</div>
							<pre className="whitespace-pre-wrap break-all w-full p-4">
								{body}
							</pre>
						</div>
					</div>
				)}
				{response && (
					<>
						<div className="flex justify-between">
							<span className="font-medium text-base text-green-700">
								{status}
							</span>
							<span className="font-light text-sm text-gray-600">
								application/json
							</span>
						</div>
						<div className="flex flex-col bg-green-50 text-sm rounded-lg mt-4 w-full overflow-x-auto">
							<div className="flex justify-end bg-green-500 px-4 py-1.5 md:py-1">
								<button
									onClick={() => handleCopy(response, setResponseCopied)}
									className="text-white text-xs transition-all duration-300 flex flex-row items-center gap-1 justify-center"
								>
									{responseCopied ? <PiCopyFill /> : <PiCopyDuotone />}
									{responseCopied ? "Copied!" : "Copy code"}
								</button>
							</div>

							<pre className="whitespace-pre-wrap p-4 break-all w-full">
								{response}
							</pre>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ApiSection;
